import { Injectable } from '@angular/core';
import { IMG_ADD_FILE_SVG, IMG_ADD_PAYMENTS_SVG, IMG_EDIT_SVG, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_FULL_SCREEN_PURPLE_SVG, IMG_ICON_HIDE_SVG, IMG_ICON_REFRESH, IMG_ICON_VERTICALALIGNCENTER, IMG_ICON_XLSXFILEPOPUPIX } from '@app/constants-enums/constants';
import { PageOptions } from '@app/models/common.model';

@Injectable({
	providedIn: 'root'
})
export class ApCustomersService {
	public selectedTab: any;
	public customerID: any;
	public customerDetails: any;
	public tiersList: any[] = [];
	public storedFilters: any = undefined;
	private updateCurrentOrFuturePlan: string;
	public isCallAsCancelInvoice: boolean = false;
	private isEditModeCustomerUserDetails: boolean = false;
	private customerUsersDetailsElements: any[] = [];
	private customerSubscriptionDetails: any;
	constructor() { }

	setSelectedCustomerTab(val: any) {
		this.selectedTab = val;
	}
	getSelectedCustomerTab() {
		return this.selectedTab || undefined;
	}

	setCustomerID(val: any) {
		this.customerID = val;
	}
	getCustomerID() {
		return this.customerID || undefined;
	}

	setCustomerDetails(val: any) {
		this.customerDetails = val;
	}
	getCustomerDetails() {
		return this.customerDetails;
	}

	setTiersList(tiersList: any[]) {
		this.tiersList = tiersList;
	}

	getTiersList() {
		return this.tiersList || [];
	}

	public setStoredFilters(val: any) {
		this.storedFilters = val;
	}

	public getStoredFilters() {
		return this.storedFilters || undefined;
	}

	public setUpdateCurrentOrFuturePlan(val: string) {
		this.updateCurrentOrFuturePlan = val;
	}

	public getUpdateCurrentOrFuturePlan() {
		return this.updateCurrentOrFuturePlan || undefined;
	}

	public setIsCallAsCancelInvoice(val: boolean) {
		return this.isCallAsCancelInvoice = val;
	}

	public getIsCallAsCancelInvoice() {
		return this.isCallAsCancelInvoice;
	}

	public setIsEditModeCustomerUserDetails(val: boolean) {
		this.isEditModeCustomerUserDetails = val;
	}
	public getIsEditModeCustomerUserDetails() {
		return this.isEditModeCustomerUserDetails;
	}

	public setEditCustomerUserDetailsElement(val: any) {
		const eleIndex = this.customerUsersDetailsElements.findIndex(ele => ele.guid === val.guid);
		if (eleIndex !== 1) {
			this.customerUsersDetailsElements.splice(eleIndex, 1);
		}
		this.customerUsersDetailsElements.push(val);
	}

	public getEditCustomerUserDetailsElement(eleObj?: any) {
		if (eleObj && Object.keys(eleObj).length > 0) {
			const eleIndex = this.customerUsersDetailsElements.findIndex(ele => ele.guid === eleObj.guid);
			if (eleIndex !== -1) {
				return this.customerUsersDetailsElements[eleIndex];
			} else {
				return {};
			}
		} else {
			return this.customerUsersDetailsElements && this.customerUsersDetailsElements.length > 0 ? this.customerUsersDetailsElements[this.customerUsersDetailsElements.length - 1] : {};
		}
	}

	public getCustomersListOfAction() {
		const listOfActions: any[] = [];
		listOfActions.push(
			{
				btnCode: 'active',
				btnName: 'Active',
				btnImage: IMG_ICON_HIDE_SVG,
				icon: ' ' + IMG_ICON_HIDE_SVG,
				visible: false,
				disabled: false
			},
			{
				btnCode: 'inactive',
				btnName: 'Inactive',
				btnImage: IMG_ICON_HIDE_SVG,
				icon: ' ' + IMG_ICON_HIDE_SVG,
				visible: false,
				disabled: false
			},
			{
				btnCode: 'extend_trial',
				btnName: 'Extend Trial',
				btnImage: IMG_ICON_FULL_SCREEN_PURPLE_SVG,
				icon: ' ' + IMG_ICON_FULL_SCREEN_PURPLE_SVG,
				visible: false,
				disabled: false
			},
			{
				btnCode: 'edit_customer',
				btnName: 'Edit Customer',
				btnImage: IMG_EDIT_SVG,
				icon: ' ' + IMG_EDIT_SVG,
				visible: true,
				disabled: false
			},
			{
				btnCode: 'purchase_subscription',
				btnName: 'Purchase Subscription',
				btnImage: IMG_ADD_PAYMENTS_SVG,
				icon: ' ' + IMG_ADD_PAYMENTS_SVG,
				visible: false,
				disabled: false
			},
			{
				btnCode: 'upgrade_subscription',
				btnName: 'Upgrade Subscription',
				btnImage: IMG_ADD_PAYMENTS_SVG,
				icon: ' ' + IMG_ADD_PAYMENTS_SVG,
				visible: false,
				disabled: false
			},
		);
		return listOfActions;
	}

	public getListOfActionsForDatagrid() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
		];
	}

	public getListOfActionsForBillingInvoiceList() {
		return [
			{
				btn_code: 'GENERATE_INVOICES',
				btn_name: 'Generate Invoice',
				btn_image: '',
				icon: IMG_ICON_VERTICALALIGNCENTER,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
		];
	}
	public getListOfActionsForAuthorizeDotNetList() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add Payment Method',
				btn_image: '',
				icon: IMG_ICON_VERTICALALIGNCENTER,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'GET_AUTHORIZE_DOT_NET',
				btn_name: 'Get Authorize.net Info',
				btn_image: '',
				icon: 'info',
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
		];
	}

	get getListOfActionsForOpensearchTab() {
		return [{
			btn_code: 'ADD',
			btn_name: 'Add New',
			btn_image: IMG_ADD_FILE_SVG,
			icon: ' ' + IMG_ADD_FILE_SVG,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'REFRESH',
			btn_name: 'Refresh',
			btn_mage: '',
			icon: IMG_ICON_REFRESH,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		}];
	}

	get getListOfActionsForCustomerSubcriptionList() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}
	get getListOfActionsForRowSubcriptionList() {
		return [
			{
				btn_code: 'RAW_DATA',
				btn_name: 'Download Raw Data',
				btn_image: 'column-chooser',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'UPDATE_CARD_DETAILS',
				btn_name: 'Update Card',
				btn_image: 'edit',
				icon: ' ' + IMG_EDIT_SVG,
				visible: true,
				disabled: false,
			}
		];
	}

	setCustomerSubscriptionDetails(val: any) {
		this.customerSubscriptionDetails = val;
	}
	getCustomerSubscriptionDetails() {
		return this.customerSubscriptionDetails;
	}


	get getListOfSubscriptonStatuses() {
		return [
			{
				value: 'active',
				text: 'ACTIVE',
				classname: 'green',
				bg_class_name: 'greenInvoice',
				color_code: '05c1a0',
			},
			{
				value: 'live',
				text: 'LIVE',
				classname: 'green',
				bg_class_name: 'greenInvoice',
				color_code: '05c1a0',
			},
			{
				value: 'future',
				text: 'FUTURE',
				classname: 'purple',
				bg_class_name: 'purpleInvoice',
				color_code: '706cce',
			},
			{
				value: 'TRIAL',
				text: 'TRIAL',
				classname: 'purple',
				bg_class_name: 'purpleInvoice',
				color_code: '706cce',
			},
			{
				value: 'TRIAL_EXPIRED',
				text: 'Void',
				classname: 'purple',
				bg_class_name: 'purpleInvoice',
				color_code: '706cce',
			},
			{
				value: 'EXPIRED',
				text: 'Void',
				classname: 'purple',
				bg_class_name: 'purpleInvoice',
				color_code: '706cce',
			},
			{
				value: 'PAST_DUE',
				text: 'Partially Paid',
				classname: 'orange',
				bg_class_name: 'orangeInvoice',
				color_code: 'f99300',
			},
			{
				value: 'UNPAID',
				text: 'OverDue',
				classname: 'red',
				bg_class_name: 'redInvoice',
				color_code: 'ff4545',
			},
			{
				value: 'CANCELLED',
				text: 'Void',
				classname: 'pink',
				bg_class_name: 'pinkInvoice',
				color_code: 'DB5383',
			},
			{
				value: 'CANCELLED_FROM_DUNNING',
				text: 'Void',
				classname: 'pink',
				bg_class_name: 'pinkInvoice',
				color_code: 'DB5383',
			},
			{
				value: 'CANCELLED_LAST_MONTH',
				text: 'Void',
				classname: 'pink',
				bg_class_name: 'pinkInvoice',
				color_code: 'DB5383',
			},
			{
				value: 'CANCELLED_THIS_MONTH',
				text: 'Void',
				classname: 'pink',
				bg_class_name: 'pinkInvoice',
				color_code: 'DB5383',
			},
			{
				value: 'NON_RENEWING',
				text: 'Void',
				classname: 'pink',
				bg_class_name: 'pinkInvoice',
				color_code: 'DB5383',
			},
		];
	}
}
