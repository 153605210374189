import { OrderCutOff } from './common.model';

export class Login {
	public user: string;
	public pwd: string;
	public token: string;
	public server_timezone: string;
	public server_date: string;
	public account_detail: AccountDetails;


	constructor() {
		this.user = '';
		this.pwd = '';
		this.token = '';
		this.server_timezone = '';
		this.server_date = '';
		this.account_detail = null;

	}
}

export class TwoFactorAuthentication {
	public twoFactorAuthentication1: number;
	public twoFactorAuthentication2: number;
	public twoFactorAuthentication3: number;
	public twoFactorAuthentication4: number;
	public twoFactorAuthentication5: number;
	public twoFactorAuthentication6: number;
	constructor() {
		// this.twoFactorAuthentication1 = 1;
		// this.twoFactorAuthentication2 = 1;
		// this.twoFactorAuthentication3 = 1;
		// this.twoFactorAuthentication4 = 1;
		// this.twoFactorAuthentication5 = 1;
		// this.twoFactorAuthentication6 = 1;
	}

}

export class SignUp {
	// public name: string;
	public firstname: string;
	public lastname: string;
	public accountname: string;
	public password: string;
	public password2: string;
	public email: string;
	public countrycode: string;
	public phone: string;
	public contact_number: string;
	public address1: string;
	public address2: string;
	public country: string;
	public state: string;
	public postalcode: string;
	public city: string;
	public nameofcard: string;
	public cardnumber: string;
	public expirmonth: string;
	public expiryear: string;
	public cvccode: string;
	public no_of_agreements: number;
	public initial_setup_charge: number;
	public initial_setup_charge_type: string;
	constructor() {
		this.countrycode = '1';
		this.country = 'United States';
		this.state = '';
		this.expirmonth = '01';
		this.initial_setup_charge_type = 'expert';
	}
}

export class CardDetails {
	public nameoncard: string;
	public cardnumber: string;
	public expirmonth: string;
	public expiryear: string;
	public cvccode: string;
	public isDefaultCard: boolean;
	public isBillingAddrSamePrimaryAdd: boolean;
	public country: string;
	public state: string;
	public address1: string;
	public address2: string;
	public city: string;
	public zip: string;
	public cardType: string;
	constructor() {
	}
}

export class UserProfile {
	public access_rights: any[];
	public company_shortcode: string;
	public country_code: string;
	public email: string;
	public first_name: string;
	public fiscal_year_start: number;
	public is_company_verified: string;
	public last_name: string;
	public mobile: string;
	public role: string;
	public sub_accounts: any[];
	public country_name: string;
	public state: string;
	public address1: string;
	public address2: string;
	public city: string;
	public zip: string;
	public company_name: string;
	public is_trial: boolean;
	public tier: number;
	public companylogo?: any;
	public allowed_device_list?: any;
	public local_timezone: string;
	public work_start: string;
	public work_end: string;
	public notification?: Notification;
	public isd_code?: string;
	public role_name?: string;
	constructor() {
	}
}
export class CompanyProfile {
	public company_name: string;
	public address1: string;
	public address2: string;
	public state: string;
	public city: string;
	public zip: string;
	public country_name: string;
	public companylogo: string;
	public dialcode: string;
	public contact_number: string;
	public companyLogoURL: any;
	public technical_contact: CompanyContact[];
	public financial_contact: CompanyContact[];
	public payment_api?: PaymentApiCompany[];
	constructor() {
	}
}
export class CompanyContact {
	guid?: string;
	first_name: string;
	last_name: string;
	contact_number: string;
	dialcode: string;
	email: string;
	local_timezone?: string;
	notify_status?: boolean;
	notify_by?: string[];
	notify_days?: string[];
	notify_start_time?: any;
	notify_end_time?: any;
	constructor() { }
}


export class AppSettings {
	erp_host_url: string;
	enable_accounts_for_commision: boolean;
	enable_accounts_for_rebate: boolean;
	erp_type: string;
	crm_type: string;
	salesforce_app_id: string;
	erp_user_db: string;
	erp_user_name: string;
	erp_user_password: any;
	twofa_pass_phrase: any;
	master_data_update_frequency: number;
	transaction_update_frequency: number;
	inactive_customer_months: number;
	include_in_commission_cal: string[];
	previous_months_orders: number;
	erp_data_start_date: string;
	company_gl_account_number?: string;
	service_item_code?: string;
	start_cron?: string;
	order_url?: string;
	product_search?: any;
	document_template_docusign_rebate?: string;
	qbonline_estimate_linkto?: string;
	map_order_due_date?: string;
	include_paidinvoice_in_settlementperiod?: string;
	commission_settlement_output?: string;
	order_cutoff: OrderCutOff;
	is_use_territory_for_commission_contract?: boolean;
	territory_by_address?: string;
	decimal_point?: number;
	rebate_price_basis?: string;
	chargeback_price_basis?: string;
	customer_age_tiers?: boolean;
	docusignEmailSubject?: any;
	decimal_point_currency?: number;
	currency_code?: string;
	currency_language?: string;
	constructor() { }
}

export class Subscription {
	unsubscribe() {
		throw new Error('Method not implemented.');
	}
	tierName: string;
	counts: any;
	tier_requirement_match: boolean;
	message: string;
	entities_affected: any[];
	constructor() { }
}

export class AccountDetails {
	access_rights: any[];
	subscription: Subscription;
	company_shortcode: string;
	country_code: string;
	email: string;
	first_name: string;
	fiscal_year_start: number;
	is_company_verified: string;
	last_name: string;
	mobile: string;
	role: string;
	role_name: string;
	sub_accounts: any[];
	app_settings: AppSettings;
	account_bonus: any;
	anet_payment_profile_ids: any;
	anet_profile_id: any;
	country_name: string;
	state: string;
	address1: string;
	address2: string;
	city: string;
	zip: string;
	sample_csv_link: string;
	users_file_link: string;
	di_msi_link: string;
	qb_msi_link: string;
	company_name: string;
	is_trial: boolean;
	no_of_agreements: any;
	startdate: any;
	expirydate: any;
	trial_remaining_days?: number = 0;
	tier: number;
	tier_guid?: any;
	trackno_separator: any;
	erp_type_settings?: any[] = [];
	userType?: UserType[] = [];
	email_verified?: boolean;
	companylogo?: any;
	admin?: boolean;
	sub_account_id?: string;
	disable_login?: boolean;
	portal_type: string;
	dynamic_groups?: any[];
	dynamic_properties?: any[];
	crm_data?: string;
	managedUsers?: any[];
	local_timezone: string;
	work_start: string;
	work_end: string;
	is_invoice_overdue: boolean;
	usr?: string;
	is_docusign_enabled?: boolean;
	contract_lifecycle_management?: string;
	rebate_status_list?: any[];
	docusign_token?: any;
	netsuite_client_app_id?: string;
	salesforce_app_id?: string;
	docusignAccountId?: string;
	docusign?: any;
	commission_rate_on_invoice?: boolean;
	commission_on_sales_orders?: boolean;
	enable_invoice_qualification_feature?: boolean;
	enable_invoice_custom_fields_feature?: boolean;
	rebate_contract_dates_on_customer?: boolean;
	enable_search_transactions_in_files?: boolean;
	rebate_contract_file_link: string;
	enable_classification_for_commision: boolean;
	enable_classification_for_rebate: boolean;
	enable_profit_on_document_header?: boolean;
	enable_amortise_payments?: boolean;
	enable_item_eligible_for_commission_feature?: boolean;
	calculate_profit_on_document_header_by?: string;
	employee_entity_to_consider?: string;
	enable_sales_leaderboard?: boolean;
	enable_configuration_for_line_item_on_ap_invoice?: boolean;
	enable_tier_basis?: boolean;
	sso_enabled?: boolean;
	tier_basis_type?: string;
	tier_basis_field?: string;
	azure_tenant_id?: string;
	enable_ar_invoice_line_items_in_ap_invoice?: boolean;
	enable_exclude_sales_people_from_cost_assignment?: boolean;
	amortise_payment_config?: any;
	amortise_payment_start_date?: any;
	amortise_payment_end_date?: any;
	enable_cost_accounting?: boolean;
	notification?: Notification;
	notificationTypes?: Notification;
	enable_shortpay_notification?: boolean;
	shortpay_tasktype?: string;
	enable_virtual_invoices?: boolean;
	application_host_url?: string;
	application_api_version?: string;
	application_company?: string;
	application_user_id?: string;
	application_user_password?: string;
	enable_chargeback_look_back_period?: boolean;
	look_back_period?: any;
	claim_back_date_period?: any;
	trade_promotion_feature?: any;
	companylogo_base64?: string;
	netsuite_iss?: string;
	netsuite_m2m_id?: string;
	base_price_list_name?: string;
	enable_cash_discount_feature?: boolean;
	netsuite_public_key_available?: boolean;
	epicor_prophet21_consumer_key?: string;
	epicor_prophet21_url?: string;
	virtual_invoice_file_link?: string;
	amortise_payment_use_for: string[];
	sso_mode?: string;
	okta_url?: string;
	okta_client_id?: string;
	okta_client_secrete_key?: string;
	use_customers_from?: string; //header | itemlist
	use_buying_groups_as?: string; //vendors | clients
	enable_min_max_claim?: boolean;
	tax_calculation_feature?: boolean;
	enable_pricelist_approval_workflow?: boolean;
	enable_rebate_settlement_approval_workflow?: boolean;
	enable_price_list_terms_feature?: boolean;
	enable_sales_rep_for_orders_feature?: boolean;
	salespersoncode?: any;
	vendor_entity_to_consider?: string;
	tax_exempt_code?: string;
	dynamics_client_app_id?: string;
	dynamics_client_environment?: string;
	dynamics_client_company?: object;
	enable_incremental_payment?: boolean;
	zoho_customer_id?: string;
	constructor() { }
}
export class UserType {
	guid: string;
	user_type: string;
	display_name: string;
	can_login: boolean;
	can_receive_incentives: string;
	managed_user: boolean;
	manages_types?: ManagesTypes[] = [];
	restrictions?: Restrictions[] = [];
	can_be_admin?: boolean;
}

export class ManagesTypes {
	guid: string;
	user_type: string;
}

export class Restrictions {
	guid: string;
	module: string;
	restriction: string;
}

export class IncentXSupportUserDetails {
	public country_code: string;
	public isd_code: string;
	public email_verified?: boolean;
	public first_name: string;
	public last_name: string;
	public actual_user: string;
	public email: string;
	public dialcode: string;
	public authy_user_id: number;
	public role: string;
	public mobile: string;
	public number: number;
	public valid_till_period: string;
	public valid_till: number;
	public sub_account_id: string;
}

export class PaymentApiCompany {
	public guid?: string;
	public paymentmethod?: string;
	public anet_api_login_id?: string;
	public anet_api_transaction_key?: string;
	public anet_api_signature_key?: string;
	public anet_api_client_key?: string;
	public apikey?: string;
	public apipin?: string;
	public transaction_key?: string;
	public i_fields_key?: string;
	public software_name?: string;
	public software_version?: string;
}


export class PaymentApiUI {
	public guid?: string;
	public paymentmethod?: string;
	public anet_api_login_id?: string;
	public anet_api_transaction_key?: string;
	public anet_api_signature_key?: string;
	public anet_api_client_key?: string;
	public apikey?: string;
	public apipin?: string;
	public transaction_key?: string;
	public i_fields_key?: string;
	public software_name?: string;
	public software_version?: string;
	constructor() { }
}


export class Notification {
	public personal_notify: boolean;
	public technical_notify: boolean;
	public billing_notify: boolean;
	public task_notify: boolean;
	public notify_by: any[];
	public notification_duration: any;
	public notification_duration_type: string;
	public start_time: any;
	public end_time: any;
	public notify_days: any[];
	constructor() {
		this.personal_notify = false;
		this.technical_notify = false;
		this.billing_notify = false;
		this.task_notify = false;
		this.notify_by = [];
		this.notification_duration = null;
		this.notification_duration_type = 'minutes';
		this.start_time = null;
		this.end_time = null;
		this.notify_days = [];
	}
}