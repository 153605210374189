import { Injectable } from '@angular/core';
import { IMG_ADD_FILE_SVG, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class EndUsersChargebackContractService {

	public tabItemsList: any[] = [
		{ key: 'items', displayVal: 'Items' },
		{ key: 'chargebackdocuments', displayVal: 'Chargeback Documents' }
	];
	public editEndUsersChargebackGuid: any;
	public itemTabDataList: any = [];
	public chargebackDocumentTabDataList: any = [];
	public calledClaimedItem: string;
	public modifiedClaimId: string;
	private dataOutReqSubscription: Subscription;
	constructor(
		public _LoginService: LoginService,
		public _RestApiService: RestApiService,
		public _LoaderService: LoaderService,
		public _ToastrService: ToastrService,
	) { }

	public setEditEndUsersChargebackGuid(editEndUsersChargebackGuid) {
		this.editEndUsersChargebackGuid = editEndUsersChargebackGuid;
	}
	public getEditEndUsersChargebackGuid() {
		return this.editEndUsersChargebackGuid;
	}
	public setItemTabDataList(list) {
		this.itemTabDataList = list;
	}
	public getItemTabDataList() {
		return this.itemTabDataList;
	}
	public setChargebackDocumentTabDataList(list) {
		this.chargebackDocumentTabDataList = list;
	}
	public getChargebackDocumentTabDataList() {
		return this.chargebackDocumentTabDataList;
	}

	//short pay task list
	public getShortPayTaskList(selectCustomerId?: string): Observable<any> {
		let searchParams: any[];
		if (selectCustomerId) {
			searchParams = [['status', '=', 'open'], 'and', ['cardcode', '=', selectCustomerId]];
		} else {
			searchParams = [['status', '=', 'open']];
		}
		let sortParams: any[] = [{ "selector": "created_date", "desc": true }];

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.TASKS);
		formData.append('is_dropdown', 'true');
		formData.append('short_pay_task', 'true');
		formData.append('filter', JSON.stringify(searchParams));
		formData.append('sort', JSON.stringify(sortParams));
		//formData.append('view_fields', JSON.stringify(viewFields));
		return this._RestApiService.doDataOutReqFormData(formData);
	}
	//end

	public getModifiedClaimId() {
		return this.modifiedClaimId;
	}
	public setModifiedClaimId(modifiedClaimId) {
		this.modifiedClaimId = modifiedClaimId;
	}
	doGetListofApprovalStatus() {
		return [{
			"guid": "b67405da8a6de034625a2b4ace3c2ce7",
			"value": 'approved' || 'approve',
			"label": "APPROVED",
			"text": "APPROVE",
			"finaltext": "APPROVED",
			"bgcolor": "#03b86c",
			"classname": "green",
			"classnameBg": "greenBg",
		},
		{
			"guid": "1ea137e004825073e732a5dac6a15487",
			"value": 'inprocess' || '',
			"label": "IN PROCESS",
			"text": "IN PROCESS",
			"finaltext": "IN PROCESS",
			"bgcolor": "#f2b80c",
			"classname": "orange",
			"classnameBg": "orangeBg",
		},
		// {
		// 	"guid": "1ea137e004825073e732a5dac6a14872",
		// 	"value": '',
		// 	"label": "IN PROCESS",
		// 	"text": "IN PROCESS",
		// 	"finaltext": "IN PROCESS",
		// 	"bgcolor": "#f2b80c",
		// 	"classname": "orange",
		// 	"classnameBg": "orangeBg",
		// },
		{
			"guid": "9ea137e004825073e732a5dac6a37702",
			"value": 'rejected',
			"label": "REJECTED",
			"text": "REJECTED",
			"finaltext": "REJECTED",
			"bgcolor": "#f2b80c",
			"classname": "red",
			"classnameBg": "redBg",
		},
		{
			"guid": "9ea137e004825073e732a5dac6a4875",
			"value": 'denied',
			"label": "DENIED",
			"text": "DENIED",
			"finaltext": "DENIED",
			"bgcolor": "#f2b80c",
			"classname": "red",
			"classnameBg": "redBg",
		},
		{
			"guid": "9ea111e004825073e732a5dac6a9874",
			"value": 'cancelled',
			"label": "CANCELLED",
			"text": "CANCELLED",
			"finaltext": "CANCELLED",
			"bgcolor": "#f2b80c",
			"classname": "red",
			"classnameBg": "redBg",
		},
		{
			"guid": "3ea139e004825073e732a5dac6a954785",
			"value": 'partial',
			"label": " PARTIAL",
			"text": " PARTIAL",
			"finaltext": "PARTIAL",
			"bgcolor": "#f2b80c",
			"classname": "orange",
			"classnameBg": "orangeBg",
		},
		{
			"guid": "3ea139e0000025073e732a5dac6a9552134",
			"value": 'under_review',
			"label": "MARK CLAIM TO REDO",
			"text": " MARK CLAIM TO REDO",
			"finaltext": "UNDER REVIEW",
			"bgcolor": "#f2b80c",
			"classname": "orange",
			"classnameBg": "orangeBg",
		}
		];
	}
	doGetListofApprovalStatusForView() {
		return [{
			"guid": "b67405da8a6de034625a2b4ace3c2ce7",
			"value": 'approved' || 'approve',
			"label": "APPROVE",
			"text": "APPROVE",
			"finaltext": "APPROVED",
			"bgcolor": "#03b86c",
			"classname": "greenBg",
		},
		{
			"guid": "1ea137e004825073e732a5dac6a15487",
			"value": 'inprocess' || '',
			"label": "IN PROCESS",
			"text": "IN PROCESS",
			"finaltext": "IN PROCESS",
			"bgcolor": "#f2b80c",
			"classname": "orangeBg",
		},
		{
			"guid": "3ea139e0000025073e732a5dac6a9552134",
			"value": 'under_review',
			"label": "MARK CLAIM TO REDO",
			"text": " MARK CLAIM TO REDO",
			"finaltext": "UNDER REVIEW",
			"bgcolor": "#f2b80c",
			"classname": "orangeBg",
		}
			// {
			// 	"guid": "9ea137e004825073e732a5dac6a37702",
			// 	"value": 'decline',
			// 	"label": "DECLINE",
			// 	"text": "DECLINE",
			// 	"finaltext": "DECLINED",
			// 	"bgcolor": "#f2b80c",
			// 	"classname": "redBg",
			// }
		];
	}
	doGetListofApprovalStatusForHeaderFilter() {
		return [{
			"guid": "b67405da8a6de034625a2b4ace3c2ce7",
			"value": 'approved',
			"label": "APPROVED",
			"text": "APPROVED",
			"finaltext": "APPROVE",
			"bgcolor": "#03b86c",
			"classname": "greenBg",
		},
		{
			"guid": "1ea137e004825073e732a5dac6a15487",
			"value": 'inprocess',
			"label": "IN PROCESS",
			"text": "IN PROCESS",
			"finaltext": "IN PROCESS",
			"bgcolor": "#f2b80c",
			"classname": "orangeBg",
		},
		{
			"guid": "3ea139e0000025073e732a5dac6a9552134",
			"value": 'under_review',
			"label": "UNDER REVIEW",
			"text": "UNDER REVIEW",
			"finaltext": "UNDER REVIEW",
			"bgcolor": "#f2b80c",
			"classname": "orangeBg",
		},
		{
			"guid": "9ea111e004825073e732a5dac6a9874",
			"value": 'cancelled',
			"label": "CANCELLED",
			"text": "CANCELLED",
			"finaltext": "CANCELLED",
			"bgcolor": "#f2b80c",
			"classname": "red",
			"classnameBg": "redBg",
		},
		{
			"guid": "9ea137e004825073e732a5dac6a37702",
			"value": 'rejected',
			"label": "REJECTED",
			"text": "REJECTED",
			"finaltext": "REJECTED",
			"bgcolor": "#f2b80c",
			"classname": "red",
			"classnameBg": "redBg",
		},
		{
			"guid": "3ea139e004825073e732a5dac6a954785",
			"value": 'partial',
			"label": " PARTIAL",
			"text": " PARTIAL",
			"finaltext": "PARTIAL",
			"bgcolor": "#f2b80c",
			"classname": "orange",
			"classnameBg": "orangeBg",
		}]
	}

	public getListOfActionsForDatagrid() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add Claim',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	get getListOfActionsForApprovalChargebackDatagrid() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			},

		];
	}

	public getEndUserDetail(contractguid) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', ServerEntity.ENDUSERSCHARGEBACKCONTRACTS_DETAILS);
		formData.append('contractguid', contractguid);
		const viewFields = ['guid', 'contractName', 'selectCustomer', 'selectCustomerId', 'is_enforce_quantity_sold'];
		formData.append('view_fields', JSON.stringify(viewFields));
		//formData.append('search', JSON.stringify(searchParams));
		this._LoaderService.show();
		return this._RestApiService.doDataOutReqFormData((formData));
	}
}
