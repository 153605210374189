import { Injectable, Injector } from '@angular/core';
import { environment } from "@environments/environment";
import { CreateTransactionRequestModel, CreateCustomerProfileRequestModel, CreateCustomerPaymentProfileRequestModel, DeleteCustomerPaymentProfileRequestModel, UserDataPaymentModel } from '@app/models/authorize-payment.model';
import { RestApiCallService } from '@app/services/rest-api-call/rest-api-call.service';
import { AuthorizePaymentDataService } from '@app/services/authorize-payment/authorize-payment-data.service';
import { DynamicScriptLoaderService } from '@app/services/dynamic-script-loader/dynamic-script-loader.service';
import { Observable, from } from 'rxjs';
import { AppCommonSrvc } from '../app-common-srvc/app-common-srvc.service';
declare var Accept: any;
@Injectable({
	providedIn: 'root'
})
export class AuthorizePaymentService {
	private clientKey = environment.AUTH_PAY_CLIENT_KEY;
	private transactionKey = environment.AUTH_PAY_TRASACTION_KEY;
	private apiLoginID = environment.AUTH_PAY_API_LOGIN_ID;
	private serviceUrl = environment.AUTH_PAY_SERVICE_URL;

	private dataDescriptor;
	private dataValue;
	private userData: UserDataPaymentModel = new UserDataPaymentModel();
	private CIMData: any;
	private customerProfileId = "";
	private customerPaymentProfileId = "";
	private paymentResponse = "";
	private profileID = "";
	private paymentProfileIDs = [];
	private _RestApiCallService: any;
	constructor(private dynamicScriptLoader: DynamicScriptLoaderService,
		private _AuthorizePaymentDataService: AuthorizePaymentDataService,
		private _AppCommonSrvc: AppCommonSrvc,
		private _Injector: Injector) {
		this._RestApiCallService = _Injector.get(RestApiCallService);
		this.loadAcceptJsScripts();
	}

	private loadAcceptJsScripts() {
		// You can load multiple scripts by just providing the key as argument into load method of the service
		this.dynamicScriptLoader.load('accept').then(data => {
		}).catch(error => console.log(error));
	}

	sendPaymentDataToAnet(): Observable<any> {
		this.userData = this._AuthorizePaymentDataService.getProfileUserData();
		const authData = { clientKey: '', apiLoginID: '' };
		authData.clientKey = this.clientKey;
		authData.apiLoginID = this.apiLoginID;

		const cardData = { cardNumber: '', month: '', year: '', cardCode: '' };
		cardData.cardNumber = this.userData.cardNumber;
		cardData.month = this.userData.expMonth;
		cardData.year = this.userData.expYear;
		cardData.cardCode = this.userData.cardCode;

		const secureData = { authData: null, cardData: null };
		secureData.authData = authData;
		secureData.cardData = cardData;
		return from(this.callUseOpaqueData(secureData));
	}

	async callUseOpaqueData(secureData) {
		return await new Promise((resolve, reject) => {
			Accept.dispatchData(secureData, response => {
				if (response.messages.resultCode === 'Error') {

					for (let i = 0; i < response.messages.message.length; i++) {
						// console.log(response.messages.message[i].code + ':' + response.messages.message[i].text);
					}
					resolve(response);
				} else {
					// console.log("useOpaqueData::: ", response.opaqueData);
					this.dataValue = response.opaqueData.dataValue;
					this.dataDescriptor = response.opaqueData.dataDescriptor;
					resolve(response);
				}
			});
		});
	}

	prepareCIMToAuthorizedDotNet(): Observable<any> {
		this.userData = this._AuthorizePaymentDataService.getProfileUserData();
		// console.log("prepareCIMToAuthorizedDotNet");
		const emailid = this.userData.email;
		const snMakeId = this.makeId();
		const cmiJsonData: CreateCustomerProfileRequestModel = new CreateCustomerProfileRequestModel();
		cmiJsonData.createCustomerProfileRequest.merchantAuthentication.name = this.apiLoginID;
		cmiJsonData.createCustomerProfileRequest.merchantAuthentication.transactionKey = this.transactionKey;
		cmiJsonData.createCustomerProfileRequest.profile.merchantCustomerId = snMakeId;
		cmiJsonData.createCustomerProfileRequest.profile.description = 'Profile description here';
		cmiJsonData.createCustomerProfileRequest.profile.email = emailid;
		cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.customerType = 'business';
		cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.payment.opaqueData.dataDescriptor = this.dataDescriptor;
		cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.payment.opaqueData.dataValue = this.dataValue;
		cmiJsonData.createCustomerProfileRequest.validationMode = environment.validationMode ? 'liveMode' : 'testMode';
		cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.firstName = this.userData.billTo.firstName ? this.userData.billTo.firstName.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.lastName = this.userData.billTo.lastName ? this.userData.billTo.lastName.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.company = this.userData.billTo.company ? this.userData.billTo.company.toString().substring(0, 50) : '';
		cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.address = this.userData.billTo.address ? this.userData.billTo.address.toString().substring(0, 60) : '';
		cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.city = this.userData.billTo.city ? this.userData.billTo.city.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.state = this.userData.billTo.state ? this.userData.billTo.state.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.zip = this.userData.billTo.zip ? this.userData.billTo.zip.toString().substring(0, 20) : '';
		cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.country = this.userData.billTo.country ? this.userData.billTo.country.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.phoneNumber = this.userData.billTo.phoneNumber ? this.userData.billTo.phoneNumber.toString().substring(0, 20) : '';
		delete cmiJsonData.createCustomerProfileRequest.transactionRequest;
		// const cmiJsonData1 = '{"createCustomerProfileRequest": {"merchantAuthentication": {"name": "' + this.apiLoginID + '","transactionKey": "' + this.transactionKey + '"},"profile": {"merchantCustomerId": "' + snMakeId + '","description": "Profile description here","email": "' + emailid + '","paymentProfiles": {"customerType": "business","payment": {"opaqueData": {"dataDescriptor": "' + this.dataDescriptor + '","dataValue": "' + this.dataValue + '"}}}},"validationMode": "testMode"}}';
		const cmiJsonData2 = {
			"createCustomerProfileRequest": {
				"merchantAuthentication": {
					"name": cmiJsonData.createCustomerProfileRequest.merchantAuthentication.name,
					"transactionKey": cmiJsonData.createCustomerProfileRequest.merchantAuthentication.transactionKey
				},
				"profile": {
					"merchantCustomerId": cmiJsonData.createCustomerProfileRequest.profile.merchantCustomerId,
					"description": "Profile description here",
					"email": cmiJsonData.createCustomerProfileRequest.profile.email,
					"paymentProfiles": {
						"customerType": cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.customerType,
						"billTo": {
							"firstName": cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.firstName,
							"lastName": cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.lastName,
							"company": cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.company,
							"address": cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.address,
							"city": cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.city,
							"state": cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.state,
							"zip": cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.zip,
							"country": cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.country,
							"phoneNumber": cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.billTo.phoneNumber,
						},
						"payment": {
							"opaqueData": {
								"dataDescriptor": cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.payment.opaqueData.dataDescriptor,
								"dataValue": cmiJsonData.createCustomerProfileRequest.profile.paymentProfiles.payment.opaqueData.dataValue
							}
						}
					}
				},
				"validationMode": environment.validationMode ? 'liveMode' : 'testMode'
			}
		};
		return this._RestApiCallService.postAuthorizePayNet(this.serviceUrl, JSON.stringify(cmiJsonData2));
	}

	makeId() {
		let text = "";
		const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (let i = 0; i < 5; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	}

	getCIMDataPaymentDataToAnet(): Observable<any> {
		this.userData = this._AuthorizePaymentDataService.getProfileUserData();
		// this.CIMData = this._AuthorizePaymentDataService.getCMIData();
		// console.log("getCIMDataPaymentDataToAnet");
		const customerProfileId = this._AuthorizePaymentDataService.getProfilID();
		const paymentProfileId = this._AuthorizePaymentDataService.getPaymentProfilIDs();

		const createTransactionRequestModel: CreateTransactionRequestModel = new CreateTransactionRequestModel();
		createTransactionRequestModel.createTransactionRequest.merchantAuthentication.name = this.apiLoginID;
		createTransactionRequestModel.createTransactionRequest.merchantAuthentication.transactionKey = this.transactionKey;
		createTransactionRequestModel.createTransactionRequest.refId = this.userData.order.invoiceNumber;
		createTransactionRequestModel.createTransactionRequest.transactionRequest.transactionType = 'authCaptureTransaction';
		createTransactionRequestModel.createTransactionRequest.transactionRequest.amount = this.userData.amount;
		createTransactionRequestModel.createTransactionRequest.transactionRequest.profile.paymentProfiles.paymentProfileId = paymentProfileId;
		createTransactionRequestModel.createTransactionRequest.transactionRequest.profile.customerProfileId = customerProfileId;
		// console.log('payThroughCIMObject: ', createTransactionRequestModel);
		const JsonData = {
			"createTransactionRequest": {
				"merchantAuthentication": {
					"name": createTransactionRequestModel.createTransactionRequest.merchantAuthentication.name,
					"transactionKey": createTransactionRequestModel.createTransactionRequest.merchantAuthentication.transactionKey
				},
				"refId": createTransactionRequestModel.createTransactionRequest.refId,
				"transactionRequest": {
					"transactionType": "authCaptureTransaction",
					"amount": this._AppCommonSrvc.toFixedWithoutRounding(createTransactionRequestModel.createTransactionRequest.transactionRequest.amount, 2),
					"profile": {
						"customerProfileId": createTransactionRequestModel.createTransactionRequest.transactionRequest.profile.paymentProfiles.paymentProfileId,
						"paymentProfile": {
							"paymentProfileId": createTransactionRequestModel.createTransactionRequest.transactionRequest.profile.customerProfileId
						}
					}
				}
			}
		};

		return this._RestApiCallService.postAuthorizePayNet(this.serviceUrl, JSON.stringify(JsonData));
	}

	newAddPaymentProfileDataAnet(newCardData): Observable<any> {
		const authData = { clientKey: '', apiLoginID: '' };
		authData.clientKey = this.clientKey;
		authData.apiLoginID = this.apiLoginID;

		const cardData = { cardNumber: '', month: '', year: '', cardCode: '' };
		cardData.cardNumber = newCardData.cardnumber;
		cardData.month = newCardData.expirmonth;
		cardData.year = newCardData.expiryear;
		cardData.cardCode = newCardData.cvccode;

		const secureData = { authData: null, cardData: null };
		secureData.authData = authData;
		secureData.cardData = cardData;
		return from(this.callUseOpaqueData(secureData));
	}

	prepareJSONForNewAdd(): Observable<any> {
		this.customerProfileId = this._AuthorizePaymentDataService.getProfilID();
		this.userData = this._AuthorizePaymentDataService.getProfileUserData();
		const cmiJsonData: CreateCustomerPaymentProfileRequestModel = new CreateCustomerPaymentProfileRequestModel();
		cmiJsonData.createCustomerPaymentProfileRequest.merchantAuthentication.name = this.apiLoginID;
		cmiJsonData.createCustomerPaymentProfileRequest.merchantAuthentication.transactionKey = this.transactionKey;
		cmiJsonData.createCustomerPaymentProfileRequest.customerProfileId = this.customerProfileId;
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.payment.opaqueData.dataDescriptor = this.dataDescriptor;
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.payment.opaqueData.dataValue = this.dataValue;
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.defaultPaymentProfile = false;
		cmiJsonData.createCustomerPaymentProfileRequest.validationMode = environment.validationMode ? 'liveMode' : 'testMode';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.firstName = this.userData.billTo.firstName ? this.userData.billTo.firstName.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.lastName = this.userData.billTo.lastName ? this.userData.billTo.lastName.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.company = this.userData.billTo.company ? this.userData.billTo.company.toString().substring(0, 50) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.address = this.userData.billTo.address ? this.userData.billTo.address.toString().substring(0, 60) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.city = this.userData.billTo.city ? this.userData.billTo.city.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.state = this.userData.billTo.state ? this.userData.billTo.state.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.zip = this.userData.billTo.zip ? this.userData.billTo.zip.toString().substring(0, 20) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.country = this.userData.billTo.country ? this.userData.billTo.country.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.phoneNumber = this.userData.billTo.phoneNumber ? this.userData.billTo.phoneNumber.toString().substring(0, 20) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.defaultPaymentProfile = this.userData.defaultPaymentProfile;
		delete cmiJsonData.createCustomerPaymentProfileRequest.transactionRequest;
		// const cmiJsonData = '{"createCustomerPaymentProfileRequest":{"merchantAuthentication":{"name": "' + this.apiLoginID + '","transactionKey": "' + this.transactionKey + '"},"customerProfileId":"' + this.customerProfileId + '","paymentProfile":{"payment":{"opaqueData":{"dataDescriptor": "' + this.dataDescriptor + '","dataValue": "' + this.dataValue + '"}},"defaultPaymentProfile":false},"validationMode":"testMode"}}';
		// $(".cim_request").val(cmiJsonData);
		const cPPJsonData = {
			"createCustomerPaymentProfileRequest": {
				"merchantAuthentication": {
					"name": cmiJsonData.createCustomerPaymentProfileRequest.merchantAuthentication.name,
					"transactionKey": cmiJsonData.createCustomerPaymentProfileRequest.merchantAuthentication.transactionKey
				},
				"customerProfileId": cmiJsonData.createCustomerPaymentProfileRequest.customerProfileId,
				"paymentProfile": {
					"customerType": "business",
					"billTo": {
						"firstName": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.firstName,
						"lastName": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.lastName,
						"company": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.company,
						"address": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.address,
						"city": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.city,
						"state": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.state,
						"zip": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.zip,
						"country": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.country,
						"phoneNumber": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.phoneNumber,
					},
					"payment": {
						"opaqueData": {
							"dataDescriptor": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.payment.opaqueData.dataDescriptor,
							"dataValue": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.payment.opaqueData.dataValue
						}
					},
					"defaultPaymentProfile": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.defaultPaymentProfile
				},
				"validationMode": environment.validationMode ? 'liveMode' : 'testMode'
			}
		};
		return this._RestApiCallService.postAuthorizePayNet(this.serviceUrl, JSON.stringify(cPPJsonData));
	}

	prepareJSONForUpdatePaymentProfile(): Observable<any> {
		this.customerProfileId = this._AuthorizePaymentDataService.getProfilID();
		const getPaymentProfileIDs = this._AuthorizePaymentDataService.getPaymentProfilIDs();
		const paymentProfileId = getPaymentProfileIDs[0];
		this.userData = this._AuthorizePaymentDataService.getProfileUserData();
		const cmiJsonData: CreateCustomerPaymentProfileRequestModel = new CreateCustomerPaymentProfileRequestModel();
		cmiJsonData.createCustomerPaymentProfileRequest.merchantAuthentication.name = this.apiLoginID;
		cmiJsonData.createCustomerPaymentProfileRequest.merchantAuthentication.transactionKey = this.transactionKey;
		cmiJsonData.createCustomerPaymentProfileRequest.customerProfileId = this.customerProfileId;
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.defaultPaymentProfile = false;
		cmiJsonData.createCustomerPaymentProfileRequest.validationMode = environment.validationMode ? 'liveMode' : 'testMode';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.defaultPaymentProfile = this.userData.defaultPaymentProfile;
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.customerPaymentProfileId = paymentProfileId;
		delete cmiJsonData.createCustomerPaymentProfileRequest.transactionRequest;
		// const cmiJsonData = '{"createCustomerPaymentProfileRequest":{"merchantAuthentication":{"name": "' + this.apiLoginID + '","transactionKey": "' + this.transactionKey + '"},"customerProfileId":"' + this.customerProfileId + '","paymentProfile":{"payment":{"opaqueData":{"dataDescriptor": "' + this.dataDescriptor + '","dataValue": "' + this.dataValue + '"}},"defaultPaymentProfile":false},"validationMode":"testMode"}}';
		// $(".cim_request").val(cmiJsonData);
		const cPPJsonData = {
			"updateCustomerPaymentProfileRequest": {
				"merchantAuthentication": {
					"name": cmiJsonData.createCustomerPaymentProfileRequest.merchantAuthentication.name,
					"transactionKey": cmiJsonData.createCustomerPaymentProfileRequest.merchantAuthentication.transactionKey
				},
				"customerProfileId": cmiJsonData.createCustomerPaymentProfileRequest.customerProfileId,
				"paymentProfile": {
					"payment": {
						"creditCard": {
							"cardNumber": this.userData.cardNumber,
							"expirationDate": this.userData['expirationDate']
						}
					},
					"defaultPaymentProfile": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.defaultPaymentProfile,
					"customerPaymentProfileId": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.customerPaymentProfileId
				},
				"validationMode": environment.validationMode ? 'liveMode' : 'testMode'
			}
		};
		return this._RestApiCallService.postAuthorizePayNet(this.serviceUrl, JSON.stringify(cPPJsonData));
	}

	// Method used to Get Payment Profile Details
	public getCustomerPaymentProfileDetails(): Observable<any> {
		this.customerProfileId = this._AuthorizePaymentDataService.getProfilID();
		const getPaymentProfileIDs = this._AuthorizePaymentDataService.getPaymentProfilIDs();
		const paymentProfileId = getPaymentProfileIDs[0];
		const cPPJsonData = {
			"getCustomerPaymentProfileRequest": {
				"merchantAuthentication": {
					"name": this.apiLoginID,
					"transactionKey": this.transactionKey
				},
				"customerProfileId": this.customerProfileId,
				"customerPaymentProfileId": paymentProfileId,
				"unmaskExpirationDate": "true",
				"includeIssuerInfo": "true"
			}
		};
		return this._RestApiCallService.postAuthorizePayNet(this.serviceUrl, JSON.stringify(cPPJsonData));
	}

	deletePaymentProfileDataAnet(custProfileId, custPaymentProfileId): Observable<any> {
		const authData = { clientKey: '', apiLoginID: '' };
		authData.clientKey = this.clientKey;
		authData.apiLoginID = this.apiLoginID;
		this.customerProfileId = custProfileId;
		this.customerPaymentProfileId = custPaymentProfileId;
		const cmiJsonData: DeleteCustomerPaymentProfileRequestModel = new DeleteCustomerPaymentProfileRequestModel();
		cmiJsonData.deleteCustomerPaymentProfileRequest.merchantAuthentication.name = this.apiLoginID;
		cmiJsonData.deleteCustomerPaymentProfileRequest.merchantAuthentication.transactionKey = this.transactionKey;
		cmiJsonData.deleteCustomerPaymentProfileRequest.customerProfileId = this.customerProfileId;
		cmiJsonData.deleteCustomerPaymentProfileRequest.customerPaymentProfileId = this.customerPaymentProfileId;
		// const cmiJsonData = '{"deleteCustomerPaymentProfileRequest":{"merchantAuthentication":{"name": "' + this.apiLoginID + '","transactionKey": "' + this.transactionKey + '"},"customerProfileId":"' + this.customerProfileId + '","customerPaymentProfileId":"' + this.customerPaymentProfileId + '"}}';
		const JsonData = {
			"deleteCustomerPaymentProfileRequest": {
				"merchantAuthentication": {
					"name": cmiJsonData.deleteCustomerPaymentProfileRequest.merchantAuthentication.name,
					"transactionKey": cmiJsonData.deleteCustomerPaymentProfileRequest.merchantAuthentication.transactionKey
				},
				"customerProfileId": cmiJsonData.deleteCustomerPaymentProfileRequest.customerProfileId,
				"customerPaymentProfileId": cmiJsonData.deleteCustomerPaymentProfileRequest.customerPaymentProfileId
			}
		};
		// $(".cim_request").val(cmiJsonData);
		return this._RestApiCallService.postAuthorizePayNet(this.serviceUrl, JSON.stringify(JsonData));
	}

	// Charge Customer Profile

	chargeCustomerProfile(): Observable<any> {
		this.userData = this._AuthorizePaymentDataService.getProfileUserData();
		// this.CIMData = this._AuthorizePaymentDataService.getCMIData();
		const amount = this.userData.amount;
		const customerProfileId = this._AuthorizePaymentDataService.getProfilID();
		const paymentProfileId = this._AuthorizePaymentDataService.getPaymentProfilIDs();

		// const payThroughCIMObject = '{"createTransactionRequest": {"merchantAuthentication": {"name": "' + this.apiLoginID + '","transactionKey": "' + this.transactionKey + '"},"refId": "123456","transactionRequest": {"transactionType":"authCaptureTransaction","amount": "' + amount + '","profile": {"customerProfileId": "' + customerProfileId + '","paymentProfile": { "paymentProfileId": "' + paymentProfileId + '" }}}}}';
		const createTransactionRequestModel: CreateTransactionRequestModel = new CreateTransactionRequestModel();
		createTransactionRequestModel.createTransactionRequest.merchantAuthentication.name = this.apiLoginID;
		createTransactionRequestModel.createTransactionRequest.merchantAuthentication.transactionKey = this.transactionKey;
		createTransactionRequestModel.createTransactionRequest.refId = this.userData.order.invoiceNumber;
		createTransactionRequestModel.createTransactionRequest.transactionRequest.transactionType = 'authCaptureTransaction';
		createTransactionRequestModel.createTransactionRequest.transactionRequest.amount = amount;
		createTransactionRequestModel.createTransactionRequest.transactionRequest.profile.paymentProfiles.paymentProfileId = paymentProfileId;
		createTransactionRequestModel.createTransactionRequest.transactionRequest.profile.customerProfileId = customerProfileId;
		if (this.userData.order) {
			createTransactionRequestModel.createTransactionRequest.transactionRequest.order.invoiceNumber = this.userData.order.invoiceNumber ? this.userData.order.invoiceNumber : '';
			createTransactionRequestModel.createTransactionRequest.transactionRequest.order.description = this.userData.order.description ? this.userData.order.description.toString().substring(0, 255) : '';
		}
		if (this.userData.tax) {
			createTransactionRequestModel.createTransactionRequest.transactionRequest.tax.amount = this.userData.tax.amount || 0;
			createTransactionRequestModel.createTransactionRequest.transactionRequest.tax.name = this.userData.tax.name || '';
			createTransactionRequestModel.createTransactionRequest.transactionRequest.tax.description = this.userData.tax.description || '';
		}
		createTransactionRequestModel.createTransactionRequest.transactionRequest.poNumber = this.userData.poNumber ? this.userData.poNumber.toString().substring(0, 40) : '';
		if (this.userData.shipTo) {
			createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.firstName = this.userData.shipTo.firstName ? this.userData.shipTo.firstName.toString().substring(0, 40) : '';
			createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.lastName = this.userData.shipTo.lastName ? this.userData.shipTo.lastName.toString().substring(0, 40) : '';
			createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.company = this.userData.shipTo.company ? this.userData.shipTo.company.toString().substring(0, 50) : '';
			createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.address = this.userData.shipTo.address ? this.userData.shipTo.address.toString().substring(0, 60) : '';
			createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.city = this.userData.shipTo.city ? this.userData.shipTo.city.toString().substring(0, 40) : '';
			createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.state = this.userData.shipTo.state ? this.userData.shipTo.state.toString().substring(0, 40) : '';
			createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.zip = this.userData.shipTo.zip ? this.userData.shipTo.zip.toString().substring(0, 20) : '';
			createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.country = this.userData.shipTo.country ? this.userData.shipTo.country.toString().substring(0, 40) : '';
		}
		// console.log('payThroughCIMObject: ', createTransactionRequestModel);
		const chargeCustomerJson = {
			"createTransactionRequest": {
				"merchantAuthentication": {
					"name": createTransactionRequestModel.createTransactionRequest.merchantAuthentication.name,
					"transactionKey": createTransactionRequestModel.createTransactionRequest.merchantAuthentication.transactionKey
				},
				"refId": createTransactionRequestModel.createTransactionRequest.refId,
				"transactionRequest": {
					"transactionType": createTransactionRequestModel.createTransactionRequest.transactionRequest.transactionType,
					"amount": this._AppCommonSrvc.toFixedWithoutRounding(createTransactionRequestModel.createTransactionRequest.transactionRequest.amount, 2),
					"profile": {
						"customerProfileId": createTransactionRequestModel.createTransactionRequest.transactionRequest.profile.customerProfileId,
						"paymentProfile": {
							"paymentProfileId": createTransactionRequestModel.createTransactionRequest.transactionRequest.profile.paymentProfiles.paymentProfileId
						}
					},
					"order": {
						"invoiceNumber": createTransactionRequestModel.createTransactionRequest.transactionRequest.order.invoiceNumber,
						"description": createTransactionRequestModel.createTransactionRequest.transactionRequest.order.description
					},
					"tax": {
						"amount": this._AppCommonSrvc.toFixedWithoutRounding(createTransactionRequestModel.createTransactionRequest.transactionRequest.tax.amount, 2),
						"name": createTransactionRequestModel.createTransactionRequest.transactionRequest.tax.name,
						"description": createTransactionRequestModel.createTransactionRequest.transactionRequest.tax.description
					},
					"poNumber": createTransactionRequestModel.createTransactionRequest.transactionRequest.poNumber,
					"shipTo": {
						"firstName": createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.firstName,
						"lastName": createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.lastName,
						"company": createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.company,
						"address": createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.address,
						"city": createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.city,
						"state": createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.state,
						"zip": createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.zip,
						"country": createTransactionRequestModel.createTransactionRequest.transactionRequest.shipTo.country
					}
				}
			}
		};

		return this._RestApiCallService.postAuthorizePayNet(this.serviceUrl, JSON.stringify(chargeCustomerJson));
	}

	getCardType(number) {
		// visa
		let re = new RegExp("^4");
		if (number.match(re) !== null) {
			return "Visa";
		}

		// Mastercard
		// Updated for Mastercard 2017 BINs expansion
		if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) {
			return "Mastercard";
		}

		// AMEX
		re = new RegExp("^3[47]");
		if (number.match(re) !== null) {
			return "AMEX";
		}

		// Discover
		re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
		if (number.match(re) != null) {
			return "Discover";
		}

		// Diners
		re = new RegExp("^36");
		if (number.match(re) != null) {
			return "Diners";
		}

		re = new RegExp("^3(?:0[0-5]|[68][0-9])[0-9]{11}$");
		if (number.match(re) != null) {
			return "Diners";
		}

		// Diners - Carte Blanche
		re = new RegExp("^30[0-5]");
		if (number.match(re) != null) {
			return "Diners - Carte Blanche";
		}

		re = new RegExp("^389[0-9]{11}$");
		if (number.match(re) != null) {
			return "Diners - Carte Blanche";
		}

		// JCB
		re = new RegExp("^35(2[89]|[3-8][0-9])");
		if (number.match(re) != null) {
			return "JCB";
		}

		// Visa Electron
		re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
		if (number.match(re) != null) {
			return "Visa Electron";
		}

		return "";
	}

	createCustomerProfileWithoutPaymentCard() {
		this.userData = this._AuthorizePaymentDataService.getProfileUserData();
		const snMakeId = this.makeId();
		const emailid = this.userData.email;
		const expirationDate = this.userData.expYear + '-' + this.userData.expMonth;
		const cmiJsonData: CreateCustomerPaymentProfileRequestModel = new CreateCustomerPaymentProfileRequestModel();
		cmiJsonData.createCustomerPaymentProfileRequest.merchantAuthentication.name = this.apiLoginID;
		cmiJsonData.createCustomerPaymentProfileRequest.merchantAuthentication.transactionKey = this.transactionKey;

		// cmiJsonData.createCustomerPaymentProfileRequest.customerProfileId = this.customerProfileId;
		// cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.payment.opaqueData.dataDescriptor = this.dataDescriptor;
		// cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.payment.opaqueData.dataValue = this.dataValue;

		cmiJsonData.createCustomerPaymentProfileRequest.validationMode = environment.validationMode ? 'liveMode' : 'testMode';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.firstName = this.userData.billTo.firstName ? this.userData.billTo.firstName.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.lastName = this.userData.billTo.lastName ? this.userData.billTo.lastName.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.company = this.userData.billTo.company ? this.userData.billTo.company.toString().substring(0, 50) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.address = this.userData.billTo.address ? this.userData.billTo.address.toString().substring(0, 60) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.city = this.userData.billTo.city ? this.userData.billTo.city.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.state = this.userData.billTo.state ? this.userData.billTo.state.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.zip = this.userData.billTo.zip ? this.userData.billTo.zip.toString().substring(0, 20) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.country = this.userData.billTo.country ? this.userData.billTo.country.toString().substring(0, 40) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.phoneNumber = this.userData.billTo.phoneNumber ? this.userData.billTo.phoneNumber.toString().substring(0, 20) : '';
		cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.defaultPaymentProfile = this.userData.defaultPaymentProfile;
		delete cmiJsonData.createCustomerPaymentProfileRequest.transactionRequest;

		const cPPJsonData = {
			"createCustomerProfileRequest": {
				"merchantAuthentication": {
					"name": cmiJsonData.createCustomerPaymentProfileRequest.merchantAuthentication.name,
					"transactionKey": cmiJsonData.createCustomerPaymentProfileRequest.merchantAuthentication.transactionKey
				},
				"profile": {
					"merchantCustomerId": snMakeId,
					"description": "Profile description here",
					"email": emailid,
					"paymentProfiles": {
						"customerType": "business",
						"billTo": {
							"firstName": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.firstName,
							"lastName": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.lastName,
							"company": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.company,
							"address": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.address,
							"city": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.city,
							"state": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.state,
							"zip": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.zip,
							"country": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.country,
							"phoneNumber": cmiJsonData.createCustomerPaymentProfileRequest.profile.paymentProfiles.billTo.phoneNumber
						},
						"payment": {
							"creditCard": {
								"cardNumber": this.userData.cardNumber,
								"expirationDate": expirationDate
							}
						},
						"defaultPaymentProfile": this.userData.defaultPaymentProfile,
					}
				},
				"validationMode": cmiJsonData.createCustomerPaymentProfileRequest.validationMode
			}
		};
		return this._RestApiCallService.postAuthorizePayNet(this.serviceUrl, JSON.stringify(cPPJsonData));
	}

	// Method used to Get Customer Profile Details
	public getCustomerProfileDetailsByProfileId(): Observable<any> {
		this.customerProfileId = this._AuthorizePaymentDataService.getProfilID();
		const cPPJsonData = {
			"getCustomerProfileRequest": {
				"merchantAuthentication": {
					"name": this.apiLoginID,
					"transactionKey": this.transactionKey
				},
				"customerProfileId": this.customerProfileId,
				"unmaskExpirationDate": "true",
				"includeIssuerInfo": "true"
			}
		};
		return this._RestApiCallService.postAuthorizePayNet(this.serviceUrl, JSON.stringify(cPPJsonData));
	}
	// Method used to Get Validate Customer Profile Details
	public getValidateCustomerPaymentProfile(): Observable<any> {
		this.customerProfileId = this._AuthorizePaymentDataService.getProfilID();
		const getPaymentProfileIDs = this._AuthorizePaymentDataService.getPaymentProfilIDs();
		const paymentProfileId = getPaymentProfileIDs[0];
		const cPPJsonData = {
			"validateCustomerPaymentProfileRequest": {
				"merchantAuthentication": {
					"name": this.apiLoginID,
					"transactionKey": this.transactionKey
				},
				"customerProfileId": this.customerProfileId,
				"customerPaymentProfileId": paymentProfileId,
				"validationMode": environment.validationMode ? 'liveMode' : 'testMode'
			}
		};
		return this._RestApiCallService.postAuthorizePayNet(this.serviceUrl, JSON.stringify(cPPJsonData));
	}
}
