import { formatNumber } from 'devextreme/localization';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyOptions } from '@app/models/common.model';
import { LoginService } from '@app/services/login.service';

@Pipe({
	name: 'customCurrencyPipe'
})

export class CustomCurrencyPipe implements PipeTransform {
	// https://stackoverflow.com/questions/38752324/angular-2-formatting-currency-brl-format
	private currency_locale: string;
	private currency_code: string;
	private currency_fraction: number;
	private numberFormatstyle: string = 'currency';

	constructor(private _LoginService: LoginService) {
		this.currency_code = this._LoginService.globalCurrency;
		this.currency_locale = this._LoginService.currencyLocale;
		this.currency_fraction = this._LoginService.decimalPointForCurrency;
	}

	transform(price: number, currencyOptions?: CurrencyOptions): any {
		if (currencyOptions?.locale) {
			this.currency_locale = currencyOptions.locale;
		}
		const numberFormatOptions: any = {
			style: this.numberFormatstyle,
			currency: 'USD',
			minimumIntegerDigits: 1,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			roundingMode: (price < 0) ? 'ceil' : 'floor'
		};

		if (currencyOptions?.style) {
			numberFormatOptions.style = currencyOptions?.style;
		} else {
			numberFormatOptions.style = this.numberFormatstyle;
		}
		if (currencyOptions?.currencyCode) {
			numberFormatOptions.currency = currencyOptions.currencyCode;
		} else {
			numberFormatOptions.currency = this.currency_code;
		}
		if (currencyOptions?.fractionDigit) {
			numberFormatOptions.minimumFractionDigits = currencyOptions.fractionDigit;
			numberFormatOptions.maximumFractionDigits = currencyOptions.fractionDigit;
		} else {
			numberFormatOptions.minimumFractionDigits = this.currency_fraction;
			numberFormatOptions.maximumFractionDigits = this.currency_fraction;
		}

		return new Intl.NumberFormat(this.currency_locale, numberFormatOptions).format(price || 0);
	}
}

@Pipe({
	name: 'customCurrencySymbolPipe'
})

export class CustomCurrencySymbolPipe implements PipeTransform {
	// https://stackoverflow.com/questions/38752324/angular-2-formatting-currency-brl-format
	private currency_locale: string;
	private currency_code: string;
	private currency_fraction: number;
	private numberFormatstyle: string = 'currency';

	constructor(private _LoginService: LoginService) {
		this.currency_code = this._LoginService.globalCurrency;
		this.currency_locale = this._LoginService.currencyLocale;
		this.currency_fraction = this._LoginService.decimalPointForCurrency;
	}

	transform(currencyOptions?: CurrencyOptions): any {
		if (currencyOptions?.locale) {
			this.currency_locale = currencyOptions.locale;
		}
		const numberFormatOptions: any = {
			style: this.numberFormatstyle,
			currency: 'USD',
			minimumIntegerDigits: 1,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		};

		if (currencyOptions?.style) {
			numberFormatOptions.style = currencyOptions?.style;
		} else {
			numberFormatOptions.style = this.numberFormatstyle;
		}
		if (currencyOptions?.currencyCode) {
			numberFormatOptions.currency = currencyOptions.currencyCode;
		} else {
			numberFormatOptions.currency = this.currency_code;
		}
		if (currencyOptions?.fractionDigit) {
			numberFormatOptions.minimumFractionDigits = currencyOptions.fractionDigit;
			numberFormatOptions.maximumFractionDigits = currencyOptions.fractionDigit;
		} else {
			numberFormatOptions.minimumFractionDigits = this.currency_fraction;
			numberFormatOptions.maximumFractionDigits = this.currency_fraction;
		}
		return new Intl.NumberFormat(this.currency_locale, numberFormatOptions)
			.formatToParts(1).find(x => x.type === "currency").value;
	}
}

@Pipe({
	name: 'customFormatCurrencyPipe'
})

export class CustomFormatCurrencyPipe implements PipeTransform {
	// https://stackoverflow.com/questions/38752324/angular-2-formatting-currency-brl-format
	private currency_locale: string;
	private currency_code: string;
	private currency_fraction: number;
	private numberFormatstyle: string = 'currency';

	constructor(private _LoginService: LoginService) {
		this.currency_code = this._LoginService.globalCurrency;
		this.currency_locale = this._LoginService.currencyLocale;
		this.currency_fraction = this._LoginService.decimalPointForCurrency;
	}

	transform(price: number, currencyOptions?: CurrencyOptions): any {
		if (currencyOptions?.locale) {
			this.currency_locale = currencyOptions.locale;
		}
		const numberFormatOptions = {
			style: this.numberFormatstyle,
			currency: 'USD',
			minimumIntegerDigits: 1,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			roundingMode: (price < 0) ? 'ceil' : 'floor'
		};

		if (currencyOptions?.style) {
			numberFormatOptions.style = currencyOptions?.style;
		} else {
			numberFormatOptions.style = this.numberFormatstyle;
		}
		if (currencyOptions?.currencyCode) {
			numberFormatOptions.currency = currencyOptions.currencyCode;
		} else {
			numberFormatOptions.currency = this.currency_code;
		}
		if (currencyOptions?.fractionDigit) {
			numberFormatOptions.minimumFractionDigits = currencyOptions.fractionDigit;
			numberFormatOptions.maximumFractionDigits = currencyOptions.fractionDigit;
		} else {
			numberFormatOptions.minimumFractionDigits = this.currency_fraction;
			numberFormatOptions.maximumFractionDigits = this.currency_fraction;
		}
		// return new Intl.NumberFormat(this.currency_locale, numberFormatOptions)
		// .formatToParts(1).find(x => x.type === "currency").value;
		return formatNumber(price, { type: "currency largeNumber", currency: numberFormatOptions.currency, precision: numberFormatOptions.minimumFractionDigits });
		// return formatNumber(123456.78, this.currency_locale, '1.' + 2 + '-' + 2);
	}
}
