import { saveAs } from 'file-saver';
import * as _ from 'underscore';
import { Injectable } from '@angular/core';
import { DataService } from '@app/services/data.service';
import { ErpTypeValEnum } from '@app/constants-enums/enums';
import { LoginService } from '@app/services/login.service';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import * as moment from 'moment';
@Injectable({
	providedIn: 'root'
})
export class BillbackService {
	public billBackType: string;
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public itemGridBoxColumns: any;
	public customerGridBoxColumns: any;
	public pricelistGridBoxColumns: any;
	public erpType: string;
	public productSearchItemType: string;
	public billbackTypeRadio: any[] = [
		{ text: "Billback", value: "billback" },
		{ text: "Chargeback", value: "chargeback" },
		{ text: "End-User Chargeback", value: "enduserschargeback" },
		{ text: "Distribution Chargeback", value: "distributionchargeback" },
		{ text: "Min-Max Chargeback", value: "minmaxenduserschargeback" }
	];

	constructor(
		public _DataService?: DataService,
		public _LoginService?: LoginService
	) {
		this.listOfUoms = this._DataService.getUOMs();
		this.listOfUomGroups = this._DataService.getUomGroups();
		this.erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
	}

	fetchBillbackTypeListRadio() {
		let billbackTypeList: any[] = JSON.parse(JSON.stringify(this.billbackTypeRadio));
		// note: if min max config not enabled remove Min-Max Chargeback type in billback type list.
		if (this._LoginService.loginUser.account_detail) {
			if (!this._LoginService.loginUser.account_detail['enable_min_max_claim']) {
				if (billbackTypeList.length > 0) {
					const indexMinMax = billbackTypeList.findIndex(i => i.value === 'minmaxenduserschargeback');
					if (indexMinMax !== -1) {
						billbackTypeList.splice(indexMinMax, 1);
					}
				}
			}
		}
		return billbackTypeList;
	}

	fetchErpType() {
		this.erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
	}

	getBillbackType() {
		return this.billBackType
	}

	setBillbackType(val: any) {
		this.billBackType = val;
	}

	getQbOnlineErpUser() {
		this.fetchErpType();
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			return true;
		} else {
			return false;
		}
	}

	getQbErpUser() {
		this.fetchErpType();
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			return true;
		} else {
			return false;
		}
	}

	getItemGridBoxColumn() {
		this.fetchErpType();
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			return this.itemGridBoxColumns = [
				{ dataField: 'itemsku', caption: 'SKU', width: 100 },
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			return this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else {
			return this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		}
	}

	getCustomerGridBoxColumn() {
		this.fetchErpType();
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			return this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
		} else if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			return this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code', visible: false },
				{ dataField: 'name', caption: 'Name' },
			];
		} else {
			return this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' },
				{ dataField: 'pricelistname', caption: 'Pricelist' }
			];
		}
	}

	getPriceListGridBoxColumn() {
		return this.pricelistGridBoxColumns = [
			{ dataField: 'pricelistnum', caption: 'No', visible: false },
			{ dataField: 'pricelistname', caption: 'Name' }
		];
	}
	getProductSearchType() {
		// product_search logic type (item/price)
		const productSearchList = this._LoginService.loginUser.account_detail.app_settings.product_search || [];
		if (productSearchList && productSearchList.length > 0) {
			const productSearchItem = productSearchList.filter(function (item) {
				return (item['key'] === 'chargebacks');
			});
			return this.productSearchItemType = (productSearchItem[0]) ? productSearchItem[0].type : '';
		}
	}
	// #region for Set UOMs
	public getlistOfUomByItemDetails(listOfUoms: any[], listOfUomGroups: any[], itemDetails: any, priceListNum?: string) {
		let listOfSpecificUOM = [];
		if (itemDetails) {
			if (priceListNum) {
				if (itemDetails.itemprices && itemDetails.itemprices.length > 0) {
					const itemPriceData = itemDetails.itemprices.filter(itemPrice => itemPrice.pricelist.toString().toLowerCase() === priceListNum.toString().toLowerCase());
					if (itemPriceData && itemPriceData.length > 0) {
						const itemPriceEle = itemPriceData[0];
						if (itemPriceEle.uomprices && itemPriceEle.uomprices.length > 0) {
							itemPriceEle.uomprices.forEach(itemUP => {
								if (itemUP.uomentry) {
									const uomIndex = this.listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUP.uomentry.toString().toLowerCase());
									if (uomIndex !== -1) {
										const newItemUomPriceClone = JSON.parse(JSON.stringify(itemUP));
										newItemUomPriceClone.absentry = this.listOfUoms[uomIndex].absentry;
										newItemUomPriceClone.code = this.listOfUoms[uomIndex].code;
										newItemUomPriceClone.name = this.listOfUoms[uomIndex].name;
										newItemUomPriceClone.price = itemUP.price || 0;
										listOfSpecificUOM.push(newItemUomPriceClone);
									}
								}
							});
						}
					}
				} else {
					listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(itemDetails, listOfUoms, listOfUomGroups);
				}
			} else {
				listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(itemDetails, listOfUoms, listOfUomGroups);
			}
		}
		return listOfSpecificUOM;
	}

	// Method used to set Default Uom by uomgroupentry from item
	public setDefaultUomByUomGroupEntry(itemDetails: any, listOfUoms?: any[], listOfUomGroups?: any[]) {
		const listOfSpecificUOM = [];
		if (itemDetails.uomgroupentry) {
			//const uomGroupLst = this._DataService.getUomGroups() || [];
			const uomGroupLst = listOfUomGroups || [];
			const uomGroupIndex = uomGroupLst.findIndex(itemUomGroup => itemUomGroup.absentry.toString().toLowerCase() === itemDetails.uomgroupentry.toString().toLowerCase());
			if (uomGroupIndex !== -1) {
				const uomGroupDefinitionCollection = uomGroupLst[uomGroupIndex].uomgroupdefinitioncollection || [];
				let newItemUomPice: any = {};
				uomGroupDefinitionCollection.forEach(itemUomGroupDefinitionCollection => {
					newItemUomPice = {};
					newItemUomPice = JSON.parse(JSON.stringify(itemUomGroupDefinitionCollection));
					const uomIndex = listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUomGroupDefinitionCollection.alternateuom.toString().toLowerCase());
					if (uomIndex !== -1) {
						console.log("this.listOfUoms[uomIndex] : ", listOfUoms[uomIndex]);
						newItemUomPice.absentry = listOfUoms[uomIndex].absentry;
						newItemUomPice.code = listOfUoms[uomIndex].code;
						newItemUomPice.name = listOfUoms[uomIndex].name;
						newItemUomPice.price = 0;
						listOfSpecificUOM.push(newItemUomPice);
					}
				});
			}
		}
		return listOfSpecificUOM;
	}

	public exportBillbackChargebackExcel(workbook, contratModel, workSheet, componentObj) {
		let fileName = '';
		let typeName;
		const billbackTypeIndex = this.billbackTypeRadio.findIndex(item => item.value === contratModel.billBackType);
		if (billbackTypeIndex !== -1) {
			typeName = this.billbackTypeRadio[billbackTypeIndex].text;
			fileName = typeName + 'Contract-' + contratModel.ixcode + '.xlsx';
		}
		const groupIndexesWithSingleItem = [];
		const listOfCustomerMasterDetailsRows = [];

		exportDataGrid({
			worksheet: workSheet,
			component: componentObj,
			autoFilterEnabled: true,
			topLeftCell: { row: 6, column: 1 },
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'sold') {
						excelCell.value = gridCell.value;
						listOfCustomerMasterDetailsRows.push({
							rowIndex: excelCell.fullAddress.row + 1,
							data: gridCell.data
						});
					}
				}
			}
		}).then((cellRange) => {

			const custRow1 = workSheet.getRow(2);
			custRow1.getCell(1).value = 'Type: ';
			custRow1.getCell(2).value = typeName;
			custRow1.getCell(2).alignment = { horizontal: 'left' };
			custRow1.getCell(4).value = 'Start Date: ';
			custRow1.getCell(5).value = moment(contratModel.startDate).format('YYYY-MM-DD');

			const custRow2 = workSheet.getRow(3);
			custRow2.getCell(1).value = 'Contract Name:';
			custRow2.getCell(2).value = contratModel.contractName || '';
			custRow2.getCell(4).value = 'End Date:';
			custRow2.getCell(5).value = moment(contratModel.endDate).format('YYYY-MM-DD');


			const custRow3 = workSheet.getRow(4);
			if (contratModel.billBackType === 'billback') {
				custRow3.getCell(1).value = 'Supplier:';
				custRow3.getCell(2).value = contratModel.selectSupplier || '';
			}
			if (contratModel.billBackType === 'chargeback') {
				custRow3.getCell(1).value = 'Customer:';
				custRow3.getCell(2).value = contratModel.selectCustomer || '';
				if (this.productSearchItemType === 'price') {
					custRow3.getCell(4).value = 'Price List:';
					custRow3.getCell(5).value = contratModel.selectCustomerPriceListName || '';
				}
			}
			if (contratModel.billBackType === 'enduserschargeback') {
				custRow3.getCell(1).value = 'Customer:';
				custRow3.getCell(2).value = contratModel.selectCustomer || '';
				if (this.productSearchItemType === 'price') {
					custRow3.getCell(4).value = 'Price List:';
					custRow3.getCell(5).value = contratModel.selectCustomerPriceListName || '';
				}
			}


			groupIndexesWithSingleItem.sort((a, b) => b - a).forEach((rowIndex) => {
				workSheet.getRow(rowIndex + 1).outlineLevel = undefined;
				workSheet.spliceRows(rowIndex, 2);
			});

			// Logic for Invoice Details rows
			const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
			let offset = 0;

			const insertRow = (index: number, rowOffset: any, outlineLevel: any) => {
				const currentIndex = index + rowOffset;
				const row = workSheet.insertRow(currentIndex, [], "n");
				for (let j = workSheet.rowCount + 1; j > currentIndex; j--) {
					workSheet.getRow(j).outlineLevel = workSheet.getRow(j - 1).outlineLevel;
				}
				row.outlineLevel = outlineLevel;
				return row;
			};

			let itemColumnsField = [
				"customer_name",
				"billbackAmount",
				"sold",
				"billed",
				"available"
			];
			let itemColumns = [
				"Customer Name",
				"Billback Amount(" + this._LoginService.globalCurrencySymbol + ")",
				"Sold",
				"Billed",
				"Available"
			];
			for (let i = 0; i < listOfCustomerMasterDetailsRows.length; i++) {

				const columnIndex = cellRange.from.column;
				let row = insertRow(listOfCustomerMasterDetailsRows[i].rowIndex + i, offset++, 2);
				// row.height = 24;
				// workSheet.mergeCells(row.number, columnIndex, row.number, 7);
				if (listOfCustomerMasterDetailsRows[i].data.customers && listOfCustomerMasterDetailsRows[i].data.customers.length > 0) {
					row = insertRow(listOfCustomerMasterDetailsRows[i].rowIndex + i, offset++, 2);
					itemColumns.forEach((columnName, currentColumnIndex) => {
						row.keepColumnWidths = true;
						Object.assign(row.getCell(columnIndex + currentColumnIndex), {
							value: columnName,
							// fill: {
							// 	type: "pattern",
							// 	pattern: "solid",
							// 	fgColor: { argb: "BEDFE6" }
							// },
							font: { bold: true },
							// border: {
							// 	bottom: borderStyle,
							// 	left: borderStyle,
							// 	right: borderStyle,
							// 	top: borderStyle
							// }
						});
					});

					let subTotalLineTotal = 0;
					listOfCustomerMasterDetailsRows[i].data.customers.forEach((itemDetails, itemIndex) => {
						const itemColumnIndexDetail = cellRange.from.column;
						row = insertRow(listOfCustomerMasterDetailsRows[i].rowIndex + i, offset++, 2);
						row.keepColumnWidths = true;
						itemColumnsField.forEach((columnName, currentColumnIndex) => {
							const cellObject = {
								value: itemDetails[columnName],
								// fill: {
								// 	type: "pattern",
								// 	pattern: "solid",
								// 	fgColor: { argb: "BEDFE6" }
								// },
								// border: {
								// 	bottom: borderStyle,
								// 	left: borderStyle,
								// 	right: borderStyle,
								// 	top: borderStyle
								// },
								alignment: { horizontal: 'left' }
							};

							Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
							row.keepColumnWidths = true;
						});
						// row = insertRow(listOfCustomerMasterDetailsRows[i].rowIndex + i, offset++, 2);
						row.keepColumnWidths = true;
					});

					row = insertRow(listOfCustomerMasterDetailsRows[i].rowIndex + i, offset++, 2);
					let rowLastCell: number;
					row.keepColumnWidths = true;
				}
				offset--;
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		// e.cancel = true;
	}


}
