import * as _ from 'underscore';
import { Injectable } from '@angular/core';
import { DataService } from '@app/services/data.service';
import { CommissionSettlementReportSectionEnum, ErpTypeValEnum } from '@app/constants-enums/enums';
import { LoginService } from '@app/services/login.service';
import { IMG_ADD_FILE_SVG, IMG_ICON_CLOSE_MULTIPLE_SETTLEMENT, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_EXPORT_PDF, IMG_ICON_REFRESH, IMG_ICON_UPDATE_CURRENT_SETTLEMENT, IMG_ICON_XLSXFILEPOPUPIX, ServerSections } from '@app/constants-enums/constants';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
@Injectable({
	providedIn: 'root'
})
export class CommissionService {

	public editCommissionGuid: string;
	public editCommissionTemplateGuid: string;
	public invoiceDetails: any;
	public commissionDetails: any;
	public itemGridBoxColumns: any;
	public customerGridBoxColumns: any;
	public erpType: string;
	public productSearchItemType: string;
	public agreementMainTab: string;
	public listOfFeatureCustomFields: any;
	public isOpenMultiSettlmentPopUp: boolean;

	public listOfRuleBonusTypes: any[] = [
		{ text: 'Inclusion/Exclusion Rule', value: 'inclusionExclusionRule' },
		{ text: 'Rate Rule', value: 'rateRule' },
		{ text: 'Bonus Amount', value: 'bonusAmount' },
		{ text: 'Bonus Rate', value: 'bonusRate' },
	];
	public listOfInclusionExclusionRuleConditions: any[] = [
		{ text: 'Include in Calculation', value: 'include' },
		{ text: 'Exclude in Calculation', value: 'exclude' },
		{ text: 'Exclude Late Invoice', value: 'excludeLateInvoice' },
	];
	public listOfRateRuleConditions: any[] = [
		{ text: 'Break Units of Measure', value: 'breakUnitsOfMeasure' },
		{ text: 'No. Items Less Than', value: 'itemsLessThan' },
		{ text: 'No. Items Greater Than', value: 'itemsGreaterThan' },
		{ text: 'Miscellaneous Deduction - Fixed Cost', value: 'miscellaneousDeductionFixedCost' },
		{ text: 'Miscellaneous Deduction - Item Percent', value: 'miscellaneousDeductionItemPercent' },
		// { text: 'Miscellaneous Deduction', value: 'miscellaneousDeduction' }, // hide this option as of IX-1615 story
		{ text: 'Miscellaneous Deduction - Inventory Item', value: 'miscellaneousDeductionInventory' },
		{ text: 'Miscellaneous Deduction - All Item', value: 'miscellaneousDeductionAllItem' },
	];
	public listOfBonusRateConditions: any[] = [
		{ text: 'New Account', value: 'newAccount' },
		{ text: 'Total Per Settlement Period Greater Than', value: 'totalPerSettlementPeriodGreaterThan' },
		{ text: 'Gross Profit Per Settlement Period Greater Than', value: 'grossProfitPerSettlementPeriod' },
		{ text: 'Number of Unique SKUs Greater Than', value: 'numberOfuniqueSKUsGreaterThan' },
		{ text: 'No. SKUs Shipped In Settlement Period Greater Than', value: 'SKUsShippedInSettlementPeriod' },

	];
	public listOfBonusRateConditionsForProfiltOnDocumentHeader: any[] = [
		{ text: 'Over List Rate', value: 'overListRate' }, // only enable while "Profit On Document Header" entity UDF 
	];
	public listOfBonusAmountConditions: any[] = [
		{ text: 'New Account', value: 'newAccount' },
		{ text: 'Per Active Customer', value: 'perActiveCustomer' },
		{ text: 'Total Per Settlement Period Greater Than', value: 'totalPerSettlementPeriodGreaterThan' },
		{ text: 'New Account With Monthly Orders Greater Than', value: 'newAccountWithMonthlyOrdersGreaterThan' },
	];
	public listOfInclusionExclusionRuleCriterions: any[] = [
		{ text: 'Unpaid Invoices', value: 'unpaidInvoices' },
		{ text: 'Billbacks / Chargebacks', value: 'billbacksChargebacks' },
		{ text: 'Cash Discounts', value: 'cashDiscounts' },
		{ text: 'Partially Paid Invoices', value: 'partialpaidInvoices' },
		{ text: 'Vendor Sponsored Promo', value: 'vendorsponsoredpromo' },
		// { text: 'Number Of Days Late', value: 'numberOfDaysLate' },
		// { text: 'Shipping Costs', value: 'shippingCosts' },
	];
	public listOfExclusionsForBuyingGroup: any[] = [
		{ text: 'Exclusion by Items', value: 'item' },
		{ text: 'Exclusion by Groups', value: 'group' },
	];
	public listOfContractCalendar: any[] = [
		{ text: 'Fiscal Year', value: 'fiscal_year' },
		{ text: 'Calender Year', value: 'calender_year' },
		{ text: 'Contract Year', value: 'contract_year' },
	];
	public decimalPointForCurrency: number;
	public decimalPointPercentageFormat: string;

	constructor(
		public _DataService?: DataService,
		public _LoginService?: LoginService,
		public _AppCommonSrvc?: AppCommonSrvc,
		public _CustomNumberNoRoundingPipe?: CustomNumberNoRoundingPipe,
	) {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
	}

	getAgreementMainTab() {
		return this.agreementMainTab;
	}
	setAgreementMainTab(val: any) {
		this.agreementMainTab = val;
	}
	getEditCommissionGuid() {
		return this.editCommissionGuid;
	}
	setEditCommissionGuid(val: any) {
		this.editCommissionGuid = val;
	}
	getEditCommissionTemplateGuid() {
		return this.editCommissionTemplateGuid;
	}
	setEditCommissionTemplateGuid(val: any) {
		this.editCommissionTemplateGuid = val;
	}
	getInvoiceDetails() {
		return this.invoiceDetails;
	}
	setInvoiceDetails(val: any) {
		this.invoiceDetails = val;
	}
	getlistOfFeatureCustomFields() {
		return this.listOfFeatureCustomFields;
	}
	setlistOfFeatureCustomFields(val: any) {
		this.listOfFeatureCustomFields = val;
	}
	getCommissionContractDetails() {
		return this.commissionDetails;
	}
	setCommissionContractDetails(val: any) {
		this.commissionDetails = val;
	}
	getIsOpenMultiSettlmentPopUp() {
		return this.isOpenMultiSettlmentPopUp;
	}
	setIsOpenMultiSettlmentPopUp(val: any) {
		this.isOpenMultiSettlmentPopUp = val;
	}

	public getlistOfUomByItemDetails(listOfUoms: any[], listOfUomGroups: any[], itemDetails: any, priceListNum?: string) {
		let listOfSpecificUOM = [];
		if (itemDetails) {
			if (priceListNum) {
				if (itemDetails.itemprices && itemDetails.itemprices.length > 0) {
					const itemPriceData = itemDetails.itemprices.filter(itemPrice => itemPrice.pricelist.toString().toLowerCase() === priceListNum.toString().toLowerCase());
					if (itemPriceData && itemPriceData.length > 0) {
						const itemPriceEle = itemPriceData[0];
						if (itemPriceEle.uomprices && itemPriceEle.uomprices.length > 0) {
							itemPriceEle.uomprices.forEach(itemUP => {
								if (itemUP.uomentry) {
									const uomIndex = listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUP.uomentry.toString().toLowerCase());
									if (uomIndex !== -1) {
										const newItemUomPriceClone = JSON.parse(JSON.stringify(itemUP));
										newItemUomPriceClone.absentry = listOfUoms[uomIndex].absentry;
										newItemUomPriceClone.code = listOfUoms[uomIndex].code;
										newItemUomPriceClone.name = listOfUoms[uomIndex].name;
										newItemUomPriceClone.price = itemUP.price || 0;
										listOfSpecificUOM.push(newItemUomPriceClone);
									}
								}
							});
						}
					}
				} else {
					listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(listOfUoms, listOfUomGroups, itemDetails);
				}
			} else {
				listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(listOfUoms, listOfUomGroups, itemDetails);
			}
		}
		return listOfSpecificUOM;
	}

	private setDefaultUomByUomGroupEntry(listOfUoms, listOfUomGroups, itemDetails) {
		const listOfSpecificUOM = [];
		const uomGroupLst = listOfUomGroups || [];
		if (itemDetails['uomgroupentry']) {
			const uomGroupIndex = uomGroupLst.findIndex(itemUomGroup => itemUomGroup.absentry.toString().toLowerCase() === itemDetails.uomgroupentry.toString().toLowerCase());
			if (uomGroupIndex !== -1) {
				const uomGroupDefinitionCollection = uomGroupLst[uomGroupIndex].uomgroupdefinitioncollection || [];
				let newItemUomPice: any = {};
				uomGroupDefinitionCollection.forEach(itemUomGroupDefinitionCollection => {
					newItemUomPice = {};
					newItemUomPice = JSON.parse(JSON.stringify(itemUomGroupDefinitionCollection));
					const uomIndex = listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUomGroupDefinitionCollection.alternateuom.toString().toLowerCase());
					if (uomIndex !== -1) {
						newItemUomPice.absentry = listOfUoms[uomIndex].absentry;
						newItemUomPice.code = listOfUoms[uomIndex].code;
						newItemUomPice.name = listOfUoms[uomIndex].name;
						newItemUomPice.price = 0;
						listOfSpecificUOM.push(newItemUomPice);
					}
				});
			}

		}
		return listOfSpecificUOM;
	}

	public getTierBasisForGrowth() {
		const tierBasis = [
			{
				code: 'volume',
				label: 'Volume'
			},
			{
				code: 'revenue',
				label: 'Revenue'
			}
		];
		return tierBasis;
	}

	public getTierBasis() {
		const tierBasis = [
			{
				code: 'volume',
				label: 'Volume'
			},
			{
				code: 'revenue',
				label: 'Revenue'
			},
			{
				code: 'notiers',
				label: 'Standard'
			}
		];
		return tierBasis;
	}

	public getCommissionBasis() {
		const commissionBasis = [
			{
				code: 'dollar',
				label: this._LoginService.globalCurrencySymbol
			},
			{
				code: 'percentage',
				label: '%'
			},
			{
				code: 'dollarperuom',
				label: this._LoginService.globalCurrencySymbol + ' / UOM'
			}
		];
		return commissionBasis;
	}

	public getTierBasisForClasses() {
		const tierBasis = [
			{
				code: 'revenue',
				label: 'Revenue'
			},
			{
				code: 'notiers',
				label: 'Standard'
			}
		];
		return tierBasis;
	}
	public getTierBasisForProfitOnHeader() {
		const tierBasis = [
			{
				code: 'revenue',
				label: 'Revenue'
			},
			{
				code: 'notiers',
				label: 'Standard'
			}
		];
		return tierBasis;
	}

	public getCommissionBasisForClasses() {
		const commissionBasis = [
			{
				code: 'dollar',
				label: this._LoginService.globalCurrencySymbol
			},
			{
				code: 'percentage',
				label: '%'
			}
		];
		return commissionBasis;
	}
	public getCommissionBasisForProfitOnHeader() {
		const commissionBasis = [
			{
				code: 'percentage',
				label: '%'
			}
		];
		return commissionBasis;
	}

	getQbOnlineErpUser() {
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			return true;
		} else {
			return false;
		}
	}

	getQbErpUser() {
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			return true;
		} else {
			return false;
		}
	}

	getItemGridBoxColumn() {
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			return this.itemGridBoxColumns = [
				{ dataField: 'itemsku', caption: 'SKU', width: 100 },
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			return this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else {
			return this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		}
	}

	getCustomerGridBoxColumn() {
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			return this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
		} else if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			return this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code', visible: false },
				{ dataField: 'name', caption: 'Name' },
			];
		} else {
			return this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
		}
	}

	getProductSearchType() {
		// product_search logic type (item/price)
		const productSearchList = this._LoginService.loginUser.account_detail.app_settings.product_search || [];
		if (productSearchList && productSearchList.length > 0) {
			const productSearchItem = productSearchList.filter(function (item) {
				return (item['key'] === 'chargebacks');
			});
			return this.productSearchItemType = (productSearchItem[0]) ? productSearchItem[0].type : '';
		}
	}

	getMonth() {
		return [
			{ value: '1', text: 'Jan' },
			{ value: '2', text: 'Feb' },
			{ value: '3', text: 'Mar' },
			{ value: '4', text: 'Apr' },
			{ value: '5', text: 'May' },
			{ value: '6', text: 'Jun' },
			{ value: '7', text: 'Jul' },
			{ value: '8', text: 'Aug' },
			{ value: '9', text: 'Sep' },
			{ value: '10', text: 'Oct' },
			{ value: '11', text: 'Nov' },
			{ value: '12', text: 'Dec' },
			{ value: '13', text: 'Jan' },
		];
	}

	// Convert object array of dynamic column on custom field for invoice
	public getCustomFieldForInvoice(responseData: any, tableColLen?: number) {
		let customFieldDataObj: any;
		let customFieldDataList: any[] = [];
		// responseData.forEach(element => {
		for (let i = 0; i < responseData.length; i++) {
			customFieldDataObj = {
				dataField: responseData[i].ix_custom_field,
				caption: responseData[i].custom_field_title,
				dataType: responseData[i].custom_field_type,
				visible: responseData[i].display,
				visibleIndex: tableColLen + i
			};
			if (responseData[i].custom_field_type === 'object') {
				customFieldDataObj['editorOptions'] = { showClearButton: true }
				customFieldDataObj['lookup'] = { dataSource: responseData[i].custom_field_values, displayExpr: "value", valueExpr: "key", showClearButton: true }
			}
			if (responseData[i].custom_field_type === 'date') {
				customFieldDataObj['format'] = 'yyyy-MM-dd';
			}
			if (responseData[i].custom_field_output_format === 'amount') {
				customFieldDataObj['format'] = { type: 'currency', precision: this.decimalPointForCurrency };
			}
			if (responseData[i].custom_field_output_format === 'percentage') {
				customFieldDataObj['customizeText'] = (cellInfo) => {
					return this._CustomNumberNoRoundingPipe.transform((+cellInfo.value || 0)) + '%';
				}
			}
			customFieldDataList.push(customFieldDataObj);
		}
		// });

		return customFieldDataList;
	}

	public getFeatureGuid() {
		let returnVal = '';
		if (this._LoginService.loginUser.account_detail['enable_invoice_custom_fields_feature']) {
			returnVal = 'bc541207c8644ec3b14fe1ade63460b9'
		}
		if (this._LoginService.loginUser.account_detail['enable_profit_on_document_header']) {
			returnVal = 'ffc6f6d2127d41a7b4a3e358c8389912'
		}
		return returnVal;
	}

	public getCommissionContractSettlementReportSection() {
		const commission_settlement_report_setting = [
			{
				name: 'Invoices',
				key: CommissionSettlementReportSectionEnum.INVOICES,
				section_level: 1,
				parent_section_level: 0
			},
			{
				name: 'Customers',
				key: CommissionSettlementReportSectionEnum.CUSTOMERS,
				section_level: 2,
				parent_section_level: 1
			},
			{
				name: 'Items',
				key: CommissionSettlementReportSectionEnum.ITEMS,
				section_level: 3,
				parent_section_level: 2
			},
			{
				name: 'Invoices',
				key: CommissionSettlementReportSectionEnum.INVOICES,
				section_level: 4,
				parent_section_level: 3
			},
			{
				name: 'Orders',
				key: CommissionSettlementReportSectionEnum.ORDERS,
				section_level: 5,
				parent_section_level: 0
			},
			{
				name: 'Change Order(s)',
				key: CommissionSettlementReportSectionEnum.CHANGE_ORDERS,
				section_level: 6,
				parent_section_level: 0
			},
			{
				name: 'Modified Invoice(s)',
				key: CommissionSettlementReportSectionEnum.MODIFIED_INVOICES,
				section_level: 10,
				parent_section_level: 0
			},
			{
				name: 'Additional Incentives',
				key: CommissionSettlementReportSectionEnum.EXTRA_COMMISSION_BASED_RULES,
				section_level: 7,
				parent_section_level: 0
			},
			{
				name: 'Amortized Payments',
				key: CommissionSettlementReportSectionEnum.AMORTIZED_PAYMENTS,
				section_level: 8,
				parent_section_level: 0
			},
			{
				name: 'Service Credit Memo',
				key: CommissionSettlementReportSectionEnum.SERVICE_CREDIT_MEMOS,
				section_level: 9,
				parent_section_level: 0
			},
			// // Hide section as of IX-1615 story
			// {
			// 	name: 'Miscellaneous Deduction',
			// 	key: 'miscellaneous_deduction_rules',
			// 	section_level: 10,
			// 	parent_section_level: 0
			// },
		]
		return commission_settlement_report_setting;
	}

	//Load State Data
	public loadStateData(sectionLevelKey, parentSectionLevelKey) {
		const sLeval = this.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		let newSection = null;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING)));
		} catch (e) {
			newSection = null;
		}
		const commissionSettlementReportConfigClone = newSection;
		if (!commissionSettlementReportConfigClone.configs) {
			commissionSettlementReportConfigClone.configs = [];
		}
		const statePersistentIndex = commissionSettlementReportConfigClone.configs.findIndex(sp => sp.section_level === sLeval.section_level && sp.parent_section_level === sLeval.parent_section_level);
		if (statePersistentIndex !== -1) {
			if (commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent) {
				return commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	// Get grid section level
	public getSectionLevel(sectionLevel, parentSectionLevel?) {
		const lstSection = this.getCommissionContractSettlementReportSection();
		let psLevel, sLeval;
		if (!parentSectionLevel) {
			const sectionLeval = lstSection.filter(i => i.key === sectionLevel && i.parent_section_level === 0);
			sLeval = sectionLeval[0];
		} else {
			const sLeval1 = lstSection.filter(i => i.key === sectionLevel && i.parent_section_level !== 0);
			sLeval1.forEach(element => {
				const psLevel1 = lstSection.filter(i => i.key === parentSectionLevel);
				if (psLevel1) {
					psLevel1.forEach(pElement => {
						if (element.parent_section_level === pElement.section_level) {
							sLeval = element;
						}
					});
				}
			});
		}
		// console.log('sLeval ::: ', sLeval);
		return sLeval;
	}

	public getListOfActionsForDatagrid() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'CLOSE_MULTIPLE_SETTLEMENT',
				btn_name: 'Close Multiple settlement',
				btn_image: 'close_multiple_settlement',
				icon: IMG_ICON_CLOSE_MULTIPLE_SETTLEMENT,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForAgrementTemplateDatagrid() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForCommissionReport() {
		return [
			{
				btn_code: 'CLOSE_CURRENT_SETTLEMENT_PERIOD',
				btn_name: 'Close Current Settlement Period',
				btn_image: 'close_current_settlement_period',
				icon: IMG_ICON_CLOSE_MULTIPLE_SETTLEMENT,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'UPDATE_CURRENT_SETTLEMENT',
				btn_name: 'Update Current Settlement',
				btn_image: 'update_current_settlement',
				icon: IMG_ICON_UPDATE_CURRENT_SETTLEMENT,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT_PDF',
				btn_name: 'Export Pdf',
				btn_image: 'pdffile',
				icon: IMG_ICON_EXPORT_PDF,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export Data',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
		];
	}

	get getListOfSettlementPeriods() {
		return [
			{ text: 'Monthly', value: 'monthly', disabled: false, visible: true },
			{ text: 'Quarterly', value: 'quarterly', disabled: false, visible: true },
			{ text: 'Bi-annually', value: 'halfYearly', disabled: false, visible: true },
			{ text: 'Annually', value: 'yearly', disabled: false, visible: true },
			{ text: 'Weekly', value: 'weekly', disabled: false, visible: true },
			{ text: 'Bi-weekly', value: 'biweekly', disabled: false, visible: true },
			{ text: 'Custom', value: 'custom', disabled: false, visible: true },
		];
	}

	get getListOfCalculationBasis() {
		return [
			{ text: 'Gross Profit', value: 'grossprofit', disabled: false, visible: true },
			{ text: 'Gross Sales', value: 'revenue', disabled: false, visible: true },
		];
	}

	get getListOfCustomerOrItemMatch() {
		return [
			{ text: 'Exclusive', value: 'exclusive', disabled: false, visible: true },
			{ text: 'Inclusive', value: 'inclusive', disabled: false, visible: true },
		];
	}
	get getListOfTiers() {
		return [
			{ text: 'Single Item', value: 'single_items', disabled: false, visible: true },
			{ text: 'Multiple Item', value: 'multiple_items', disabled: false, visible: true },
		];
	}

	get getListOfCalculateCommissionBy() {
		return [
			{ value: 'orders', text: 'Orders', disabled: false, visible: true },
			{ value: 'invoices', text: 'Invoices', disabled: false, visible: true }
		];
	}

	get getListOfTierModes() {
		return [
			{ text: 'Absolute', value: 'absolute', disabled: false, visible: true },
			{ text: 'Marginal', value: 'marginal', disabled: false, visible: true },
		];
	}

	get getListOfTierAccrualPeriods() {
		return [
			{ text: 'Weekly', value: 'weekly', disabled: false, visible: false },
			{ text: 'Monthly', value: 'monthly', disabled: false, visible: true },
			{ text: 'Quarterly', value: 'quarterly', disabled: false, visible: true },
			{ text: 'Bi-annually', value: 'halfYearly', disabled: false, visible: true },
			{ text: 'Annually', value: 'yearly', disabled: false, visible: true },
		];
	}

	get getListOfTierInclude() {
		return [
			{ text: 'Only items in tier', value: 'only_items_in_tier', disabled: false, visible: true },
			{ text: 'All items in contract', value: 'all_items_in_contract', disabled: false, visible: true }
		];
	}

	get getListOfYears() {
		const year = new Date().getFullYear();
		const range = [];
		range.push(year);
		for (let i = 1; i < 5; i++) {
			range.push(year - i);
		}
		return range;
	}
}
