import { DashboardTaskListComponent } from './dashboard/dashboard-task-list/dashboard-task-list.component';
import { NgModule } from '@angular/core';
import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPrintModule } from 'ngx-print';
import { MainPipesModule } from '@app/pipes/main-pipes.module';
import { DxChartModule, DxButtonModule, DxSelectBoxModule, DxDataGridModule, DxDropDownBoxModule, DxLoadPanelModule, DxCheckBoxModule, DxDateBoxModule, DxPopoverModule, DxRadioGroupModule, DxListModule, DxCircularGaugeModule, DxVectorMapModule, DxDropDownButtonModule, DxPopupModule, DxTooltipModule, DxLoadIndicatorModule } from 'devextreme-angular';
import { OpenTaskTilesComponent } from './dashboard/open-task-tiles/open-task-tiles.component';
import { OpenLeadTilesComponent } from './dashboard/open-lead-tiles/open-lead-tiles.component';
import { OpenProspectTilesComponent } from './dashboard/open-prospect-tiles/open-prospect-tiles.component';
import { SalesOrderTotalTilesComponent } from './dashboard/sales-order-total-tiles/sales-order-total-tiles.component';
import { SalesInvoiceTotalTilesComponent } from './dashboard/sales-invoice-total-tiles/sales-invoice-total-tiles.component';
import { SalesQuotesTotalTilesComponent } from './dashboard/sales-quotes-total-tiles/sales-quotes-total-tiles.component';
import { CommssionTotalTilesComponent } from './dashboard/commssion-total-tiles/commssion-total-tiles.component';
import { AgreementListComponent } from './dashboard/agreement-list/agreement-list.component';
import { QuarterlyReportComponent } from './dashboard/quarterly-report/quarterly-report.component';
import { DrillDownBarChartComponent } from './dashboard/drill-down-bar-chart/drill-down-bar-chart.component';
import { ActiveCustomerTilesComponent } from './dashboard/active-customer-tiles/active-customer-tiles.component';
import { RebateForecastBarChartComponent } from './dashboard/rebate-forecast-bar-chart/rebate-forecast-bar-chart.component';
import { RebateTotalTilesComponent } from './dashboard/rebate-total-tiles/rebate-total-tiles.component';
import { RebateRevenueBarChartComponent } from './dashboard/rebate-revenue-bar-chart/rebate-revenue-bar-chart.component';
import { CustomComponentsModule } from '@app/custom-components/custom-components.module';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { SalesArCreditmemoTotalTilesComponent } from './dashboard/sales-ar-creditmemo-total-tiles/sales-ar-creditmemo-total-tiles.component';
import { SalesGoalChartComponent } from './dashboard/sales-goal-chart/sales-goal-chart.component';
import { SalesPerformanceChartComponent } from './dashboard/sales-performance-chart/sales-performance-chart.component';
import { SalesLeaderBoardChartComponent } from './dashboard/sales-leader-board-chart/sales-leader-board-chart.component';
import { NotificationsModule } from './notifications/notifications.module';
import { RoyaltyTotalTilesComponent } from './dashboard/royalty-total-tiles/royalty-total-tiles.component';
import { CustomCurrencyPipe, CustomFormatCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { FilterListFromListWithMultiPropertiesPipe } from '@app/pipes/filter-from-list.pipe';
import { CustomDateFormatPipe } from '@app/pipes/custom-date-format/custom-date-format.pipe';

@NgModule({
	declarations: [
		PagesComponent,
		DashboardComponent,
		HeaderComponent,
		SidemenuComponent,
		ActiveCustomerTilesComponent,
		OpenTaskTilesComponent,
		OpenLeadTilesComponent,
		OpenProspectTilesComponent,
		SalesOrderTotalTilesComponent,
		SalesInvoiceTotalTilesComponent,
		SalesQuotesTotalTilesComponent,
		CommssionTotalTilesComponent,
		AgreementListComponent,
		DashboardTaskListComponent,
		QuarterlyReportComponent,
		DrillDownBarChartComponent,
		RebateForecastBarChartComponent,
		RebateTotalTilesComponent,
		RoyaltyTotalTilesComponent,
		RebateRevenueBarChartComponent,
		SalesArCreditmemoTotalTilesComponent,
		SalesGoalChartComponent,
		SalesPerformanceChartComponent,
		SalesLeaderBoardChartComponent,
	],
	entryComponents: [],
	imports: [
		FormsModule,
		ReactiveFormsModule,
		Ng2SearchPipeModule,
		CommonModule,
		PagesRoutingModule,
		A11yModule,
		MatProgressSpinnerModule,
		PortalModule,
		ScrollingModule,
		NgxPrintModule,
		NgxPermissionsModule,
		MainPipesModule,
		DxChartModule,
		DxSelectBoxModule,
		DxButtonModule,
		DxDropDownBoxModule,
		DxDataGridModule,
		DxLoadPanelModule,
		DxCheckBoxModule,
		DxDateBoxModule,
		DxPopoverModule,
		DxRadioGroupModule,
		DxTooltipModule,
		CustomComponentsModule,
		NgDynamicBreadcrumbModule,
		DxListModule,
		DxCircularGaugeModule,
		DxVectorMapModule,
		DxDropDownButtonModule,
		NotificationsModule,
		DxPopupModule,
		DxLoadIndicatorModule
	],
	providers: [
		DecimalPipe,
		CurrencyPipe,
		CustomCurrencyPipe,
		CustomFormatCurrencyPipe,
		CustomDateFormatPipe,
		FilterListFromListWithMultiPropertiesPipe
	]
})
export class PagesModule { }
