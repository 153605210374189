import { Injectable } from '@angular/core';
import { AppCommonSrvc } from '../app-common-srvc/app-common-srvc.service';
import { environment } from "@environments/environment";
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';


interface Scripts {
	name: string;
	src: string;
}

export const ScriptStore: Scripts[] = [
	{ name: 'accept', src: environment.ACCEPT_JS_URL },
	{ name: 'jquery', src: 'jquery-min.js' },
	{ name: 'functions', src: 'functions.js' },
	{ name: 'datatables', src: 'datatables.min.js' },
	{ name: 'general', src: 'general.js' },
	// { name: 'highcharts', src: 'highcharts.js' },
	// { name: 'crm-highchart', src: 'crm-highcharts.js' },
	// { name: 'dashboard-highchart', src: 'dashboard-highcharts.js' },
	{ name: 'pay', src: 'pay.js' },
	{ name: 'card_knox', src: environment.CARD_NOX_JS_URL },
];

declare var document: any;

@Injectable({
	providedIn: 'root'
})
export class DynamicScriptLoaderService {

	private scripts: any = {};

	constructor(private _AppCommonSrvc: AppCommonSrvc, private _DomSanitizer: DomSanitizer) {
		ScriptStore.forEach((script: any) => {
			let srcUrl;
			if (script.name === 'accept') {
				srcUrl = script.src;
			} else if (script.name === 'card_knox') {
				srcUrl = script.src;
			} else {
				srcUrl = (environment.production) ? this._AppCommonSrvc.cdnJsPath + script.src : '.' + this._AppCommonSrvc.pathJs + script.src;
			}
			this.scripts[script.name] = {
				loaded: false,
				src: srcUrl
			};
		});
	}

	load(...scripts: string[]) {
		const promises: any[] = [];
		scripts.forEach((script) => promises.push(this.loadScript(script)));
		return Promise.all(promises);
	}

	loadScript(name: string) {
		return new Promise((resolve, reject) => {
			if (!this.scripts[name].loaded) {
				// load script
				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = this.scripts[name].src;
				if (script.readyState) {  // IE
					script.onreadystatechange = () => {
						if (script.readyState === 'loaded' || script.readyState === 'complete') {
							script.onreadystatechange = null;
							this.scripts[name].loaded = true;
							resolve({ script: name, loaded: true, status: 'Loaded' });
						}
					};
				} else {  // Others
					script.onload = () => {
						this.scripts[name].loaded = true;
						resolve({ script: name, loaded: true, status: 'Loaded' });
					};
				}
				script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
				document.getElementsByTagName('head')[0].appendChild(script);
			} else {
				resolve({ script: name, loaded: true, status: 'Already Loaded' });
			}
		});
	}
}
