import { PAGE_SIZE_OPTIONS, PAGE_SIZE } from '@app/constants-enums/constants';
export class TabsData {
	public tabId?: number;
	public tabCode?: any;
	public tabName?: string;
	public detailName?: string;
	public isActive?: boolean;
	public isHidden?: boolean;
	public isDisabled?: boolean;
	constructor() { }
}

export class Contacts {
	public leads?: any;
	public prospects?: any;
	public clients?: any;
	public vendors?: any;
	public buyingGroups?: any;
}

export class PageOptions {
	totalLength: number;
	pageSize: number;
	pageSizeOptions: number[];
	pageIndex?: number;
	constructor() {
		this.totalLength = 0;
		this.pageSize = PAGE_SIZE;
		this.pageSizeOptions = PAGE_SIZE_OPTIONS;
		this.pageIndex = 0;
	}
}

export class OptionBtns {
	public btnCode?: any;
	public btnName?: any;
	public isActive?: any;
	public btnImage?: any;
	public icon?: any;
	public visible?: boolean;
	constructor() { }
}

export class CommonOptions {
	public id?: any;
	public name?: any;
	public code?: any;
}

export class TimelinesInsertUpdate {
	public guid?: any;
	public datetime?: string;
	public title?: string;
	public actiontype?: string;
	public actionid?: any;
	public details?: string;
	constructor() {

	}
}

export class TimelinesList {
	public guid?: any;
	public datetime?: string;
	public title?: string;
	public actiontype?: string;
	public actionid?: any;
	public details?: string;
}

export class ConfirmPopupData {
	public popupTitle?: any;
	public popupMessage?: any;
	public popupOkBtnText?: string;
	public popupCancelBtnText?: string;
	public popupExtraDatas?: any;
	public popupInfoBoxClass?: string;
	public popupIconClass?: string;
}

export class AppsConfigsUI {
	erpType: string;
	crmType: string;
	erpHostUrl?: string;
	erpUserName?: string;
	erpUserDb?: string;
	erpUserPassword?: string;
	twofaPassPhrase?: string;
	fiscalYearStartDate?: any;
	fiscalYearStart?: number;
	erpDataStartdate?: any;
	chk_unpaid_invoices?: boolean;
	chk_rebates?: boolean;
	chk_billbacks_chargebacks?: boolean;
	chk_cash_discounts?: boolean;
	chk_shipping_costs?: boolean;
	includeInCommissionCal?: string[];
	transactionUpdateFrequency?: number;
	masterDataUpdateFrequency?: number;
	inactiveCustomerMonths?: number;
	previousMonthsOrders?: number;
	servertimezone?: string;
	company_gl_account_number?: string;
	service_item_code?: string;
	order_url?: string;
	document_template_docusign_rebate?: string;
	product_search: any[] = [];
	qbonline_estimate_linkto?: string;
	map_order_due_date?: string;
	include_paidinvoice_in_settlementperiod?: string;
	commission_settlement_output?: string;
	order_cutoff: OrderCutOff;
	contract_lifecycle_management?: string;
	netsuite_client_app_id?: string;
	salesforce_app_id?: string;
	docusignAccountId?: string;
	is_use_territory_for_commission_contract?: boolean;
	territory_by_address?: string;
	decimal_point?: number;
	decimal_point_currency?: number;
	docusignAccountEnvelopeUrl?: string;
	rebate_price_basis?: string;
	chargeback_price_basis?: string;
	enable_feature?: boolean;
	customer_age_tiers?: boolean;
	docusignEmailSubject?: any;
	enable_invoice_qualification_feature?: boolean;
	enable_invoice_custom_fields_feature?: boolean;
	enable_virtual_invoices?: boolean;
	application_host_url?: string;
	application_api_version?: string;
	application_company?: string;
	application_user_id?: string;
	application_user_password?: string;
	enable_chargeback_look_back_period?: boolean;
	look_back_period?: any;
	claim_back_date_period?: any;
	netsuite_iss?: string;
	netsuite_m2m_id?: string;
	base_price_list_name?: string;
	enable_cash_discount_feature?: boolean;
	epicor_prophet21_consumer_key?: string;
	epicor_prophet21_url?: string;
	is_use_for_commission_contract?: boolean;
	is_use_for_royalty_contract?: boolean;
	sso_mode?: string;
	okta_url?: string;
	okta_client_id?: string;
	okta_client_secrete_key?: string;
	use_customers_from?: string; //header | itemlist
	use_buying_groups_as?: string; //vendors | clients
	currency_code?: string;
	currency_language?: string;
	enable_min_max_claim?: boolean;
	enable_pricelist_approval_workflow?: boolean;
	tax_calculation_feature?: boolean;
	enable_rebate_settlement_approval_workflow?: boolean;
	enable_price_list_terms_feature?: boolean;
	enable_sales_rep_for_orders_feature?: boolean;
	vendor_entity_to_consider?: string;
	tax_exempt_code?: string;
	dynamics_client_app_id?: string;
	dynamics_client_environment?: string;
	dynamics_client_company_id?: any[];
	dynamics_client_company?: object;
	enable_incremental_payment?: boolean;
	constructor() { }
}

export class AppsConfigsRequest {
	erp_type: string;
	crm_type: string;
	erp_host_url: string;
	erp_user_name: string;
	erp_user_db: string;
	erp_user_password: string;
	twofa_pass_phrase: string;
	fiscal_year_start: number;
	erp_data_start_date: any;
	include_in_commission_cal: string[];
	transaction_update_frequency: number;
	master_data_update_frequency: number;
	inactive_customer_months: number;
	previous_months_orders: number;
	servertimezone: string;
	company_gl_account_number?: string;
	service_item_code?: string;
	order_url?: string;
	document_template_docusign_rebate?: string;
	product_search?: any[];
	qbonline_estimate_linkto?: string;
	map_order_due_date?: string;
	include_paidinvoice_in_settlementperiod?: string;
	commission_settlement_output?: string;
	order_cutoff: OrderCutOff;
	contract_lifecycle_management?: string;
	netsuite_client_app_id?: string;
	salesforce_app_id?: string;
	docusignAccountId?: string;
	is_use_territory_for_commission_contract?: boolean;
	territory_by_address?: string;
	decimal_point?: number;
	docusignAccountEnvelopeUrl?: string;
	rebate_price_basis?: string;
	chargeback_price_basis?: string;
	customer_age_tiers?: boolean;
	docusignEmailSubject?: any;
	application_host_url?: string;
	application_api_version?: string;
	application_company?: string;
	application_user_id?: string;
	application_user_password?: string;
	netsuite_iss?: string;
	netsuite_m2m_id?: string;
	base_price_list_name?: string;
	epicor_prophet21_consumer_key?: string;
	epicor_prophet21_url?: string;
	use_customers_from?: string;
	use_buying_groups_as?: string;
	decimal_point_currency?: number;
	currency_code?: string;
	currency_language?: string;
	dynamics_client_app_id?: string;
	dynamics_client_environment?: string;
	dynamics_client_company?: object;
	constructor() { }
}

export class GetRecordsReq {
	public usr: string;
	public token: string;
	public entity: string;
	public method: string;
	public itemsperpage?: number;
	public page?: number;
	public is_dropdown?: boolean;
	public is_listview?: boolean;
	public sort_field?: string;
	public sort_dir?: string;
	public search?: any[] = [];
	public view_fields?: string;
	public entity_id?: any;
	public index?: any;
	public for_usr?: string;
	constructor() { }
}

export class FormDataReqParameters {
	public usr: string;
	public for_usr?: string;
	public token: string;
	public entity: string;
	public method: string;
	public itemsperpage?: string;
	public page?: string;
	public is_dropdown?: string;
	public is_listview?: string;
	public sort_field?: string;
	public sort_dir?: string;
	public search?: string;
	public view_fields?: string;
	public entity_id?: string;
	public index?: string;
	constructor() { }
}

export class ResendVerificationEmailReq {
	public method: string;
	public usr: string;
	public token: string;
	public for_usr?: string;
}

export class OrderCutOff {
	public local_timezone?: string;
	public days?: OrderCutOffDays[];
	constructor() {
		this.local_timezone = '';
		this.days = [];
	}
}

export class OrderCutOffDays {
	public guid?: string;
	public index?: number;
	public day?: string;
	public accept_order?: boolean;
	public order_cutoff_time?: any;
	constructor() { }
}

export class ChargebackLookBackPeriodModel {
	enable_chargeback_look_back_period?: boolean;
	look_back_period?: any;
	claim_back_date_period?: any;
	constructor() {
		this.enable_chargeback_look_back_period = false;
		this.look_back_period = '';
		this.claim_back_date_period = '';
	}
}

export class CurrencyOptions {
	style?: string;
	locale?: string;
	currencyCode?: string;
	fractionDigit?: number;
	constructor() { }
};

export class ClaimPreviewModel {
	guid: string;
	ixcode: string;
	chargeback_period: any;
	claimedItems: any[];
	claimedStatus: string;
	contractName?: string;
	contractGuid?: string;
	contracttype?: string;
	created_by_guid?: string;
	created_by_name?: string;
	created_date?: Date;
	modified_date?: Date;
	documentDate: Date;
	cut_off_date?: Date;
	transaction_date?: Date;
	documentTotal: number;
	ignoredItems: any[];
	selectCustomer: string;
	selectCustomerId: string;
	source_claim_no?: string;
	reason_code_label?: string;
	reason_code_erpcode?: string;
	claimtype?: string;
	overrideItems?: any[];
	constructor() { }
}
export class TaxCalculationConfigModel {
	guid?: string;
	taxcode?: string;
	rates?: TaxCalculationRatesConfigModel[] = [];
}

export class TaxCalculationRatesConfigModel {
	guid?: string;
	startdate?: any;
	enddate?: any;
	rate?: any;
}


export class FileUploadIxModel {
	uploadFor: string;
}