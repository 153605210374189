export class SignUpUI {
	public company_name?: string;
	public password?: string;
	public dialcode_phone?: any;
	public mobile?: string;
	public email?: string;
	public firstname?: string;
	public lastname?: string;
	public address1?: string;
	public address2?: string;
	public sel_country_data?: any = {};
	public country_name?: string;
	public sel_state_data?: any = {};
	public state?: string;
	public city?: string;
	public zip?: string;
	public dialcode_contact_number?: any;
	public contact_number?: string;
	public istermsandcondition?: boolean;
	public termsandcondition?: string;
	public name_on_card?: string;
	public card_number?: any;
	public card_type?: string;
	public expire_month_number?: any;
	public expire_year_number?: number;
	public cvv_number?: any;
	public selSubscriptionPlanDetail?: any;
	public license_categories?: any[];
	public setup_package_options?: any[];
	public flat_rate_billing?: any[];
	public order_summary = {
		license_categories_subtotal: 0,
		license_categories_taxtotal: 0,
		license_categories_totalcost: 0,
		setup_package_options_subtotal: 0,
		setup_package_options_taxtotal: 0,
		setup_package_options_totalcost: 0,
		subtotal: 0,
		totaltax: 0,
		totalcost: 0,
	};
	public is_trial?: boolean;
	public isCaptchaSuccess?: boolean;
	constructor() { }
}

export class CalculateTaxRates {
	public to_country: string;
	public to_zip: string;
	public to_state: string;
	public amount: number;
	public shipping: number;
	public line_items: LineItems[];
	constructor() {
		this.to_country = '';
		this.to_zip = '';
		this.to_state = '';
		this.amount = null;
		this.shipping = 0;
		this.line_items = [];
	}
}

export class LineItems {
	public quantity: number;
	public unit_price: number;
	public product_tax_code: string;
	constructor() {
		this.quantity = null;
		this.unit_price = null;
		this.product_tax_code = null;
	}
}

export class SignUpRequest {
	public method?: string;
	public company_name?: string;
	public password?: string;
	public mobile?: string;
	public countrycode?: string;
	public email?: string;
	public isd_code?: string;
	public country_code?: string;
	public firstname?: string;
	public lastname?: string;
	public address1?: string;
	public address2?: string;
	public country_name?: string;
	public state?: string;
	public city?: string;
	public zip?: string;
	public dialcode?: string;
	public contact_number?: string;
	public is_trial?: boolean;
	public termsandcondition?: string; // pending/ verified
	public tac_version?: string; // Terms and Condition Version
	public tier_guid?: string;
	public license_categories?: string;
	public setup_package_options?: string;
	public flat_rate_billings?: string;
	public startdate?: string;
	public no_of_days?: number;
	public rep?: string;
	constructor() {
	}
}

export class UpdateUserTierDetailsUI {
	public anet_profile_id: string;
	public anet_payment_profile_ids: string;
	public anet_cim_response: string;
	public anet_customer_id: string;
	constructor() {

	}
}

export class UpdateUserTierDetailsReq {
	public method: string;
	public for_usr: string;
	public anet_profile_id: string;
	public anet_payment_profile_ids: string;
	public invoices: string;
	public signup: number;
	public anet_cim_response: string;
	public anet_customer_id: string;
	public token?: string;
	public usr?: string;
	public type?: string;
	constructor() {

	}
}

export class ReGenerateInvoiceReq {
	public usr?: string;
	public token?: string;
	public method: string;
	public for_usr: string;
	public refcancelinvoiceno: string;
	public invoice_type: string;
	public licenses: any;
	public anet_response: any;
	constructor() {

	}
}