// All Model for dx-popup property
export class ViewEndusersInvoiceDetailPopUpModel {
	constructor() {
		this.docNo = '';
		this.docType = '';
	}
	docNo: string;
	docType: string;
}
export class ViewInvoiceDetailPopUpModel {
	constructor() {
		this.docNo = '';
		this.docType = '';
	}
	docNo: string;
	docType: string;
}
export class ViewOrderChangeOrdereDetailPopUpModel {
	constructor() {
		this.docNo = '';
		this.docType = '';
	}
	docNo: string;
	docType: string;
}
export class ViewCreditMemoDetailPopUpModel {
	constructor() {
		this.docNo = '';
		this.docType = '';
	}
	docNo: string;
	docType: string;
}