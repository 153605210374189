<div id="wrapper" class="loginScreens" [ngClass]="{'loginScreenno':multiViewIndex === 1 || multiViewIndex === 2}">
	<div class="loginContainer cf" [ngClass]="{'authyCode':multiViewIndex === 1 || multiViewIndex === 2 }">
		<div class="cols12 cf txtCenter">
			<span (click)="doLoginView()" *ngIf="multiViewIndex === 1 || multiViewIndex === 2 || multiViewIndex === 3"
				class="loginBack"></span>
			<a>
				<img [src]="_AppCommonSrvc.cdnImgPath + 'logo.png'" width="222" height="48" alt="incentX" title="incentX"
					class="loginScreenslogo">
			</a>
		</div>

		<div *ngIf="!isShowLoginContainer" class="regTitles connectedTitle">
			<dx-load-indicator height="40" width="40" class="mrgT20"></dx-load-indicator>
			<h4 class="successfullyConnectionTitle mrgT0">Successfully <span class="highlight"> Authenticated</span></h4>
			<p>Logging you into incentX</p>
		</div>

		<div *ngIf="isShowLoginContainer">
			<dx-multi-view #multiview [dataSource]="listOfPages" [selectedIndex]="multiViewIndex" [loop]="false"
				[animationEnabled]="true" [swipeEnabled]="false" [focusStateEnabled]="false">
				<div *dxTemplate="let page of 'item'">
					<div class="multiview-item">
						<div *ngIf="page.code === 'LOGIN'">
							<div class="regTitles mrgT20">
								<h4>Login to the incentX portal</h4>
								<div id="login-message" style="color:red">
									{{errorMessage}}
								</div>
							</div>
							<div class="loginForm cf">
								<div class="formControls">
									<form (submit)="login()" name="formLogin" autocomplete="off">
										<div class="row">
											<div class="cols12 mrgB30">
												<div class="loginIcon">
													<label class="icon-Userlogin"></label>
													<dx-text-box mode="text" [(value)]="LoginData.user"
														[disabled]="isDisabledEmailId" (onInitialized)="doLoginInitialized($event)"
														name="email" id="email" placeholder="Enter email" [showClearButton]="true">
													</dx-text-box>
												</div>
											</div>
											<div class="cols12">
												<div class="loginIcon">
													<label class="icon-passwordlogin"></label>
													<dx-text-box #password mode="password" [(value)]="LoginData.pwd"
														[disabled]="isDisabledPassword" placeholder="Enter password" class=""
														[showClearButton]="true">
													</dx-text-box>
												</div>
											</div>
										</div>
										<div class="row forgotRow">
											<div class="cols6">
												<a class="forgotPass" [attr.href]="signupPageUrl" target="_blank">Sign Up Now</a>
											</div>
											<div class="cols6 txtRight" (click)="doForgotPassword()">
												<a class="forgotPass">Forgot your Password</a>
											</div>
										</div>
										<div class="cf">
											<dx-button class="button dxSubmitBtn payBtn cols12 mrgB10" text="Login Securely"
												type="normal" stylingMode="contained" [useSubmitBehavior]="true">
											</dx-button>
										</div>
										<div class="cf">
											<h3 class="anotherMethod"><span>OR</span></h3>
										</div>
										<div class="cf">
											<dx-button icon="key" (onClick)="doContinueWithSSO()"
												class="secondaryBtn button dxSubmitBtn noPadd cols12 ssoBtn mrgB10"
												text="Continue with SSO" type="normal" stylingMode="contained"
												[useSubmitBehavior]="false">
											</dx-button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div *ngIf="page.code === '2FA_AUTHY'">
							<div class="regTitles">
								<h2 class="loginScreenemail">{{LoginData.user}}</h2>
								<h3>Enter your verification code shown in Authy</h3>
								<h4>
									Input the six digit code shown for <span class="skyblue">incentX</span>
									in the Authy app to verify your identity
								</h4>
								<div id="2fa-message" style="color:red">
									{{errorMessage || ''}}
								</div>
							</div>
							<div class="loginForm cf">
								<div class="formControls">
									<form (submit)="checkTwoFactorAuthenticationClick()" name="formTwoFactorAuth"
										autocomplete="off">
										<div class="row">
											<div class="cols12">
												<div class="cf verificationCode noBdr">
													<!-- <dx-text-box #twoFactorTextbox mode="text" [(value)]="twoFactorAuthenticationCode"
													(onInitialized)="do2FAInitialized($event)"
													(onValueChanged)="doChange2FAToken($event)" valueChangeEvent="keyup"
													[showClearButton]="false" [maxLength]="6" placeholder=""
													name="twoFactorAuthenticationCode" class="mrgB10 verificationCode">
												</dx-text-box> -->
													<input type="text" [(ngModel)]="twoFactorAuthenticationCode"
														name="twoFactorAuthenticationCode" class="mrgB10"
														(keyup)="doKeyUpVerificationCode($event)"
														(paste)="doOnPasteVerificationCode($event)" maxlength="6" placeholder=""
														[disabled]="isDisabledTwoFactorCodeTxt" numbersOnly autoFocus
														pattern="[0-9]*">
												</div>
											</div>
										</div>
										<div class="cf mrgB20">
											<dx-button class="button dxSubmitBtn payBtn cols12" text="Verify" type="normal"
												stylingMode="contained" [useSubmitBehavior]="true">
											</dx-button>
										</div>
									</form>
									<div class="cf mrgB20 mrgT20">
										<div class="alignLeft">Never used Authy before?</div>
										<div class="alignRight">
											<a href="https://authy.com/download/" target="_blank" class="downAppy">
												<span class="authyIcon">
													<span class="icon-logos_authy"></span>
												</span>
												<span class="underLine">Download the app</span>
											</a>
										</div>
									</div>
									<div class="cf">
										<h3 class="anotherMethod"><span>Choose another method</span></h3>
									</div>
									<div class="row">
										<div class="methodIcons">
											<a (click)="doClickMethodIcon('smscode')">
												<span class="icon-sms"></span>
												Use SMS
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="page.code === '2FA_SMS'">
							<div class="regTitles">
								<h2 class="loginScreenemail">{{LoginData.user}}</h2>
								<h3>Enter your verification code</h3>
								<h4>
									Input the code we sent to
									<span class="skyblue">{{mobileNumber || ''}}</span>
									to access your account.
								</h4>
								<div id="2fa-message" style="color:red">
									{{errorMessage || ''}}
								</div>
							</div>
							<div class="loginForm cf">
								<div class="formControls">
									<form (submit)="checkTwoFactorAuthenticationClick()" name="formSmsAuth" autocomplete="off">
										<div class="row">
											<div class="cols12">
												<div class="cf verificationCode noBdr">
													<!-- <dx-text-box mode="text" #twoFactorTextbox [(value)]="twoFactorAuthenticationCode"
													(onInitialized)="do2FAInitialized($event)"
													(onValueChanged)="doChange2FAToken($event)" valueChangeEvent="keyup"
													[showClearButton]="false" [maxLength]="6" placeholder=""
													name="twoFactorAuthenticationCode" class="mrgB10 verificationCode">
													` </dx-text-box> -->
													<input type="text" [(ngModel)]="twoFactorAuthenticationCode"
														[disabled]="isCallVerifyOTP" name="twoFactorAuthenticationCode" class="mrgB10"
														(keyup)="doKeyUpVerificationCode($event)"
														(paste)="doOnPasteVerificationCode($event)" maxlength="6" placeholder=""
														numbersOnly autoFocus pattern="[0-9]*">
												</div>
												<span class="alignRight">
													<a (click)="doClickResendPIN()" class="rsndPing">Resend PIN</a>
												</span>
											</div>
										</div>
										<div class="cf mrgB20">
											<dx-button class="button dxSubmitBtn payBtn cols12" text="Verify" type="normal"
												stylingMode="contained" [useSubmitBehavior]="true">
											</dx-button>
										</div>
									</form>
									<div class="cf">
										<h3 class="anotherMethod"><span>Choose another method</span></h3>
									</div>
									<div class="row">
										<div class="methodIcons">
											<a (click)="doClickMethodIcon('authycode')">
												<span class="authyAppIconRed"></span>
												Authy App
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="page.code === 'LOGIN_WITH_SSO'">
							<div class="regTitles mrgT20">
								<h4>Login with SSO to the incentX portal</h4>
								<div id="login-message" style="color:red">
									{{errorMessage}}
								</div>
							</div>
							<div class="loginForm cf">
								<div class="formControls">
									<form (submit)="doLoginWithSSO()" name="formLoginWithSSO" autocomplete="off">
										<div class="row">
											<div class="cols12 mrgB30">
												<div class="loginIcon">
													<label class="icon-Userlogin"></label>
													<dx-text-box mode="text" [(value)]="LoginData.user"
														[disabled]="isDisabledEmailId" name="email" id="email"
														(onInitialized)="doLoginInitialized($event)" placeholder="Enter email"
														[showClearButton]="true">
													</dx-text-box>
												</div>
											</div>
										</div>
										<div class="cf">
											<dx-button class="button dxSubmitBtn payBtn cols12 mrgB10 mrgT10"
												text="Continue with SSO" type="normal" stylingMode="contained"
												[useSubmitBehavior]="true">
											</dx-button>
										</div>
										<div class="row">
											<div class="methodIcons mrgT10">
												<a (click)="doCancelLoginWithSSO()">
													Cancel
												</a>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</dx-multi-view>
		</div>
	</div>
</div>

<app-forgot-password *ngIf="isShowForgotPasswordPopup"
	(closeForgotPasswordPopup)="doCloseForgotPasswordPopup($event)"></app-forgot-password>