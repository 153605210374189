import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { DataService } from '@app/services/data.service';
import { Component, OnInit, AfterViewInit, Inject, ViewChild, OnDestroy, Input, HostListener, EventEmitter, Output } from '@angular/core';
import { environment } from "@environments/environment";
import { LoginService } from '@app/services/login.service';
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
import { DxDataGridComponent } from 'devextreme-angular';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalPopoupHeight90Ratio, GlobalPopoupWidth90Ratio, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { MinMaxEndUsersChargebackContractService } from '@app/services/contract-service/billback/min-max-end-users-chargeback-contract.service';
import { Guid } from '@app/models/guid';
import { MessageTrallComponent } from '@app/custom-components/message-trall/message-trall.component';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { ClaimPreviewModel } from '@app/models/common.model';
import { DatePipe } from '@angular/common';
import { MinMaxChargebackStatusValEnum } from '@app/constants-enums/enums';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import * as moment from 'moment';

@Component({
	selector: 'app-view-claim-preview-popup',
	templateUrl: './view-claim-preview-popup.component.html',
	styleUrls: ['./view-claim-preview-popup.component.css']
})
export class ViewClaimPreviewPopupComponent implements OnInit, OnDestroy, AfterViewInit {
	public succeedClaimItemsDataSource: any;
	public ignoredClaimItemsDataSource: any;
	// @ViewChild("claimPreviewDataGridContainer", {}) claimPreviewDataGridContainer: DxDataGridComponent;
	@ViewChild("succeedClaimItemsRef", {}) succeedClaimItemsRef: DxDataGridComponent;
	@ViewChild("ignoredClaimItemsRef", {}) ignoredClaimItemsRef: DxDataGridComponent;
	@ViewChild(MessageTrallComponent) childMessageTrallComponent: MessageTrallComponent;
	public titleText = 'Chargeback Claim';
	public isVisiblePopup: boolean = false;
	public popupWidth: number;
	public popupHeight: number;
	public currentTab: string;
	public isShowDatagrid: boolean = false;
	public filterTextForSucceed: string = '';
	private filterForSucceedValueChangeSbsn: Observable<any>;
	private isDisabledClearForSucceedBtn: boolean = true;
	public filterTextForIgnored: string = '';
	private filterForIgnoredValueChangeSbsn: Observable<any>;
	private isDisabledClearForIgnoredBtn: boolean = true;
	private instanceSucceedClearBtn: any = null;
	private instanceIgnoredClearBtn: any = null;
	private mainSectionStorageKey: string;
	private updateUserSettingSbsn: Subscription;
	private dataOutReqSubscription: Subscription;
	public stateStorageSucceedTabKey: string;
	public stateStorageIgnoredTabKey: string;
	private guid = new Guid();
	public filterValue: any[] = [];
	public minMaxEndUsersChargebackContractModelUI: any;
	public isViewMessageTrail: boolean = false;
	public decimalPointForCurrency: number;
	public claimPreviewDetails: ClaimPreviewModel = new ClaimPreviewModel();
	@Input() claimPreviewProps: any;
	public claimId: string;
	@Output() closeClaimPopup = new EventEmitter<string>();
	public isShowAuditTrailBtn: boolean = true;
	public isOpenAuditTrailDrawer: boolean = false;
	public auditTrailList: any = [];
	public isVisibleConsiderClaimBtn: boolean = false;
	public listOfActions: any[];
	public isShowIsActiveColumn: boolean = true;
	public listOfStatuses: any[] = this._MinMaxEndUsersChargebackContractService.doGetListofClaimApprovalStatus();
	public listOfStatusesBtns: any[] = [];
	public listOfCustomReason: any[] = [];
	public selectedKeyDropDownBtn: any;
	public isShowDropDownButton: boolean;
	public isNotficationApprovalView: boolean = false;
	public callForm: string;
	public approvalTrailDetails: any;
	private sendPriceListForApprovalSbsn: Subscription;
	private getListOfReasonSubscription: Subscription;
	public listOfTaxExemptHeaderFilter: any[] = [
		{ text: 'YES', value: true },
		{ text: 'NO', value: false },
	]
	public isCustomerChargebackbasis: boolean = false;
	public isDisabledConsiderForClaimItem: boolean = false;
	public selectedRowKeysForIgnoreItems: any[] = [];
	public MinMaxChargebackStatusValEnum = MinMaxChargebackStatusValEnum;
	private listOfFeaturesCustomFields: any[] = [];
	private filterValueChangeSbsn: Observable<any>;
	public isShowOtherPurchasePopup: boolean = false;
	public otherPurchaseProps: any;
	public isShowOverrideItemsTab: boolean;
	public approvalClaimDetail: any;
	constructor(private _LoginService: LoginService,
		public _AppCommonSrvc: AppCommonSrvc,
		public _BillbackService: BillbackService,
		public _LoaderService: LoaderService,
		public _RestApiService: RestApiService,
		public _ToastrService: ToastrService,
		public _CustomCurrencyPipe: CustomCurrencyPipe,
		public _DatePipe: DatePipe,
		private _IxDxAlertService: IxDxAlertService,
		public _MinMaxEndUsersChargebackContractService: MinMaxEndUsersChargebackContractService) {
		this.doLoadSucceedItemTab = this.doLoadSucceedItemTab.bind(this);
		this.doSaveSucceedItemTab = this.doSaveSucceedItemTab.bind(this);
		this.doLoadIgnoredItemTab = this.doLoadIgnoredItemTab.bind(this);
		this.doSaveIgnoredItemTab = this.doSaveIgnoredItemTab.bind(this);
	}
	// @HostListener('window:resize', ['$event'])
	// public onResize(event) {
	// 	//this.setDxPopupWidth();
	// }
	ngOnInit() {
		this.callForm = this.claimPreviewProps.callFrom;
		this.listOfActions = this._AppCommonSrvc.getListOfActionsForClaimPreviewDatagrid() || [];
		// get global currency
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.minMaxEndUsersChargebackContractModelUI = this.claimPreviewProps.minMaxEndUsersChargebackContractModelUI;
		// setTimeout(() => {
		// 	this.currentTab = 'succeed_items';
		// }, 100);
		this.currentTab = 'succeed_items';
		this.isViewMessageTrail = true;
		this.mainSectionStorageKey = ServerSections.MIN_MAX_CHARGEBACK_CLAIM_PREVIEW;
		this.stateStorageSucceedTabKey = ServerSections.CLAIM_PREVIEW_SUCCEED_CLAIM_TAB;
		this.stateStorageIgnoredTabKey = ServerSections.CLAIM_PREVIEW_IGNORED_CLAIM_TAB;
		if (this.claimPreviewProps.calledFrom == 'min-max-chargeback-Approval-details') {
			this.isNotficationApprovalView = true;
			this.approvalClaimDetail = this.claimPreviewProps.approvalDetail;
		}
		this.getFeaturesCustomFields()
		setTimeout(() => {
			if (this.succeedClaimItemsRef) {
				this.filterForSucceedValueChangeSbsn = this.succeedClaimItemsRef.filterValueChange.asObservable();
				this.filterForSucceedValueChangeSbsn.subscribe((result) => {
					this.getFilterTextSucceedTab();
				});
			}
			if (this.ignoredClaimItemsRef) {
				this.filterForIgnoredValueChangeSbsn = this.ignoredClaimItemsRef.filterValueChange.asObservable();
				this.filterForIgnoredValueChangeSbsn.subscribe((result) => {
					this.getFilterTextIgnoredTab();
				});
			}
		}, 60);
		setTimeout(() => {
			this.callClaimPreview();
		}, 600);
		this.chargebackBasisCondtions();
		this.fetchListOfCustomReason();
	}

	ngOnDestroy(): void {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.sendPriceListForApprovalSbsn) {
			this.sendPriceListForApprovalSbsn.unsubscribe();
		}
		if (this.getListOfReasonSubscription) {
			this.getListOfReasonSubscription.unsubscribe();
		}
	}

	ngAfterViewInit() {
		// this.currentTab = 'succeed_items';
		// setTimeout(() => {
		// 	this.setDxPopupWidth();
		// }, 500);
	}

	chargebackBasisCondtions() {
		this.isCustomerChargebackbasis = false;
		const chargebackBasis = this.minMaxEndUsersChargebackContractModelUI.chargebackBasis || 'endusers';
		const customerBasis = this._MinMaxEndUsersChargebackContractService.listOfCharegbackBasis[1].value || 'customers'
		if (chargebackBasis && chargebackBasis == customerBasis) {
			this.isCustomerChargebackbasis = true;
		}
	}
	//#region Succeed Claim Item Tab

	public doActionsBtnClickForSucceed(event: any) {
		const addIndex = this.listOfActions.findIndex(i => i.btn_code
			=== 'ADD');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].disabled = !this.isShowIsActiveColumn;
		}
	}
	public doActionsBtnItemClickForSucceed(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshSucceedItemDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcelForSucceedTab();
					break;
				case 'COLUMN_CHOOSER':
					this.succeedClaimItemsRef.instance.showColumnChooser();
					break;
			}
		}
	}
	// Method used to Display Toolbar for Export Data, Filter Data
	public doSucceedItemsToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateSucceedFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearForSucceedBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceSucceedClearBtn = args.component;
					},
					onClick: () => {
						this.succeedClaimItemsRef.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenSucceedFilterBuilder();
					}
				}
			},
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcelForSucceedTab();
			// 		}
			// 	}
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'refresh',
			// 		text: "Refresh",
			// 		onClick: (event: any) => {
			// 			this.refreshSucceedItemDataGrid();
			// 		}
			// 	}
			// }
			{
				location: 'after',
				template: 'templateSucceedActionBtns',
				visible: true,
			},
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.visible = false;
			columnChooserButton.showText = "always";
		}
	}
	// Method used to Open Filter Build Popup manually
	public doOpenSucceedFilterBuilder() {
		this.succeedClaimItemsRef.instance.option("filterBuilderPopup.visible", true);
	}
	public doRefreshSucceedItemDataGrid() {
		// this.claimPreviewDataGridContainer.instance.refresh();
		this.callClaimPreview();
	}
	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcelForSucceedTab() {
		const context = this;
		if (context.succeedClaimItemsRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let claimedItemWorksheet;
		let fileName = context.claimPreviewDetails.selectCustomer + '_ChargebackDocument_successful.xlsx';
		claimedItemWorksheet = workbook.addWorksheet('Claimed Item(s)');

		exportDataGrid({
			worksheet: claimedItemWorksheet,
			component: context.succeedClaimItemsRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 5, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'selectedClaimedUom' || gridCell.column.dataField === 'contract_uom') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value.toString().toUpperCase();
						}
					}
					if (gridCell.column.dataField === 'is_active') {
						excelCell.value = '';
						let colorName = '';
						excelCell.value = gridCell.value ? 'Active' : 'Inactive';
						colorName = gridCell.value ? '05c1a0' : 'f99300';
						// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
						excelCell.font = { color: { argb: colorName } };
					}
				}
			}
		}).then((cellRange) => {
			// header
			this.doSetHeaderBlockOnExportExcel(claimedItemWorksheet, context);
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});

	}
	// Method used to Get Same Text from Filter Panel
	private getFilterTextSucceedTab() {
		this.filterTextForSucceed = '';
		this.isDisabledClearForSucceedBtn = true;
		if (this.succeedClaimItemsRef) {
			this.succeedClaimItemsRef.filterPanel.customizeText = (e: any) => {
				this.filterTextForSucceed = e.text;
				if (this.filterTextForSucceed) {
					this.isDisabledClearForSucceedBtn = false;
				} else {
					this.isDisabledClearForSucceedBtn = true;
				}
			};
		}
		setTimeout(() => {
			this.updateStatesClearBtnForSucceed();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtnForSucceed() {
		if (this.instanceSucceedClearBtn !== null) {
			setTimeout(() => {
				this.instanceSucceedClearBtn.option({ disabled: this.isDisabledClearForSucceedBtn });
			}, 10);
		}
	}

	//#endregion Succeed Claim Tab

	// #region for Succeed Claim Tab Datagrid State Storing functionality

	public doLoadSucceedItemTab() {
		this.filterValueChangeSbsn = this.succeedClaimItemsRef.filterValueChange.asObservable();
		this.filterValueChangeSbsn.subscribe((result) => {
			this.getFilterTextSucceedTab();
		});
		return this.loadDatagridState(this.stateStorageSucceedTabKey, this.mainSectionStorageKey);
	}
	public doSaveSucceedItemTab(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageSucceedTabKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}
	// #endregion

	//#region Ignored Claim Item Tab
	// Method used to Display Toolbar for Export Data, Filter Data
	public doIgnoredItemsToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateIgnoredFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearForIgnoredBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceIgnoredClearBtn = args.component;
					},
					onClick: () => {
						this.ignoredClaimItemsRef.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenIgnoredFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				template: 'templateIgnoredActionBtns',
				visible: true,
			},
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcelForIgnoredTab();
			// 		}
			// 	}
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'refresh',
			// 		text: "Refresh",
			// 		onClick: (event: any) => {
			// 			this.refreshIgnoredItemDataGrid();
			// 		}
			// 	}
			//}
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.visible = false;
			columnChooserButton.showText = "always";
		}
	}
	public doActionsBtnClickForIgnored(event: any) {
		const addIndex = this.listOfActions.findIndex(i => i.btn_code
			=== 'ADD');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].disabled = !this.isShowIsActiveColumn;
		}
	}
	public doActionsBtnItemClickForIgnored(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshIgnoredItemDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcelForIgnoredTab();
					break;
				case 'COLUMN_CHOOSER':
					this.ignoredClaimItemsRef.instance.showColumnChooser();
					break;
			}
		}
	}
	// Method used to Open Filter Build Popup manually
	public doOpenIgnoredFilterBuilder() {
		this.ignoredClaimItemsRef.instance.option("filterBuilderPopup.visible", true);
	}
	public doRefreshIgnoredItemDataGrid() {
		// this.claimPreviewDataGridContainer.instance.refresh();
		this.callClaimPreview();
	}
	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcelForIgnoredTab() {
		const context = this;
		if (context.ignoredClaimItemsRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let ignoredItemWorksheet;
		let fileName = context.claimPreviewDetails.selectCustomer + '_ChargebackDocument_ignored.xlsx';
		ignoredItemWorksheet = workbook.addWorksheet('Ignored Item(s)');
		// let ignoredItemsWorkBook: any;
		// if (context.ignoreClaimItemsRef && context.ignoreClaimItemsRef.instance && context.ignoreClaimItemsRef.instance.totalCount() > 0) {
		// 	ignoredItemsWorkBook = workbook.addWorksheet('Ignored Item(s)');
		// }
		exportDataGrid({
			worksheet: ignoredItemWorksheet,
			component: context.ignoredClaimItemsRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 8, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'selectedClaimedUom' || gridCell.column.dataField === 'contract_uom') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value.toString().toUpperCase();
						}
					}
					if (gridCell.column.dataField === 'is_active') {
						excelCell.value = '';
						let colorName = '';
						excelCell.value = gridCell.value ? 'Active' : 'Inactive';
						colorName = gridCell.value ? '05c1a0' : 'f99300';
						// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
						excelCell.font = { color: { argb: colorName } };
					}
				}
			}
		}).then((cellRange) => {
			this.doSetHeaderBlockOnExportExcel(ignoredItemWorksheet, context);
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});

	}
	// Method used to Get Same Text from Filter Panel
	private getFilterTextIgnoredTab() {
		this.filterTextForIgnored = '';
		this.isDisabledClearForIgnoredBtn = true;
		if (this.ignoredClaimItemsRef) {
			this.ignoredClaimItemsRef.filterPanel.customizeText = (e: any) => {
				this.filterTextForIgnored = e.text;
				if (this.filterTextForIgnored) {
					this.isDisabledClearForIgnoredBtn = false;
				} else {
					this.isDisabledClearForIgnoredBtn = true;
				}
			};
		}
		setTimeout(() => {
			this.updateStatesClearBtnForIgnoredTab();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtnForIgnoredTab() {
		if (this.instanceIgnoredClearBtn !== null) {
			setTimeout(() => {
				this.instanceIgnoredClearBtn.option({ disabled: this.isDisabledClearForIgnoredBtn });
			}, 10);
		}
	}

	public doSelectionChangedIgnoredGrd(e) {
		if (e.selectedRowKeys.length > 0) {
			let isReasonExists = false;
			e.selectedRowsData.forEach(row => {
				isReasonExists = false;
				if (row && !row.hasOwnProperty('reason_value') || (row.reason_value && !row.reason_value.trim())) {
					isReasonExists = true;
				}
			});
			if (isReasonExists) {
				this._ToastrService.info('Reason is required', 'Info', { closeButton: true, tapToDismiss: true });
				setTimeout(() => {
					e.component.deselectRows(e.selectedRowKeys[e.selectedRowKeys.length - 1]);
				}, 200);
			}
			this.isVisibleConsiderClaimBtn = true;
		} else {
			this.isVisibleConsiderClaimBtn = false;
		}
	}

	public doEditorPreparingIgnoredGrd(e) {
		if (e.parentType == 'dataRow' && e.dataField === 'reason_value') {
			const rowIndex = e.row.rowIndex;
			const dataGridInstance = this.ignoredClaimItemsRef.instance;
			e.editorOptions.dataSource = this.listOfCustomReason;
			e.editorOptions.onValueChanged = (event: any) => {
				dataGridInstance.cellValue(rowIndex, 'reason_value', event.value);
				const selectedCustomReason = this.listOfCustomReason.find(customReason => customReason.value === event.value);
				if (selectedCustomReason && selectedCustomReason.guid) {
					dataGridInstance.cellValue(rowIndex, 'reason_description', selectedCustomReason.reason_description || '');
				}
				dataGridInstance.columnOption('reason_description', 'allowEditing', false);
				if (event.value === 'other') {
					dataGridInstance.columnOption('reason_description', 'allowEditing', true);
				}
			};
		}
	}

	//#endregion Ignored Claim Tab

	// #region for Ignored Claim Tab Datagrid State Storing functionality

	public doLoadIgnoredItemTab() {
		setTimeout(() => {
			this.filterValueChangeSbsn = this.ignoredClaimItemsRef.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterTextIgnoredTab();
			});
		}, 60);
		return this.loadDatagridState(this.stateStorageIgnoredTabKey, this.mainSectionStorageKey);
	}

	public doSaveIgnoredItemTab(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageIgnoredTabKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}
	// #endregion

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}

	private setDxPopupWidth() {
		const innerWidth = window.innerWidth;
		const innerHeight = window.innerHeight;
		this.popupWidth = (innerWidth * GlobalPopoupWidth90Ratio) / 100;
		this.popupHeight = (innerHeight * GlobalPopoupHeight90Ratio) / 100;
	}

	public doChangeTab(newTab: string) {
		this.approvalTrailDetails = this.claimPreviewDetails;
		this.currentTab = newTab;
		// this.succeedClaimItemsDataSource = [];
		// this.ignoredClaimItemsDataSource = [];
		// switch (newTab) {
		// 	case 'succeed_items':
		// 		this.succeedClaimItemsDataSource = JSON.parse(JSON.stringify(this.claimPreviewDetails.claimedItems || []));
		// 		break;
		// 	case 'ignored_items':
		// 		const ignoredItem = [
		// 			{
		// 				"end_user_id": "37ae5ffcfad0b3aef22eef1e114a6960",
		// 				"end_user_code": "AVICENNA",
		// 				"end_user_name": "AVICENNA",
		// 				"pricelistno": "P123",
		// 				"pricelistname": "New EU Pricelist",
		// 				"erp_primary_key": "100000000",
		// 				"itemsku": "",
		// 				"itemname": "100000000",
		// 				"description": "SIRDUPLA MDI 125\/25MCG 120DSE",
		// 				"selectedUom": "Each",
		// 				"quantity": 5,
		// 				"listPrice": 15,
		// 				"final_amount": 10,
		// 				"chargebackAmount": 5,
		// 				"claimed_inv": null,
		// 				"linetotal": 25
		// 			},
		// 			{
		// 				"end_user_id": "37ae5ffcfad0b3aef22eef1e114a6961",
		// 				"end_user_code": "AVICENNA -1",
		// 				"end_user_name": "AVICENNA -1",
		// 				"pricelistno": "P123-1",
		// 				"pricelistname": "New EU Pricelist-1",
		// 				"erp_primary_key": "100000000",
		// 				"itemsku": "",
		// 				"itemname": "100000000",
		// 				"description": "SIRDUPLA MDI 125\/25MCG 120DSE",
		// 				"selectedUom": "Each",
		// 				"quantity": 10,
		// 				"listPrice": null,
		// 				"final_amount": null,
		// 				"chargebackAmount": null,
		// 				"claimed_inv": null,
		// 				"linetotal": 10
		// 			},
		// 			{
		// 				"end_user_id": "37ae5ffcfad0b3aef22eef1e114a4885",
		// 				"end_user_code": "AVICENNA-2",
		// 				"end_user_name": "AVICENNA-2",
		// 				"pricelistno": "P123-2",
		// 				"pricelistname": "New EU Pricelist-2",
		// 				"erp_primary_key": "100000000",
		// 				"itemsku": "",
		// 				"itemname": "100000000",
		// 				"description": "SIRDUPLA MDI 125\/25MCG 120DSE",
		// 				"selectedUom": "Each",
		// 				"quantity": 15,
		// 				"listPrice": null,
		// 				"final_amount": null,
		// 				"chargebackAmount": null,
		// 				"claimed_inv": null,
		// 				"linetotal": 15
		// 			},
		// 		]
		// 		if (this.claimPreviewDetails.ignoredItems.length === 0) {
		// 			this.claimPreviewDetails.ignoredItems = ignoredItem || [];
		// 		}
		// 		this.ignoredClaimItemsDataSource = JSON.parse(JSON.stringify(this.claimPreviewDetails.ignoredItems || []));
		// 		break;
		// 	default:
		// 		break;
		//}
	}

	public callClaimPreview() {
		this._LoaderService.show();
		this.claimPreviewDetails = new ClaimPreviewModel();
		const chargeback_period = this.claimPreviewProps?.chargeback_period || '';
		const cut_off_date = this.claimPreviewProps?.cut_off_date || '';
		const transaction_date = this.claimPreviewProps?.transaction_date || '';
		// const chargeback_period_start_date = this.claimPreviewProps?.chargeback_period_start_date || '';
		// const chargeback_period_end_date = this.claimPreviewProps?.chargeback_period_end_date || '';
		const selectedEndUserList = this.claimPreviewProps?.selectedEndUserList || [];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('contractguid', this.minMaxEndUsersChargebackContractModelUI.guid);
		formData.append('method', ServerMethods.MIN_MAX_CLAIM_PREVIEW);
		if (this.callForm === 'view' && this.claimPreviewProps.hasOwnProperty('claimguid')) {
			formData.append('claimguid', this.claimPreviewProps?.claimguid);
		}
		if (this.claimPreviewProps.hasOwnProperty('reasonCodeGuid')) {
			formData.append('reason_code_guid', this.claimPreviewProps?.reasonCodeGuid);
		}
		if (selectedEndUserList.length > 0) {
			const enduserCodeList = [];
			selectedEndUserList.forEach(element => {
				enduserCodeList.push(element.code);
			});
			formData.append('endusers', JSON.stringify(enduserCodeList));
		}
		// if (chargeback_period) {
		// 	const chargeback_period_date = moment(chargeback_period).format('YYYY-MM-DD') || '';
		// 	formData.append('chargeback_period', chargeback_period_date);
		// }
		if (cut_off_date) {
			formData.append('cut_off_date', cut_off_date);
		}
		if (transaction_date) {
			formData.append('transaction_date', transaction_date);
		}
		// if (chargeback_period_start_date) {
		// 	formData.append('chargeback_period_start_date', chargeback_period_start_date);
		// }
		// if (chargeback_period_end_date) {
		// 	formData.append('chargeback_period_end_date', chargeback_period_end_date);
		// }

		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				if (response.flag && response.data) {
					this.claimPreviewDetails = response.data;
					this.succeedClaimItemsDataSource = JSON.parse(JSON.stringify(this.claimPreviewDetails.claimedItems || []));
					this.ignoredClaimItemsDataSource = JSON.parse(JSON.stringify(this.claimPreviewDetails.ignoredItems || []));
					this.checkIsOverrrideCalim();
				} else {
					this.claimPreviewDetails = new ClaimPreviewModel();
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				}
				// this.currentTab = 'succeed_items';
				this._LoaderService.hide();
				this.setSubmitMultiPleBtns();
				this.isVisiblePopup = true;
			}, error: (error) => {
				console.error('error', error);
				this.claimPreviewDetails = new ClaimPreviewModel();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				// this.currentTab = 'succeed_items';
				this._LoaderService.hide();
			}
		});
	}
	public checkIsOverrrideCalim() {
		this.isShowOverrideItemsTab = false;
		if (this.approvalClaimDetail && this.approvalClaimDetail.hasOwnProperty('overrideItems')) {
			if (this.approvalClaimDetail.overrideItems && this.approvalClaimDetail.overrideItems.length > 0) {
				this.isShowOverrideItemsTab = true;
				this.succeedClaimItemsDataSource = this.approvalClaimDetail.overrideItems || [];
				this.doChangeTab('override_items');
			} else {
				this.doChangeTab('succeed_items');
			}
		}
	}

	public doGenerateClaim() {
		this.closeClaimPopup.emit();
	}

	public doClickViewClaim(item) {
		this.childMessageTrallComponent.doClickViewClaim(item);
	}

	private doSetHeaderBlockOnExportExcel(worksheet, context) {
		const headerRow = worksheet.getRow(2);
		headerRow.height = 30;
		if (context.claimPreviewDetails.contractName) {
			worksheet.mergeCells(2, 1, 2, 12);
		}
		headerRow.getCell(1).value = context.claimPreviewDetails.contractName;
		headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 14, 'bold': true };
		headerRow.getCell(1).alignment = { horizontal: 'center', vertical: 'center' };

		const docRefNumRow = worksheet.getRow(2);
		docRefNumRow.height = 30;
		docRefNumRow.getCell(1).value = 'Chargeback Duration';
		docRefNumRow.getCell(1).alignment = { horizontal: 'left' };
		docRefNumRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		let monthFilterValue = context.claimPreviewDetails.chargeback_period;
		docRefNumRow.getCell(2).value = this._DatePipe.transform(monthFilterValue, 'MMMM, yyyy');;
		docRefNumRow.getCell(2).alignment = { horizontal: 'left' };
		docRefNumRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		if (context.claimPreviewDetails.documentDate) {
			docRefNumRow.getCell(4).value = 'Document Date';
			docRefNumRow.getCell(4).font = { name: 'Segoe UI Light', size: 12, bold: false };
			docRefNumRow.getCell(5).value = context.claimPreviewDetails.documentDate || '';
			docRefNumRow.getCell(5).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}

		const selectCustomerRow = worksheet.getRow(3);
		selectCustomerRow.height = 30;
		selectCustomerRow.getCell(1).value = 'Customer Code';
		selectCustomerRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		let selectCustomerRowValue = context.claimPreviewDetails.selectCustomerId || '';
		selectCustomerRow.getCell(2).value = selectCustomerRowValue;
		selectCustomerRow.getCell(2).alignment = { horizontal: 'left' };
		selectCustomerRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		if (context.claimPreviewDetails.selectCustomer) {
			selectCustomerRow.getCell(4).value = 'Customer Name';
			selectCustomerRow.getCell(4).font = { name: 'Segoe UI Light', size: 12, bold: false };
			selectCustomerRow.getCell(5).value = context.claimPreviewDetails.selectCustomer || '';
			selectCustomerRow.getCell(5).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}
		let sourceClaimNoRowValue = context.claimPreviewDetails.claimUse || '';
		if (sourceClaimNoRowValue) {
			const sourceClaimNoRow = worksheet.getRow(4);
			sourceClaimNoRow.height = 30;
			sourceClaimNoRow.getCell(1).value = 'Source Claim No.';
			sourceClaimNoRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
			sourceClaimNoRow.getCell(2).value = sourceClaimNoRowValue;
			sourceClaimNoRow.getCell(2).alignment = { horizontal: 'left' };
			sourceClaimNoRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}

		let claimedUserNoteRowValue = context.claimPreviewDetails.claimedUserNote || '';
		if (claimedUserNoteRowValue) {
			const sourceClaimNoRow = worksheet.getRow(5);
			sourceClaimNoRow.height = 30;
			sourceClaimNoRow.getCell(1).value = 'User Note.';
			sourceClaimNoRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
			sourceClaimNoRow.getCell(2).value = claimedUserNoteRowValue;
			sourceClaimNoRow.getCell(2).alignment = { horizontal: 'left' };
			sourceClaimNoRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}
	}

	public doHiddenPopup() {
		this.isVisiblePopup = false;
		this.closeClaimPopup.emit();
	}

	public doOpenAuditTrailDrawer() {
		this.isOpenAuditTrailDrawer = true;
		setTimeout(() => {
			this.isShowAuditTrailBtn = false;
		}, 200);
	}

	public doFilterDateOnValueChanged(e) {
		// const previousValue = e.previousValue;
		// const newValue = e.value;
		if (e && e.value) {
			this.claimPreviewDetails.chargeback_period = e.value;
			//this.callClaimPreview();
		}
	}

	public doCloseAuditTrailtDrawer() {
		this.isOpenAuditTrailDrawer = false;
		this.isShowAuditTrailBtn = false;
		setTimeout(() => {
			this.isShowAuditTrailBtn = true;
		}, 200);
	}

	public doClickConsiderForClaimItem(e) {
		if (this.ignoredClaimItemsRef) {
			const selectedIgnoredRows = this.ignoredClaimItemsRef.instance.getSelectedRowsData();
			if (selectedIgnoredRows.length > 0) {
				const listOfClaimedItems = [];
				selectedIgnoredRows.forEach(row => {
					const claimObj: any = {
						pricelistno: row.pricelistno,
						pricelistname: row.pricelistname,
						erp_primary_key: row.erp_primary_key,
						itemsku: row.itemsku,
						itemname: row.itemname,
						description: row.description,
						reason_value: row.reason_value || '',
						reason_description: row.reason_description || ''
					};
					if (!this.isCustomerChargebackbasis) {
						claimObj.end_user_id = row.end_user_id;
						claimObj.end_user_code = row.end_user_code;
						claimObj.end_user_name = row.end_user_name;
					}
					listOfClaimedItems.push(claimObj)
				});
				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('method', ServerMethods.OVERRIDE_ITEMS_FOR_CLAIM);
				formData.append('claimid', this.claimPreviewDetails.guid);
				formData.append('overrideItems', JSON.stringify(listOfClaimedItems));
				this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
					next: (response) => {
						if (response.flag) {
							this.doRefreshSucceedItemDataGrid();
						} else {
							this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
						}
						this._LoaderService.hide();
					}, error: (error) => {
						console.error('error', error);
						this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
						this._LoaderService.hide();
					}
				});
				/*
				const ignoredItems = JSON.parse(JSON.stringify(this.claimPreviewDetails.ignoredItems || []));
				const claimedItems = JSON.parse(JSON.stringify(this.claimPreviewDetails.claimedItems || []));
				if (ignoredItems.length > 0) {
					selectedIgnoredRows.forEach(element => {
						if (element) {
							const indexIgnoredItem = (ignoredItems.length > 0) ? ignoredItems.findIndex(i => (i.end_user_id === element.end_user_id) && (i.pricelistno === element.pricelistno) && (i.erp_primary_key === element.erp_primary_key)) : -1;
							// const indexClaimedItems = (claimedItems.length > 0) ? claimedItems.findIndex(i => (i.end_user_id === element.end_user_id) && (i.pricelistno === element.pricelistno) && (i.erp_primary_key === element.erp_primary_key)
							// 	&& (i.selectedUom === element.selectedUom) && (i.listPrice === element.listPrice) && (i.final_amount === element.final_amount) && (i.chargebackAmount === element.chargebackAmount)) : -1;
							// if (indexClaimedItems !== -1) {
							// 	const claimedQty = claimedItems[indexClaimedItems].quantity;
							// 	const ignoredQty = ignoredItems[indexIgnoredItem].quantity;
							// 	const claimedLinetotal = claimedItems[indexClaimedItems].linetotal;
							// 	const ignoredLinetotal = ignoredItems[indexIgnoredItem].linetotal;
							// 	claimedItems[indexClaimedItems].quantity = (claimedQty + ignoredQty);
							// 	claimedItems[indexClaimedItems].linetotal = (claimedLinetotal + ignoredLinetotal);
							// } else {
							let addClaimItemsObj = JSON.parse(JSON.stringify(ignoredItems[indexIgnoredItem]));
							addClaimItemsObj['is_ignored_item'] = true;
							claimedItems.push(ignoredItems[indexIgnoredItem]);
							// }
							if (indexIgnoredItem !== -1) {
								ignoredItems.splice(indexIgnoredItem, 1);
							}
						}
					});

					this.claimPreviewDetails.claimedItems = JSON.parse(JSON.stringify(claimedItems));
					this.claimPreviewDetails.ignoredItems = JSON.parse(JSON.stringify(ignoredItems));
					this.succeedClaimItemsDataSource = JSON.parse(JSON.stringify(claimedItems || []));
					this.ignoredClaimItemsDataSource = JSON.parse(JSON.stringify(ignoredItems || []));
				}
				*/

			}
		}
	}

	public callGenerateClaim() {
		this._LoaderService.show();
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('contractguid', this.minMaxEndUsersChargebackContractModelUI.guid);
		formData.append('method', ServerMethods.MIN_MAX_CLAIM_PREVIEW);
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				if (response.flag && response.data) {
					this.claimPreviewDetails = response.data || [];
					this.doChangeTab(this.currentTab);
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error: (error) => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	public getClassNameForStatusField(status) {
		const gotStatusIndex = this.listOfStatuses.findIndex(s => s.value === status);
		let dynamicClass = 'dynamic_21b3c6';
		if (gotStatusIndex !== -1) {
			const statusObj = JSON.parse(JSON.stringify(this.listOfStatuses[gotStatusIndex]));
			if (statusObj.bgcolor) {
				dynamicClass = 'dynamic_' + statusObj.bgcolor.toString().replace('#', '');
			}
		}
		return dynamicClass;
	}

	public getListOfApproveStatus(data) {
		this.listOfStatuses.forEach(action => {
			// action.label = action.label.toUpperCase();
			action.label = action.label;
		});
		this.listOfStatuses.forEach(action => {
			if (action.value === data.claimedStatus) {
				action.visible = false;
			} else {
				action.visible = true;
			}
		});
	}

	private setSubmitMultiPleBtns() {
		const priceListStatus = this.claimPreviewDetails.claimedStatus || null;
		this.listOfStatusesBtns = [];
		if (priceListStatus === MinMaxChargebackStatusValEnum.DRAFT) {
			const signatureReqStatusIndex = this.listOfStatuses.findIndex(rs => rs.value === MinMaxChargebackStatusValEnum.PENDING_APPROVAL);
			const signatureReqClone = JSON.parse(JSON.stringify(this.listOfStatuses[signatureReqStatusIndex]));
			signatureReqClone.label = 'Submit For Approval';
			this.listOfStatusesBtns.push(signatureReqClone);
		} else if (priceListStatus === MinMaxChargebackStatusValEnum.PENDING_APPROVAL) {
			const currentStatusIndex = this.listOfStatuses.findIndex(rs => rs.value === MinMaxChargebackStatusValEnum.APPROVED);
			const currentStatusClone = JSON.parse(JSON.stringify(this.listOfStatuses[currentStatusIndex]));
			currentStatusClone.label = 'Approve';
			this.listOfStatusesBtns.push(currentStatusClone);
			const draftStatusIndex = this.listOfStatuses.findIndex(rs => rs.value === MinMaxChargebackStatusValEnum.DRAFT);
			const draftClone = JSON.parse(JSON.stringify(this.listOfStatuses[draftStatusIndex]));
			draftClone.label = 'Set To Draft';
			this.listOfStatusesBtns.push(draftClone);
		} else if (priceListStatus === MinMaxChargebackStatusValEnum.APPROVED) {
			const draftStatusIndex = this.listOfStatuses.findIndex(rs => rs.value === MinMaxChargebackStatusValEnum.DRAFT);
			const draftClone = JSON.parse(JSON.stringify(this.listOfStatuses[draftStatusIndex]));
			draftClone.label = 'Set To Draft';
			this.listOfStatusesBtns.push(draftClone);
		} else {
			const currentStatusIndex = this.listOfStatuses.findIndex(rs => rs.value === MinMaxChargebackStatusValEnum.DRAFT);
			const draftStatusClone = JSON.parse(JSON.stringify(this.listOfStatuses[currentStatusIndex]));
			draftStatusClone.label = 'Submit';
			this.listOfStatusesBtns.push(draftStatusClone);
			const signatureReqStatusIndex = this.listOfStatuses.findIndex(rs => rs.value === MinMaxChargebackStatusValEnum.PENDING_APPROVAL);
			const signatureReqClone = JSON.parse(JSON.stringify(this.listOfStatuses[signatureReqStatusIndex]));
			signatureReqClone.label = 'Submit For Approval';
			this.listOfStatusesBtns.push(signatureReqClone);
		}
		this.selectedKeyDropDownBtn = this.listOfStatusesBtns[0].value;
		this.isHideShowDropDownButton();
	}
	private isHideShowDropDownButton() {
		this.isShowDropDownButton = false;
		if (this.claimPreviewDetails.claimedStatus === MinMaxChargebackStatusValEnum.DRAFT) {
			this.isShowDropDownButton = true;
		}
		if (this.claimPreviewDetails.claimedStatus === MinMaxChargebackStatusValEnum.PENDING_APPROVAL) {
			this.isShowDropDownButton = false;
		}
		if (this.claimPreviewDetails.claimedStatus === MinMaxChargebackStatusValEnum.APPROVED) {
			this.isShowDropDownButton = false;
		}
		if (this.claimPreviewDetails.claimtype === 'manual_adjustment') {
			this.isShowDropDownButton = false;
		}
	}

	public getSubmitBtnText() {
		return this.listOfStatusesBtns ? this.listOfStatusesBtns[0].text : '';
	}

	public doSaveUpdateClaimList(e) {
		const status = e.selectedItem.value;
		this.callSubmitClaim(status);
	}

	public doSaveUpdateClaimStatusChanged(e) {
		const status = e.itemData.value;
		this.callSubmitClaim(status);
	}

	public callSubmitClaim(status) {
		if (status === MinMaxChargebackStatusValEnum.DRAFT) {
			this.doSubmitForDraft();
		}
		if (status === MinMaxChargebackStatusValEnum.PENDING_APPROVAL) {
			this.doSubmitForPendingApproval();
		}
		if (status === MinMaxChargebackStatusValEnum.APPROVED) {
			this.doSubmitForApproved();
		}
	}

	public doSubmitForDraft() {
	}
	public doSubmitForPendingApproval() {
		if (this.claimPreviewDetails.guid) {
			const claimGuidLst = [];
			let transactionDate = this.claimPreviewDetails.transaction_date ? this.claimPreviewDetails.transaction_date : moment(new Date()).format('YYYY-MM-DD');
			claimGuidLst.push(this.claimPreviewDetails.guid);
			const ixCustomDialogOptions: any = {
				popupIconsHtml: '<div class="sendIcon"><div class="infoIcon mrgT0"></div></div>',
				title: '',
				showTitle: false,
				subtitle: 'Submit For Approval',
				message: 'Are you sure you want to submit for Approval ?<br/><b>This transaction will be created with posting date: ' + transactionDate + '</b><br/>You will not be able to undo the operation.'
			};
			this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
				if (result) {
					const formData = new FormData();
					formData.append('usr', this._LoginService.loginUser.user);
					formData.append('token', this._LoginService.loginUser.token);
					formData.append('method', ServerMethods.SEND_MULTI_CLAIM_TO_APPROVAL);
					formData.append('chargebackBasis', this.minMaxEndUsersChargebackContractModelUI.chargebackBasis);
					formData.append('claimids', JSON.stringify(claimGuidLst));
					if (this.sendPriceListForApprovalSbsn) {
						this.sendPriceListForApprovalSbsn.unsubscribe();
					}
					this._LoaderService.show();
					this.sendPriceListForApprovalSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
						next: response => {
							this._LoaderService.hide();
							if (response && response.flag) {
								this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
							} else {
								this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
							}
							this.isVisiblePopup = false;
							this.closeClaimPopup.emit();
						}, error: error => {
							this._LoaderService.hide();
							this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
						}
					});
				}
			});
		}
	}

	public doSubmitForApproved() {

	}

	private fetchListOfCustomReason() {
		let dynamic_groups = [];
		let dynamic_properties = [];
		try {
			dynamic_groups = this._LoginService.loginUser.account_detail.dynamic_groups || [];
			dynamic_properties = this._LoginService.loginUser.account_detail.dynamic_properties || [];
		} catch (error) {
			dynamic_groups = [];
			dynamic_properties = [];
		}
		let dgIndex = -1;
		dgIndex = dynamic_groups.findIndex(dg => dg.group_value.toString().toLowerCase() === 'overridereason');
		if (dgIndex !== -1) {
			const dgData = dynamic_groups[dgIndex];
			const dpIndex = dynamic_properties.findIndex(dp => dp.group_guid === dgData.guid && dp.property.toString().toLowerCase() === 'reasons');
			if (dpIndex !== -1) {
				const dpData = dynamic_properties[dpIndex];
				const reqFormData = new FormData();
				reqFormData.append('usr', this._LoginService.loginUser.user);
				reqFormData.append('token', this._LoginService.loginUser.token);
				reqFormData.append('method', ServerMethods.GET_ENTITY_DATA);
				reqFormData.append('entity', ServerEntity.DYNAMIC_PROPERTY_OPTIONS);
				reqFormData.append('search', JSON.stringify([{ property_guid: dpData.guid }]));
				reqFormData.append('sort_dir', 'asc');
				reqFormData.append('sort_field', 'sequence');
				reqFormData.append('is_dropdown', 'true');
				reqFormData.append('view_fields', JSON.stringify(['guid', 'property_guid', 'value', 'label', 'sequence', 'reason_description']));
				if (this.getListOfReasonSubscription) {
					this.getListOfReasonSubscription.unsubscribe();
				}
				this.listOfCustomReason = [];
				this._LoaderService.show();
				this.getListOfReasonSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
					next: response => {
						this._LoaderService.hide();
						if (response && response.flag) {
							this.listOfCustomReason = response.data || [];
						}
					}, error: error => {
						this._LoaderService.hide();
					}
				});
			}
		}
	}

	public getFeaturesCustomFields() {
		const listOfMasterFeaturesCustomFields = this._AppCommonSrvc.getFeatureCustomFields();
		this.listOfFeaturesCustomFields = listOfMasterFeaturesCustomFields.filter(field => field.external_entity_field === true && field.entity_type === 'items' && field.section === "HEADER");
		// const listOfCustomViewFieds: any = [];
		// this.listOfFeaturesCustomFields.forEach(field => {
		// 	if (field.display) {
		// 		listOfCustomViewFieds.push(field.custom_field_id);
		// 	}
		// });
	}

	public doInitializedSucceedClaimItemDataGrid(event) {
		setTimeout(() => {
			this.succeedClaimItemsRef.instance.state(null);
		}, 300);

		setTimeout(() => {
			const tableColLen = event && event.component ? event.component.getVisibleColumns().length : 0;
			const customFieldDataList = [];
			if (this.listOfFeaturesCustomFields && this.listOfFeaturesCustomFields.length > 0) {
				for (let i = 0; i < this.listOfFeaturesCustomFields.length; i++) {
					const field = this.listOfFeaturesCustomFields[i];
					let customFieldDataObj = {
						dataField: field.ix_custom_field,
						caption: field.custom_field_title,
						dataType: field.custom_field_output_format,
						allowHeaderFiltering: false,
						visible: field.display,
						showInColumnChooser: field.display,
						allowEditing: false
						// visibleIndex: tableColLen + i,
					};
					customFieldDataList.push(customFieldDataObj);
				}
				const listOfColumns = this.succeedClaimItemsRef.instance.getVisibleColumns();
				customFieldDataList.forEach(element => {
					const columnIndex = listOfColumns.findIndex(col => col.dataField === element.dataField);
					if (columnIndex === -1) {
						// console.log('Add');
						this.succeedClaimItemsRef.instance.addColumn(element);
					}
				});
			}
		}, 1000);
	}

	public doInitializedIgnoredClaimItemDataGrid(event) {
		setTimeout(() => {
			this.ignoredClaimItemsRef.instance.state(null);
		}, 300);

		setTimeout(() => {
			const tableColLen = event && event.component ? event.component.getVisibleColumns().length : 0;
			const customFieldDataList = [];
			if (this.listOfFeaturesCustomFields && this.listOfFeaturesCustomFields.length > 0) {
				for (let i = 0; i < this.listOfFeaturesCustomFields.length; i++) {
					const field = this.listOfFeaturesCustomFields[i];
					let customFieldDataObj = {
						dataField: field.ix_custom_field,
						caption: field.custom_field_title,
						dataType: field.custom_field_output_format,
						allowHeaderFiltering: false,
						visible: field.display,
						showInColumnChooser: field.display,
						allowEditing: false
						// visibleIndex: tableColLen + i,
					};
					customFieldDataList.push(customFieldDataObj);
				}
				const listOfColumns = this.ignoredClaimItemsRef.instance.getVisibleColumns();
				customFieldDataList.forEach(element => {
					const columnIndex = listOfColumns.findIndex(col => col.dataField === element.dataField);
					if (columnIndex === -1) {
						// console.log('Add');
						this.ignoredClaimItemsRef.instance.addColumn(element);
					}
				});
			}
		}, 1000);
	}

	public ignoredClaimItemGridCustomizeColumns = (columns, DataType) => {
		if (columns && columns.length > 0) {
			const indexCol = columns.findIndex(i => i.caption === 'Action');
			if (indexCol !== -1) {
				columns[indexCol].visibleIndex = columns.length; // action column set on last index in grid-data
			}
		}
	}

	public doSucceedItemsCellClick(element) {
		this.showQtyPopup(element);
	}
	public doIgnoredItemsCellClick(element) {
		this.showQtyPopup(element);
	}
	public showQtyPopup(element) {
		if (element.column.dataField !== 'total_bought' && element.column.dataField !== 'other_bought') {
			return;
		}

		let listOfAvailableQuantity: any[] = [];
		if (this.claimPreviewDetails.contractGuid) {
			const claimedItems = element.row.hasOwnProperty('data') ? element.row.data : null;
			const mode = element.column.dataField;
			this.getEndUserItemsQtyModeLst(claimedItems, mode).subscribe({
				next: (response) => {
					if (response.flag) {
						listOfAvailableQuantity = this.genrateSrNoCol(response.data) || [];
						this.otherPurchaseProps = {
							dxDataField: element.column.dataField,
							dxItemName: claimedItems.itemname,
							dxItemDescription: claimedItems.description,
							dxCustomerName: this.claimPreviewDetails.selectCustomer,
							listOfAvailableQuantity: listOfAvailableQuantity
						}
						this.isShowOtherPurchasePopup = false;
						setTimeout(() => {
							this.isShowOtherPurchasePopup = true;
						}, 20);
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					}
					this._LoaderService.hide();
				}, error: (error) => {
					this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					this._LoaderService.hide();
				}
			});
		}
	}

	getEndUserItemsQtyModeLst(claimedItems, mode) {
		if (claimedItems) {
			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('method', ServerMethods.GET_ENTITY_DATA);
			formData.append('mode', mode);
			formData.append('entity', ServerEntity.MINMAXENDUSERSCHARGEBACKCONTRACTS_DETAILS);
			formData.append('contractguid', this.claimPreviewDetails.contractGuid);
			formData.append('end_user_code', claimedItems.end_user_code);
			formData.append('pricelistno', claimedItems.pricelistno);
			formData.append('erp_primary_key', claimedItems.erp_primary_key);
			formData.append('is_dropdown', 'true');
			this._LoaderService.show();
			return this._RestApiService.doDataOutReqFormData((formData));
		}
	}

	public genrateSrNoCol(listOfQty) {
		if (listOfQty && listOfQty.length > 0) {
			let i: number = 0;
			listOfQty.forEach(element => {
				element['sr_no'] = i + 1;
				i++;
			});
			// console.log('listOfQty ::', listOfQty);
			return listOfQty;
		}
	}
}

