import { NgxFileDropModule } from 'ngx-file-drop';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ResharedModule } from '../../../reshared/reshared.module';
import { AuthGuardService } from '@app/services/auth-guard.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomComponentsModule } from '@app/custom-components/custom-components.module';
import { MainPipesModule } from 'app/pipes/main-pipes.module';
import { DxSelectBoxModule, DxListModule, DxDataGridModule, DxButtonModule, DxCheckBoxModule, DxDropDownBoxModule, DxHtmlEditorModule, DxDropDownButtonModule, DxTextBoxModule, DxNumberBoxModule, DxRadioGroupModule, DxLookupModule, DxLoadPanelModule, DxTagBoxModule, DxDateBoxModule, DxPopupModule, DxScrollViewModule, DxTextAreaModule, DxTooltipModule, DxBoxModule, DxDateRangeBoxModule, DxSwitchModule } from 'devextreme-angular';
import { BillbackRoutingModule } from './billback-routing.module';
import { BillbackContractComponent } from './billback-contract/billback-contract.component';
import { ChargebackContractComponent } from './chargeback-contract/chargeback-contract.component';
import { DistributionChargebackContractComponent } from './distribution-chargeback-contract/distribution-chargeback-contract.component';
import { BcItemsTabComponent } from './billback-contract/bc-items-tab/bc-items-tab.component';
import { BcBillbackDocumentsTabComponent } from './billback-contract/bc-billback-documents-tab/bc-billback-documents-tab.component';
import { CcChargebackDocumentsTabComponent } from './chargeback-contract/cc-chargeback-documents-tab/cc-chargeback-documents-tab.component';
import { CcClaimedItemsListDialogComponent } from './chargeback-contract/cc-claimed-items-list-dialog/cc-claimed-items-list-dialog.component';
import { CcViewChargebackDocumentsDialogComponent } from './chargeback-contract/cc-view-chargeback-documents-dialog/cc-view-chargeback-documents-dialog.component';
import { DccChargebackTypesTabComponent } from './distribution-chargeback-contract/dcc-chargeback-types-tab/dcc-chargeback-types-tab.component';
import { BillbackComponent } from './billback.component';
import { EndUsersChargebackContractComponent } from './end-users-chargeback-contract/end-users-chargeback-contract.component';
import { EuccChargebackDocumentsTabComponent } from './end-users-chargeback-contract/eucc-chargeback-documents-tab/eucc-chargeback-documents-tab.component';
import { EuccItemsTabComponent } from './end-users-chargeback-contract/eucc-items-tab/eucc-items-tab.component';
import { CcUploadItemsDialogComponent } from './chargeback-contract/cc-upload-items-dialog/cc-upload-items-dialog.component';
import { EuccUploadItemsDialogComponent } from './end-users-chargeback-contract/eucc-upload-items-dialog/eucc-upload-items-dialog.component';
import { EuccClaimedItemsListDialogComponent } from './end-users-chargeback-contract/eucc-claimed-items-list-dialog/eucc-claimed-items-list-dialog.component';
import { BcViewBillbackDocumentsDialogComponent } from './billback-contract/bc-view-billback-documents-dialog/bc-view-billback-documents-dialog.component';
import { BcUploadItemsDialogComponent } from './billback-contract/bc-upload-items-dialog/bc-upload-items-dialog.component';
import { BcBilledItemsListDialogComponent } from './billback-contract/bc-billed-items-list-dialog/bc-billed-items-list-dialog.component';
import { EuccPricelistTabComponent } from './end-users-chargeback-contract/eucc-pricelist-tab/eucc-pricelist-tab.component';
import { EuccPricelistUnmatchedItemsDialogComponent } from './end-users-chargeback-contract/eucc-pricelist-unmatched-items-dialog/eucc-pricelist-unmatched-items-dialog.component';
import { CcPricelistTabComponent } from './chargeback-contract/cc-pricelist-tab/cc-pricelist-tab.component';
import { CcPricelistUnmatchedItemsDialogComponent } from './chargeback-contract/cc-pricelist-unmatched-items-dialog/cc-pricelist-unmatched-items-dialog.component';
import { DccChargebackClaimsTabComponent } from './distribution-chargeback-contract/dcc-chargeback-claims-tab/dcc-chargeback-claims-tab.component';
import { DccClaimAttachedDocumentsComponent } from './distribution-chargeback-contract/dcc-claim-attached-documents/dcc-claim-attached-documents.component';
import { BcUploadItemsAndIgnoredItemsDialogComponent } from './billback-contract/bc-upload-items-and-ignored-items-dialog/bc-upload-items-and-ignored-items-dialog.component';
import { EuccItemClaimDetailsDxPopupComponent } from './end-users-chargeback-contract/eucc-item-claim-details-dx-popup/eucc-item-claim-details-dx-popup.component';
import { MinMaxEndUsersChargebackContractComponent } from './min-max-end-users-chargeback-contract/min-max-end-users-chargeback-contract.component';
import { MmeuccPricelistTabComponent } from './min-max-end-users-chargeback-contract/mmeucc-pricelist-tab/mmeucc-pricelist-tab.component';
import { MmeuccChargebackDocumentsTabComponent } from './min-max-end-users-chargeback-contract/mmeucc-chargeback-documents-tab/mmeucc-chargeback-documents-tab.component';
import { MmeuccItemsTabComponent } from './min-max-end-users-chargeback-contract/mmeucc-items-tab/mmeucc-items-tab.component';
import { MmeuccItemClaimDetailsDxPopupComponent } from './min-max-end-users-chargeback-contract/mmeucc-item-claim-details-dx-popup/mmeucc-item-claim-details-dx-popup.component';
import { MmeuccViewChargebackDocumentsDxPopupComponent } from './min-max-end-users-chargeback-contract/mmeucc-view-chargeback-documents-dx-popup/mmeucc-view-chargeback-documents-dx-popup.component';
import { MmeuccPricelistUnmatchedItemsDialogComponent } from './min-max-end-users-chargeback-contract/mmeucc-pricelist-unmatched-items-dialog/mmeucc-pricelist-unmatched-items-dialog.component';
import { MmeuccUploadItemsDialogComponent } from './min-max-end-users-chargeback-contract/mmeucc-upload-items-dialog/mmeucc-upload-items-dialog.component';
import { MmeuccClaimedItemsListDialogComponent } from './min-max-end-users-chargeback-contract/mmeucc-claimed-items-list-dialog/mmeucc-claimed-items-list-dialog.component';
import { MmeuccCustomersPricelistTabComponent } from './min-max-end-users-chargeback-contract/mmeucc-customers-pricelist-tab/mmeucc-customers-pricelist-tab.component';
import { MmeuccManualAdjustmentPopupComponent } from './min-max-end-users-chargeback-contract/mmeucc-manual-adjustment-popup/mmeucc-manual-adjustment-popup.component';
// import { UploadChargebackEnduserClaimsDxDialogComponent } from '@app/custom-components/upload-chargeback-enduser-claims-dx-dialog/upload-chargeback-enduser-claims-dx-dialog.component';
// import { EuccViewChargebackDocumentsDxPopupComponent } from './end-users-chargeback-contract/eucc-view-chargeback-documents-dx-popup/eucc-view-chargeback-documents-dx-popup.component';

@NgModule({
	declarations: [
		BillbackComponent,
		BillbackContractComponent,
		EndUsersChargebackContractComponent,
		EuccChargebackDocumentsTabComponent,
		EuccItemsTabComponent,
		DistributionChargebackContractComponent,
		BcItemsTabComponent,
		BcBillbackDocumentsTabComponent,
		DccChargebackTypesTabComponent,
		CcClaimedItemsListDialogComponent,
		CcViewChargebackDocumentsDialogComponent,
		CcUploadItemsDialogComponent,
		EuccUploadItemsDialogComponent,
		EuccPricelistUnmatchedItemsDialogComponent,
		EuccClaimedItemsListDialogComponent,
		BcViewBillbackDocumentsDialogComponent,
		BcUploadItemsDialogComponent,
		BcBilledItemsListDialogComponent,
		EuccPricelistTabComponent,
		EuccPricelistUnmatchedItemsDialogComponent,
		CcPricelistUnmatchedItemsDialogComponent,
		DccChargebackClaimsTabComponent,
		DccClaimAttachedDocumentsComponent,
		BcUploadItemsAndIgnoredItemsDialogComponent,
		EuccItemClaimDetailsDxPopupComponent,
		MinMaxEndUsersChargebackContractComponent,
		MmeuccPricelistTabComponent,
		MmeuccChargebackDocumentsTabComponent,
		MmeuccItemsTabComponent,
		MmeuccItemClaimDetailsDxPopupComponent,
		MmeuccViewChargebackDocumentsDxPopupComponent,
		MmeuccPricelistUnmatchedItemsDialogComponent,
		MmeuccUploadItemsDialogComponent,
		MmeuccClaimedItemsListDialogComponent,
		MmeuccCustomersPricelistTabComponent,
		MmeuccManualAdjustmentPopupComponent,
		// UploadChargebackEnduserClaimsDxDialogComponent,
		// EuccViewChargebackDocumentsDxPopupComponent
		// ChargebackContractComponent,
		// CcItemsTabComponent,
		// CcChargebackDocumentsTabComponent,
	],
	imports: [
		CommonModule,
		BillbackRoutingModule,
		ResharedModule,
		FormsModule,
		ReactiveFormsModule,
		MatMenuModule,
		MatIconModule,
		MatDialogModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatInputModule,
		Ng2SearchPipeModule,
		MatProgressSpinnerModule,
		MatSortModule,
		MatTableModule,
		MatPaginatorModule,
		CustomComponentsModule,
		NgxPermissionsModule,
		MatTooltipModule,
		MainPipesModule,
		DxSelectBoxModule,
		DxListModule,
		DxDataGridModule,
		DxButtonModule,
		DxCheckBoxModule,
		DxDropDownBoxModule,
		DxHtmlEditorModule,
		DxTextBoxModule,
		DxTagBoxModule,
		DxDropDownButtonModule,
		DxNumberBoxModule,
		DxLookupModule,
		DxLoadPanelModule,
		NgxFileDropModule,
		DxRadioGroupModule,
		DxDateBoxModule,
		DxPopupModule,
		DxScrollViewModule,
		DxTextAreaModule,
		DxTooltipModule,
		DxBoxModule,
		DxSwitchModule,
		DxDateRangeBoxModule
	],
	entryComponents: [
		CcClaimedItemsListDialogComponent,
		CcViewChargebackDocumentsDialogComponent,
		CcUploadItemsDialogComponent,
		EuccClaimedItemsListDialogComponent,
		EuccPricelistUnmatchedItemsDialogComponent,
		CcPricelistUnmatchedItemsDialogComponent,
		BcViewBillbackDocumentsDialogComponent,
		BcUploadItemsDialogComponent,
		BcBilledItemsListDialogComponent,
		// MmeuccViewChargebackDocumentsDxPopupComponent
	],
	providers: [AuthGuardService],
	exports: [
		EuccChargebackDocumentsTabComponent,
		MmeuccViewChargebackDocumentsDxPopupComponent,
		// UploadChargebackEnduserClaimsDxDialogComponent,
		// EuccViewChargebackDocumentsDxPopupComponent
	]
})
export class BillbackModule { }
