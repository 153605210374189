import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PageOptions } from '@app/models/common.model';
import { MinMaxEndUsersChargebackContractService } from '@app/services/contract-service/billback/min-max-end-users-chargeback-contract.service';
import { LoginService } from '@app/services/login.service';
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { DatePipe } from '@angular/common';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import * as moment from 'moment';
import { MinMaxEndUsersChargebackContractModelUI } from '@app/models/min-max-end-users-chargeback-contract.model';
import * as _ from 'underscore';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { environment } from '@environments/environment';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { ContractTypeValEnum, MinMaxChargebackStatusValEnum } from '@app/constants-enums/enums';
import { ManualAdjustmentClaimDetailModel, ManualAdjustmentClaimModel } from '@app/models/billback-contract.model';
import { Guid } from '@app/models/guid';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
@Component({
	selector: 'app-mmeucc-manual-adjustment-popup',
	templateUrl: './mmeucc-manual-adjustment-popup.component.html',
	styleUrls: ['./mmeucc-manual-adjustment-popup.component.css']
})
export class MmeuccManualAdjustmentPopupComponent implements OnInit {
	@Input() claimPreviewProps: any;
	@Output() closeClaimManualAdjustmentPopup = new EventEmitter<string>();
	public isVisiblePopup: boolean = true;
	public MinMaxChargebackStatusValEnum = MinMaxChargebackStatusValEnum;
	public titleText: string = 'Manual Adjustment';
	public listOfStatuses: any[] = this._MinMaxEndUsersChargebackContractService.doGetListofClaimApprovalStatus();
	public listOfStatusesBtns: any[] = [];
	public listOfCustomReason: any[] = [];
	public listOfCustomOverrideReason: any[] = [];
	public manualAdjustmentClaim: ManualAdjustmentClaimModel = new ManualAdjustmentClaimModel();
	public minMaxEndUsersChargebackContractModelUI: MinMaxEndUsersChargebackContractModelUI = new MinMaxEndUsersChargebackContractModelUI();
	public isReasonCodeDropDownBoxOpened: boolean = false;
	public reasonCodeGridBoxValue: any;
	public endUserListOfPrices: any;
	private dataOutReqSubscription: Subscription;
	private getListOfReasonSubscription: Subscription;
	private saveManualAdjustmentSbsn: Subscription;
	private addManualAdjustmenTtemsSbsn: Subscription;
	private sendApprovalSbsn: Subscription;
	private getPriceLstSbsn: Subscription;
	private getEndUSerDetailsSbsn: Subscription;
	private updateUserSettingSbsn: Subscription;
	public currentTab: string = 'endusers_items';
	public pageOptions: PageOptions = new PageOptions();
	private filterValueChangeSbsn: Observable<any>;
	public reasonCodeGridBoxColumns = [
		{ dataField: 'guid', caption: 'Guid', visible: false },
		{ dataField: 'value', caption: 'Value', visible: false },
		{ dataField: 'type', caption: 'Type', visible: false },
		{ dataField: 'erpcode', caption: 'ERP Code' },
		{ dataField: 'label', caption: 'Content' },
	];
	@ViewChild("endUsersDataGrid", {}) endUsersDataGrid: DxDataGridComponent;
	@ViewChild("ignoredClaimItemsRef", {}) ignoredClaimItemsRef: DxDataGridComponent;
	public ignoredClaimItemsDataSource: any;
	public enduserDataSource: any[] = [];
	public enduserGridBoxValue: any[] = [];
	public arrSelectedEnduserList: any = [];
	public isEnduserDropDownBoxOpened: boolean = false;
	public enduserGridBoxColumns = [
		{ dataField: 'guid', caption: 'Guid', visible: false },
		{ dataField: 'code', caption: 'Code' },
		{ dataField: 'name', caption: 'Name' },
		{ dataField: 'pricelistname', caption: 'Pricelist', visible: false },
		{ dataField: 'pricelistno', caption: 'Pricelist No', visible: false },
	];
	public lengthSelectedEndusers: any;
	public itemTabDataList: any;
	public endUsersGridBoxValue: any;
	public isEndUsersDropDownBoxOpened: boolean = false;
	@ViewChild("itemDataGrid", {}) itemDataGrid: DxDataGridComponent;
	public itemGridBoxValue: any[] = [];
	public itemDataSource: any[] = [];
	public lengthSelectedItems: any;
	public itemGridBoxColumns: any;
	public chkAllItem: boolean = false;
	public arrSelectedItemList: any = [];
	public filterTextForIgnored: string = '';
	private filterForIgnoredValueChangeSbsn: Observable<any>;
	private isDisabledClearForIgnoredBtn: boolean = true;
	private instanceIgnoredClearBtn: any = null;
	public isVisibleConsiderClaimBtn: boolean = false;
	public isShowDropDownButton: boolean;
	public selectedKeyDropDownBtn: any;
	private listOfFeaturesCustomFields: any[] = [];
	public listOfActions: any[];
	public isShowIsActiveColumn: boolean = true;
	private guid = new Guid();
	public selectedEnduserDetail: any;
	public decimalPointForCurrency: number;
	public selectedEndUserList: any[] = [];
	public lastTaxCalculation: any[] = [];
	private mainSectionStorageKey: string;
	public stateStorageIgnoredTabKey: string;
	constructor(
		public _MinMaxEndUsersChargebackContractService: MinMaxEndUsersChargebackContractService,
		public _LoginService: LoginService,
		public _AppCommonSrvc: AppCommonSrvc,
		public _BillbackService: BillbackService,
		public _LoaderService: LoaderService,
		public _RestApiService: RestApiService,
		public _ToastrService: ToastrService,
		public _CustomCurrencyPipe: CustomCurrencyPipe,
		public _DatePipe: DatePipe,
		public _ChangeDetectorRef: ChangeDetectorRef,
		public _GlobalStateService: GlobalStateService,
		private _IxDxAlertService: IxDxAlertService,
	) {
		this.doLoadIgnoredItemTab = this.doLoadIgnoredItemTab.bind(this);
		this.doSaveIgnoredItemTab = this.doSaveIgnoredItemTab.bind(this);
	}

	ngOnInit(): void {
		this.itemGridBoxColumns = this._BillbackService.getItemGridBoxColumn();
		this.listOfActions = this._AppCommonSrvc.getListOfActionsForClaimPreviewDatagrid() || [];
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.mainSectionStorageKey = ServerSections.MANUAL_ADJUSTMENT_CLAIM;
		this.stateStorageIgnoredTabKey = ServerSections.MANUAL_ADJUSTMENT_IGNORED_CLAIM_TAB;
		if (this.claimPreviewProps.minMaxEndUsersChargebackContractModelUI) {
			this.minMaxEndUsersChargebackContractModelUI = this.claimPreviewProps.minMaxEndUsersChargebackContractModelUI;
			this.manualAdjustmentClaim.contractGuid = this.minMaxEndUsersChargebackContractModelUI.guid;
			this.manualAdjustmentClaim.contractName = this.minMaxEndUsersChargebackContractModelUI.contractName;
			this.manualAdjustmentClaim.selectCustomerId = this.minMaxEndUsersChargebackContractModelUI.selectCustomerId;
			this.manualAdjustmentClaim.selectCustomer = this.minMaxEndUsersChargebackContractModelUI.selectCustomer;
			this.manualAdjustmentClaim.claimedStatus = 'draft';
			this.itemTabDataList = this.claimPreviewProps.itemTabDataList || [];
		}
		this.manualAdjustmentClaim.reason_code_guid = this.claimPreviewProps.reason_code_guid;
		this.manualAdjustmentClaim.reason_code_label = this.claimPreviewProps.reason_code_label;
		this.manualAdjustmentClaim.reason_code_erpcode = this.claimPreviewProps.reason_code_erpcode;
		this.manualAdjustmentClaim.chargeback_period = moment(this.claimPreviewProps.chargeback_period).format('YYYY-MM-DD') || '';
		this.manualAdjustmentClaim.cut_off_date = moment(this.claimPreviewProps.cut_off_date).format('YYYY-MM-DD') || '';
		this.manualAdjustmentClaim.transaction_date = moment(this.claimPreviewProps.transaction_date).format('YYYY-MM-DD') || '';
		this.selectedEndUserList = this.claimPreviewProps.selectedEndUserList || [];
		this.fetchListOfCustomOverrideReason();
		this.fetchedEnUSerDetails();
		//this.fetchListOfCustomReason();
		// this.loadEnduserDataList();
		this.fetchedItemData()
		this.setSubmitMultiPleBtns();
		this.doCallGetTaxLevel();
	}

	ngOnDestroy(): void {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.getListOfReasonSubscription) {
			this.getListOfReasonSubscription.unsubscribe();
		}
		if (this.saveManualAdjustmentSbsn) {
			this.saveManualAdjustmentSbsn.unsubscribe();
		}
		if (this.addManualAdjustmenTtemsSbsn) {
			this.addManualAdjustmenTtemsSbsn.unsubscribe();
		}
		if (this.getPriceLstSbsn) {
			this.getPriceLstSbsn.unsubscribe();
		}
		if (this.getEndUSerDetailsSbsn) {
			this.getEndUSerDetailsSbsn.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}

		this._GlobalStateService.unsubscribe('GET_END_USER_PRICE_LIST_UOM_DATA_EVENT');
	}

	public doHiddenPopop() {
		this.isVisiblePopup = false;
		this.closeClaimManualAdjustmentPopup.emit();
	}

	private fetchListOfCustomOverrideReason() {
		let dynamic_groups = [];
		let dynamic_properties = [];
		try {
			dynamic_groups = this._LoginService.loginUser.account_detail.dynamic_groups || [];
			dynamic_properties = this._LoginService.loginUser.account_detail.dynamic_properties || [];
		} catch (error) {
			dynamic_groups = [];
			dynamic_properties = [];
		}
		let dgIndex = -1;
		dgIndex = dynamic_groups.findIndex(dg => dg.group_value.toString().toLowerCase() === 'overridereason');
		if (dgIndex !== -1) {
			const dgData = dynamic_groups[dgIndex];
			const dpIndex = dynamic_properties.findIndex(dp => dp.group_guid === dgData.guid && dp.property.toString().toLowerCase() === 'reasons');
			if (dpIndex !== -1) {
				const dpData = dynamic_properties[dpIndex];
				const reqFormData = new FormData();
				reqFormData.append('usr', this._LoginService.loginUser.user);
				reqFormData.append('token', this._LoginService.loginUser.token);
				reqFormData.append('method', ServerMethods.GET_ENTITY_DATA);
				reqFormData.append('entity', ServerEntity.DYNAMIC_PROPERTY_OPTIONS);
				reqFormData.append('search', JSON.stringify([{ property_guid: dpData.guid }]));
				reqFormData.append('sort_dir', 'asc');
				reqFormData.append('sort_field', 'sequence');
				reqFormData.append('is_dropdown', 'true');
				reqFormData.append('view_fields', JSON.stringify(['guid', 'property_guid', 'value', 'label', 'sequence', 'reason_description']));
				if (this.getListOfReasonSubscription) {
					this.getListOfReasonSubscription.unsubscribe();
				}
				this.listOfCustomOverrideReason = [];
				this._LoaderService.show();
				this.getListOfReasonSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
					next: response => {
						this._LoaderService.hide();
						if (response && response.flag) {
							this.listOfCustomOverrideReason = response.data || [];
						}
					}, error: error => {
						this._LoaderService.hide();
					}
				});
			}
		}
	}

	public displayExprReasonCode(item) {
		if (!item) {
			return "";
		}
		const name = item.label + ' (' + item.erpcode + ')';
		return name.toString();
	}

	public onReasonCodeSelectionChanged(e) {
		if (e.name === "value") {
			this.isReasonCodeDropDownBoxOpened = false;
			this._ChangeDetectorRef.detectChanges();
		}
	}

	public doChangeTab(newTab: string) {
		//this.approvalTrailDetails = this.manualAdjustmentClaim;
		this.currentTab = newTab;
	}

	public getListOfApproveStatus(data) {
		this.listOfStatuses.forEach(action => {
			// action.label = action.label.toUpperCase();
			action.label = action.label;
		});
		this.listOfStatuses.forEach(action => {
			if (action.value === data.claimedStatus) {
				action.visible = false;
			} else {
				action.visible = true;
			}
		});
	}

	public doFilterDateOnValueChanged(e) {
		// const previousValue = e.previousValue;
		// const newValue = e.value;
		if (e && e.value) {
			this.manualAdjustmentClaim.chargeback_period = e.value;
			//this.callClaimPreview();
		}
	}

	//#region for Enduser select box methods
	public loadEnduserDataList() {
		if (this.itemTabDataList && this.itemTabDataList.length > 0) {
			let listOfEndusers = _.map(this.itemTabDataList, item => {
				return {
					guid: item['end_user_id'],
					code: item['end_user_code'],
					name: item['end_user_name'],
					pricelistno: item['pricelistno'],
					pricelistname: item['pricelistname'],
				}
			});
			this.enduserDataSource = listOfEndusers || [];
		}
	}

	public displayExpEnduserName(enduser) {
		if (!enduser) {
			return "";
		}
		const name = enduser.name;
		return name.toString();
	}

	public onEndUsersSelectionChanged(e) {
		if (e.name === "value") {
			this.isEndUsersDropDownBoxOpened = false;
			this._ChangeDetectorRef.detectChanges();
		}
	}

	doCallGetTaxLevel() {
		const loginUser = this._LoginService.loginUser;
		const formData = new FormData();
		formData.append('usr', loginUser.user);
		formData.append('token', loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('is_dropdown', 'true');
		formData.append('entity', ServerEntity.TAXLEVELS);
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response.flag) {
					if (response.hasOwnProperty('data')) {
						this.lastTaxCalculation = response.data || [];
					}
				}
			},
			error: error => {
				this._LoaderService.hide();
				console.error(error);
			}
		});
	}

	public fetchedItemData() {
		let selectedEndUsersDataList: any[] = [];
		let endUserPriceListItems: any[] = [];
		selectedEndUsersDataList = this.selectedEndUserList;
		//if (this.endUsersDataGrid) {
		// selectedEndUsersDataList = this.endUsersDataGrid.instance.getSelectedRowsData();
		//}
		if (selectedEndUsersDataList.length > 0) {
			const itemPriceListNo = selectedEndUsersDataList[0]['pricelistno'];
			const itemPriceListName = selectedEndUsersDataList[0]['pricelistname'];
			this.fetchedEndUserListOfPrices(itemPriceListNo);
			this._GlobalStateService.unsubscribe('GET_END_USER_PRICE_LIST_UOM_DATA_EVENT');
			this._GlobalStateService.subscribe('GET_END_USER_PRICE_LIST_UOM_DATA_EVENT', response => {
				if (response.endUserListOfPrices && response.endUserListOfPrices.length > 0) {
					response.endUserListOfPrices.forEach(element => {
						if (element['items'] && element['items'].length > 0) {
							if (response.endUserListOfPrices.length == 1) {
								endUserPriceListItems = element['items'];
							}
						}
					});
				}
				this.itemDataSource = endUserPriceListItems || [];
			});

		}
	}

	private fetchedEndUserListOfPrices(pricelistno: string) {
		this.endUserListOfPrices = [];
		const searchArr = [];
		searchArr.push({ pricelistno: pricelistno });
		const viewFields = ['pricelistno', 'pricelistname', 'items', 'modified_date'];
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('method', 'getEntityData');
		formdata.append('is_dropdown', '1');
		formdata.append('entity', 'pricelists');
		formdata.append('search', JSON.stringify(searchArr));
		formdata.append('view_fields', JSON.stringify(viewFields));
		if (this.getPriceLstSbsn) {
			this.getPriceLstSbsn.unsubscribe();
		}
		this.getPriceLstSbsn = this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						this.endUserListOfPrices = response.data || [];
						this._GlobalStateService.notifyDataChangedDuplicate('GET_END_USER_PRICE_LIST_UOM_DATA_EVENT', { endUserListOfPrices: this.endUserListOfPrices });
					} else {
						this._GlobalStateService.notifyDataChangedDuplicate('GET_END_USER_PRICE_LIST_UOM_DATA_EVENT', { endUserListOfPrices: [] });
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._GlobalStateService.notifyDataChangedDuplicate('GET_END_USER_PRICE_LIST_UOM_DATA_EVENT', { endUserListOfPrices: [] });
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._GlobalStateService.notifyDataChangedDuplicate('GET_END_USER_PRICE_LIST_UOM_DATA_EVENT', { endUserListOfPrices: [] });
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public fetchedEnUSerDetails() {
		this.selectedEnduserDetail = null;
		let selectedEndUsersDataList: any[] = [];
		let endUserGuid: string = '';
		//if (this.endUsersDataGrid) {
		// selectedEndUsersDataList = this.endUsersDataGrid.instance.getSelectedRowsData();
		selectedEndUsersDataList = this.selectedEndUserList;
		//}
		if (selectedEndUsersDataList.length > 0) {
			endUserGuid = selectedEndUsersDataList[0]['guid'];
		}
		if (!endUserGuid) {
			return;
		}
		const searchArr = [{ guid: endUserGuid }];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.ENDUSERS);
		formData.append('is_dropdown', 'true');
		if (searchArr && searchArr.length > 0) {
			formData.append('search', JSON.stringify(searchArr));
		}

		if (this.getEndUSerDetailsSbsn) {
			this.getEndUSerDetailsSbsn.unsubscribe();
		}
		this.getEndUSerDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						this.selectedEnduserDetail = response.data[0] || null;
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}


	//#endregion

	//#region for Item|Product select box

	onItemValueChanged(e: any) {
		if (e.value === null) {
			this.itemGridBoxValue = [];
			this.itemDataGrid.instance.refresh();
			this.arrSelectedItemList = [];
		}
	}
	checkAllItem() {
		// if (this.chkAllItem) {
		// 	this.lengthSelectedItems = 'All Items Selected';
		// 	this.arrSelectedItemList = [];
		// 	this.itemDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedItems = '';
		// }
	}
	onItemSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			this.itemGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.itemGridBoxValue.push(e.selectedRowsData[i].itemname);
			};
		}
		this.lengthSelectedItems = '';
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			this.lengthSelectedItems = e.selectedRowsData.length + ' Item(s) Selected';
		}
	}
	public displayExprItemName(item) {
		let listOfText = [];
		if (this.arrSelectedItemList && this.arrSelectedItemList.length > 0) {
			listOfText.push(this.arrSelectedItemList.length + ' Items(s) Selected');
		}
		return listOfText.length > 0 ? listOfText.join(', ') : '';
	}
	//#endregion

	//#region Ignored Claim Item Tab
	// Method used to Display Toolbar for Export Data, Filter Data
	public doIgnoredItemsToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateIgnoredFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearForIgnoredBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceIgnoredClearBtn = args.component;
					},
					onClick: () => {
						this.ignoredClaimItemsRef.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenIgnoredFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				template: 'templateIgnoredActionBtns',
				visible: true,
			},
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcelForIgnoredTab();
			// 		}
			// 	}
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'refresh',
			// 		text: "Refresh",
			// 		onClick: (event: any) => {
			// 			this.refreshIgnoredItemDataGrid();
			// 		}
			// 	}
			//}
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.visible = false;
			columnChooserButton.showText = "always";
		}
	}
	// Method used to Open Filter Build Popup manually
	public doOpenIgnoredFilterBuilder() {
		this.ignoredClaimItemsRef.instance.option("filterBuilderPopup.visible", true);
	}
	public doRefreshIgnoredItemDataGrid() {
		this.ignoredClaimItemsRef.instance.refresh();
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcelForIgnoredTab() {
		const context = this;
		if (context.ignoredClaimItemsRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let ignoredItemWorksheet;
		let fileName = 'Manual_Adjustment.xlsx';
		if (this.manualAdjustmentClaim?.selectCustomer) {
			fileName = this.manualAdjustmentClaim.selectCustomer + '_Manual_Adjustment.xlsx';
		}
		ignoredItemWorksheet = workbook.addWorksheet('Manual Adjustment(s)');
		// let ignoredItemsWorkBook: any;
		// if (context.ignoreClaimItemsRef && context.ignoreClaimItemsRef.instance && context.ignoreClaimItemsRef.instance.totalCount() > 0) {
		// 	ignoredItemsWorkBook = workbook.addWorksheet('Ignored Item(s)');
		// }
		exportDataGrid({
			worksheet: ignoredItemWorksheet,
			component: context.ignoredClaimItemsRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			// topLeftCell: { row: 8, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'selectedClaimedUom' || gridCell.column.dataField === 'contract_uom') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value.toString().toUpperCase();
						}
					}
					if (gridCell.column.dataField === 'taxexempt') {
						excelCell.value = '';
						excelCell.value = gridCell.value ? 'YES' : 'NO';
					}
				}
			}
		}).then((cellRange) => {
			//this.doSetHeaderBlockOnExportExcel(ignoredItemWorksheet, context);
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});

	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtnForIgnoredTab() {
		if (this.instanceIgnoredClearBtn !== null) {
			setTimeout(() => {
				this.instanceIgnoredClearBtn.option({ disabled: this.isDisabledClearForIgnoredBtn });
			}, 10);
		}
	}

	public doSelectionChangedIgnoredGrd(e) {
		if (e.selectedRowKeys.length > 0) {
			let isReasonExists = false;
			e.selectedRowsData.forEach(row => {
				isReasonExists = false;
				if (row && !row.hasOwnProperty('reason_value') || (row.reason_value && !row.reason_value.trim())) {
					isReasonExists = true;
				}
			});
			if (isReasonExists) {
				this._ToastrService.info('Reason is required', 'Info', { closeButton: true, tapToDismiss: true });
				setTimeout(() => {
					e.component.deselectRows(e.selectedRowKeys[e.selectedRowKeys.length - 1]);
				}, 200);
			}
			this.isVisibleConsiderClaimBtn = true;
		} else {
			this.isVisibleConsiderClaimBtn = false;
		}
	}

	public doEditorPreparingIgnoredGrd(e) {
		if (e.parentType == 'dataRow' && e.dataField === 'reason_value') {
			const rowIndex = e.row.rowIndex;
			const dataGridInstance = this.ignoredClaimItemsRef.instance;
			e.editorOptions.dataSource = this.listOfCustomOverrideReason;
			e.editorOptions.onValueChanged = (event: any) => {
				dataGridInstance.cellValue(rowIndex, 'reason_value', event.value);
				const selectedCustomReason = this.listOfCustomOverrideReason.find(customReason => customReason.value === event.value);
				if (selectedCustomReason && selectedCustomReason.guid) {
					dataGridInstance.cellValue(rowIndex, 'reason_description', selectedCustomReason.reason_description || '');
				}
				dataGridInstance.columnOption('reason_description', 'allowEditing', false);
				if (event.value === 'other') {
					dataGridInstance.columnOption('reason_description', 'allowEditing', true);
				}
			};
		}
	}

	public doInitializedIgnoredClaimItemDataGrid(event) {
		this.doAddCustomColumns(event.component)
		// setTimeout(() => {
		// 	this.ignoredClaimItemsRef.instance.state(null);
		// }, 300);

		// setTimeout(() => {
		// 	const tableColLen = event && event.component ? event.component.getVisibleColumns().length : 0;
		// 	const customFieldDataList = [];
		// 	if (this.listOfFeaturesCustomFields && this.listOfFeaturesCustomFields.length > 0) {
		// 		for (let i = 0; i < this.listOfFeaturesCustomFields.length; i++) {
		// 			const field = this.listOfFeaturesCustomFields[i];
		// 			let customFieldDataObj = {
		// 				dataField: field.ix_custom_field,
		// 				caption: field.custom_field_title,
		// 				dataType: field.custom_field_output_format,
		// 				allowHeaderFiltering: false,
		// 				visible: field.display,
		// 				showInColumnChooser: field.display,
		// 				allowEditing: false
		// 				// visibleIndex: tableColLen + i,
		// 			};
		// 			customFieldDataList.push(customFieldDataObj);
		// 		}
		// 		const listOfColumns = this.ignoredClaimItemsRef.instance.getVisibleColumns();
		// 		customFieldDataList.forEach(element => {
		// 			const columnIndex = listOfColumns.findIndex(col => col.dataField === element.dataField);
		// 			if (columnIndex === -1) {
		// 				// console.log('Add');
		// 				this.ignoredClaimItemsRef.instance.addColumn(element);
		// 			}
		// 		});
		// 	}
		// }, 1000);
	}

	private doAddCustomColumns(dataGridInstance: any) {
		let getVisibleColumns = [];
		let actionColumnIndex = -1;
		const specificListOfCustomFieldsColumns = this.listOfFeaturesCustomFields;
		const stateObj = this.loadDatagridState(this.stateStorageIgnoredTabKey, this.mainSectionStorageKey);
		setTimeout(() => {
			if (stateObj && stateObj.hasOwnProperty('columns')) {
				getVisibleColumns = dataGridInstance.getVisibleColumns();
				specificListOfCustomFieldsColumns.forEach((element, index) => {
					element.isCustomField = true;
					const isStateColumnIndex = stateObj.columns.findIndex(vColumn => vColumn.dataField === element.dataField)
					if (isStateColumnIndex === -1) {
						stateObj.columns.push(element);
						dataGridInstance.addColumn(element);
					} else {
						dataGridInstance.columnOption(element.dataField, 'caption', element.caption);
						dataGridInstance.columnOption(element.dataField, 'dataType', element.dataType);
						if (element.format) {
							dataGridInstance.columnOption(element.dataField, 'format', element.format);
						}
						if (element.customizeText) {
							dataGridInstance.columnOption(element.dataField, 'customizeText', element.customizeText);
						}
						dataGridInstance.columnOption(element.dataField, 'allowFiltering', element.allowFiltering);
						dataGridInstance.columnOption(element.dataField, 'allowHeaderFiltering', element.allowHeaderFiltering);
					}
				});
				stateObj.columns.forEach((column, index) => {
					if (column.hasOwnProperty('added') && (!column.added.hasOwnProperty('isCustomField') || column.added.isCustomField)) {
						if (specificListOfCustomFieldsColumns.findIndex(customColumn => customColumn.dataField === column.dataField) === -1) {
							dataGridInstance.deleteColumn(column.dataField);
							stateObj.columns.splice(index, 1);
						}
					}
				});
				stateObj.columns.forEach((column, index) => {
					column.visibleIndex = index + 1;
				});
				actionColumnIndex = stateObj.columns.findIndex(vc => vc.dataField === 'guid' && vc.caption === 'Action');
				if (actionColumnIndex !== -1) {
					stateObj.columns[actionColumnIndex]['visibleIndex'] = getVisibleColumns.length + 1;
				}
				getVisibleColumns = dataGridInstance.getVisibleColumns();
				getVisibleColumns.forEach((column, index) => {
					dataGridInstance.columnOption(column.dataField, 'visibleIndex', index + 1);
				});
				getVisibleColumns = dataGridInstance.getVisibleColumns();
				actionColumnIndex = getVisibleColumns.findIndex(vc => vc.dataField === 'guid' && vc.caption === 'Action');
				if (actionColumnIndex !== -1) {
					dataGridInstance.columnOption('guid', 'visibleIndex', getVisibleColumns.length + 1);
				}
			} else {
				getVisibleColumns = dataGridInstance.getVisibleColumns();
				specificListOfCustomFieldsColumns.forEach((element, index) => {
					dataGridInstance.addColumn(element);
				});
				getVisibleColumns = dataGridInstance.getVisibleColumns();
				getVisibleColumns.forEach((column, index) => {
					dataGridInstance.columnOption(column.dataField, 'visibleIndex', index + 1);
				});
				getVisibleColumns = dataGridInstance.getVisibleColumns();
				actionColumnIndex = getVisibleColumns.findIndex(vc => vc.dataField === 'guid' && vc.caption === 'Action');
				if (actionColumnIndex !== -1) {
					dataGridInstance.columnOption('guid', 'visibleIndex', getVisibleColumns.length + 1);
				}
			}
		}, 100);
	}

	public doOnEditorPreparingIgnoredClaimItem(event) {
		if (event && event['row'] && event['row']['data']) {
			const rowIndex = this.ignoredClaimItemsRef.instance.getRowIndexByKey(event.row.key);
			const itemObject = event.row.data || [];
			if (event.dataField === 'quantity') {
				event.editorOptions.onValueChanged = (e: any) => {
					let linetotalVal = 0;
					let taxamountVal = 0;
					if ((e.value && e.value > 0) && (itemObject.hasOwnProperty('chargebackAmount') && itemObject.chargebackAmount && itemObject.chargebackAmount > 0)) {
						linetotalVal = (itemObject.chargebackAmount * e.value) || 0;
					}
					if ((linetotalVal > 0) && (itemObject.hasOwnProperty('taxrate') && itemObject.taxrate > 0)) {
						taxamountVal = (linetotalVal * itemObject.taxrate) / 100;
					}
					// linetotalVal = linetotalVal + taxamountVal;
					linetotalVal = linetotalVal;
					const finallinetotalVal: any = linetotalVal > 0 ? linetotalVal || 0 : '';
					const finaltaxamountVal: any = taxamountVal > 0 ? taxamountVal || 0 : '';
					this.ignoredClaimItemsRef.instance.cellValue(rowIndex, 'quantity', e.value);
					this.ignoredClaimItemsRef.instance.cellValue(rowIndex, 'taxamount', finaltaxamountVal);
					this.ignoredClaimItemsRef.instance.cellValue(rowIndex, 'linetotal', finallinetotalVal);
				}
			}
			if (event.dataField === 'chargebackAmount') {
				event.editorOptions.onValueChanged = (e: any) => {
					let linetotalVal = 0;
					let taxamountVal = 0;
					if ((e.value && e.value > 0) && (itemObject.hasOwnProperty('quantity') && itemObject.quantity && itemObject.quantity > 0)) {
						linetotalVal = (itemObject.quantity * e.value) || 0;
					}
					if ((linetotalVal > 0) && (itemObject.hasOwnProperty('taxrate') && itemObject.taxrate > 0)) {
						taxamountVal = (linetotalVal * itemObject.taxrate) / 100;
					}
					// linetotalVal = linetotalVal + taxamountVal;
					linetotalVal = linetotalVal;
					// this._CustomCurrencyPipe.transform(linetotalVal || 0)
					const finallinetotalVal: any = linetotalVal > 0 ? linetotalVal || 0 : '';
					const finaltaxamountVal: any = taxamountVal > 0 ? taxamountVal || 0 : '';
					this.ignoredClaimItemsRef.instance.cellValue(rowIndex, 'chargebackAmount', e.value);
					this.ignoredClaimItemsRef.instance.cellValue(rowIndex, 'taxamount', finaltaxamountVal);
					this.ignoredClaimItemsRef.instance.cellValue(rowIndex, 'linetotal', finallinetotalVal);
				}
			}
		}
	}

	public doCustomizelineTotalSummary(e) {
		if (e.value < 0) {
			return 0;
		} else {
			return e.valueText;
		}
	}

	public doActionsBtnClickForIgnored(event: any) {
		const addIndex = this.listOfActions.findIndex(i => i.btn_code
			=== 'ADD');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].disabled = !this.isShowIsActiveColumn;
		}
	}
	//#endregion Ignored Claim Tab

	public doOnSavedIgnoredClaimItemDataGrid(event: any) {

	}

	public getSubmitBtnText() {
		return this.listOfStatusesBtns ? this.listOfStatusesBtns[0].text : '';
	}

	private setSubmitMultiPleBtns() {
		const priceListStatus = this.manualAdjustmentClaim.claimedStatus || null;
		this.listOfStatusesBtns = [];
		if (priceListStatus === MinMaxChargebackStatusValEnum.DRAFT) {
			const signatureReqStatusIndex = this.listOfStatuses.findIndex(rs => rs.value === MinMaxChargebackStatusValEnum.PENDING_APPROVAL);
			const signatureReqClone = JSON.parse(JSON.stringify(this.listOfStatuses[signatureReqStatusIndex]));
			signatureReqClone.label = 'Submit For Approval';
			this.listOfStatusesBtns.push(signatureReqClone);
		} else if (priceListStatus === MinMaxChargebackStatusValEnum.PENDING_APPROVAL) {
			const currentStatusIndex = this.listOfStatuses.findIndex(rs => rs.value === MinMaxChargebackStatusValEnum.APPROVED);
			const currentStatusClone = JSON.parse(JSON.stringify(this.listOfStatuses[currentStatusIndex]));
			currentStatusClone.label = 'Approve';
			this.listOfStatusesBtns.push(currentStatusClone);
			const draftStatusIndex = this.listOfStatuses.findIndex(rs => rs.value === MinMaxChargebackStatusValEnum.DRAFT);
			const draftClone = JSON.parse(JSON.stringify(this.listOfStatuses[draftStatusIndex]));
			draftClone.label = 'Set To Draft';
			this.listOfStatusesBtns.push(draftClone);
		} else if (priceListStatus === MinMaxChargebackStatusValEnum.APPROVED) {
			const draftStatusIndex = this.listOfStatuses.findIndex(rs => rs.value === MinMaxChargebackStatusValEnum.DRAFT);
			const draftClone = JSON.parse(JSON.stringify(this.listOfStatuses[draftStatusIndex]));
			draftClone.label = 'Set To Draft';
			this.listOfStatusesBtns.push(draftClone);
		} else {
			const currentStatusIndex = this.listOfStatuses.findIndex(rs => rs.value === MinMaxChargebackStatusValEnum.DRAFT);
			const draftStatusClone = JSON.parse(JSON.stringify(this.listOfStatuses[currentStatusIndex]));
			draftStatusClone.label = 'Submit';
			this.listOfStatusesBtns.push(draftStatusClone);
			const signatureReqStatusIndex = this.listOfStatuses.findIndex(rs => rs.value === MinMaxChargebackStatusValEnum.PENDING_APPROVAL);
			const signatureReqClone = JSON.parse(JSON.stringify(this.listOfStatuses[signatureReqStatusIndex]));
			signatureReqClone.label = 'Submit For Approval';
			this.listOfStatusesBtns.push(signatureReqClone);
		}
		this.selectedKeyDropDownBtn = this.listOfStatusesBtns[0].value;
		this.isHideShowDropDownButton();
	}

	private isHideShowDropDownButton() {
		this.isShowDropDownButton = false;
		if (this.manualAdjustmentClaim.claimedStatus === MinMaxChargebackStatusValEnum.DRAFT) {
			this.isShowDropDownButton = true;
		}
		if (this.manualAdjustmentClaim.claimedStatus === MinMaxChargebackStatusValEnum.PENDING_APPROVAL) {
			this.isShowDropDownButton = false;
		}
		if (this.manualAdjustmentClaim.claimedStatus === MinMaxChargebackStatusValEnum.APPROVED) {
			this.isShowDropDownButton = false;
		}
	}


	public addManualAdjustmentItems() {
		let selectedEnduserData: any[] = [];
		let selectedItemData: any[] = [];
		let manualAdjustmentClaimLst: any[] = [];
		if (this.ignoredClaimItemsDataSource && this.ignoredClaimItemsDataSource.length > 0) {
			manualAdjustmentClaimLst = this.ignoredClaimItemsDataSource;
		}

		selectedEnduserData = this.selectedEndUserList || [];
		//if (this.endUsersDataGrid) {
		// selectedEnduserData = this.endUsersDataGrid.instance.getSelectedRowsData();
		//}
		if (this.itemDataGrid) {
			selectedItemData = this.itemDataGrid.instance.getSelectedRowsData();
		}

		if (selectedEnduserData.length == 0) {
			this._ToastrService.info('Please select End user', 'Info', { closeButton: true, tapToDismiss: true });
			return
		}
		if (selectedEnduserData.length > 1) {
			this._ToastrService.info('Please select single End user', 'Info', { closeButton: true, tapToDismiss: true });
			return
		}
		if (selectedItemData.length == 0) {
			this._ToastrService.info('Please select atleast one Item', 'Info', { closeButton: true, tapToDismiss: true });
			return
		}

		// selectedEnduserData.forEach(endUserObj => {
		// 	selectedItemData.forEach(itemObj => {
		// 		let manualAdjustmentClaimDetailObj: ManualAdjustmentClaimDetailModel = new ManualAdjustmentClaimDetailModel();
		// 		manualAdjustmentClaimDetailObj.guid = this.guid.newGuid();
		// 		manualAdjustmentClaimDetailObj.end_user_id = endUserObj.guid;
		// 		manualAdjustmentClaimDetailObj.end_user_code = endUserObj.code;
		// 		manualAdjustmentClaimDetailObj.end_user_name = endUserObj.name;
		// 		manualAdjustmentClaimDetailObj.pricelistname = endUserObj.pricelistname;
		// 		manualAdjustmentClaimDetailObj.pricelistno = endUserObj.pricelistno;
		// 		if (this.selectedEnduserDetail) {
		// 			manualAdjustmentClaimDetailObj.taxexempt = this.selectedEnduserDetail.taxexempt || false;
		// 			manualAdjustmentClaimDetailObj.taxrate = this.getTaxRate();
		// 		}
		// 		manualAdjustmentClaimDetailObj.erp_primary_key = itemObj.erp_primary_key;
		// 		manualAdjustmentClaimDetailObj.itemname = itemObj.itemname;
		// 		manualAdjustmentClaimDetailObj.description = itemObj.description;
		// 		manualAdjustmentClaimDetailObj.min = itemObj.min;
		// 		manualAdjustmentClaimDetailObj.max = itemObj.max;
		// 		manualAdjustmentClaimDetailObj.selectedUom = itemObj.priceuom;
		// 		manualAdjustmentClaimDetailObj.listPrice = itemObj.price;
		// 		manualAdjustmentClaimDetailObj.type = 'override';
		// 		if (this.checkDuplicateItem(manualAdjustmentClaimDetailObj)) {
		// 			manualAdjustmentClaimLst.push(JSON.parse(JSON.stringify(manualAdjustmentClaimDetailObj)));
		// 		}
		// 	});
		// });
		let end_user_code = selectedEnduserData[0].code;
		let manual_adjustment_items: any[] = [];
		selectedItemData.forEach(itemObj => {
			if (this.checkDuplicateItem(itemObj.erp_primary_key)) {
				manual_adjustment_items.push(itemObj.erp_primary_key);
			}
		});
		const cut_off_date = this.claimPreviewProps?.cut_off_date || null;
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.ADD_MANUAL_ADJUSTMENT_ITEMS);
		formData.append('manual_adjustment_items', JSON.stringify(manual_adjustment_items));
		formData.append('end_user_code', end_user_code);
		formData.append('contractguid', this.minMaxEndUsersChargebackContractModelUI.guid);
		formData.append('chargeback_period', this.manualAdjustmentClaim.chargeback_period);
		if (cut_off_date) {
			formData.append('cut_off_date', cut_off_date);
		}

		if (this.addManualAdjustmenTtemsSbsn) {
			this.addManualAdjustmenTtemsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.addManualAdjustmenTtemsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response && response.flag) {
					if (response.data && response.data.length > 0) {
						response.data.forEach(elementItem => {
							elementItem.chargebackAmount = '';
							manualAdjustmentClaimLst.push(elementItem);
						});
						this.ignoredClaimItemsDataSource = manualAdjustmentClaimLst || [];
					}
					this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
		this.resetDataGrid();
	}

	public getTaxRate() {
		let taxRate: number = 0;
		if (this.selectedEnduserDetail.hasOwnProperty('taxcode') && this.lastTaxCalculation.length > 0) {
			const taxIndex = this.lastTaxCalculation.findIndex(i => i.taxcode === this.selectedEnduserDetail.taxcode);
			if (taxIndex != -1) {
				const ratesList = this.lastTaxCalculation[taxIndex].rates;
				if (ratesList.length > 0) {
					ratesList.forEach(elementRate => {
						const chargeback_period = moment(this.claimPreviewProps.chargeback_period).format('YYYY-MM-DD') || '';
						if (chargeback_period) {
							if (elementRate.hasOwnProperty('startdate') && elementRate.hasOwnProperty('enddate') && elementRate.hasOwnProperty('rate')) {
								if ((chargeback_period >= elementRate.startdate) && (chargeback_period <= elementRate.enddate)) {
									taxRate = elementRate.rate || 0;
								}
							}
						}
					});
				}
			}
			return taxRate;
		}
	}

	public checkDuplicateItem(erp_primary_key) {
		let manualAdjustmentClaimLst: any[] = [];
		if (this.ignoredClaimItemsDataSource && this.ignoredClaimItemsDataSource.length > 0) {
			manualAdjustmentClaimLst = this.ignoredClaimItemsDataSource;
		}
		if (manualAdjustmentClaimLst.length > 0) {
			const indexClaim = manualAdjustmentClaimLst.findIndex(i => i.erp_primary_key === erp_primary_key);
			if (indexClaim !== -1) {
				return false;
			}
		}
		return true;
	}

	public resetDataGrid() {
		//this.endUsersDataGrid.instance.refresh();
		//this.endUsersGridBoxValue = [];
		this.itemDataGrid.instance.refresh();
		this.arrSelectedItemList = [];
		this.lengthSelectedItems = '';
	}

	public doSaveUpdateClaimList(e) {
		const status = e.selectedItem.value;
		this.callSubmitClaim(status);
	}

	public doSaveUpdateClaimStatusChanged(e) {
		const status = e.itemData.value;
		this.callSubmitClaim(status);
	}

	public callSubmitClaim(status) {
		if (status === MinMaxChargebackStatusValEnum.DRAFT) {
			this.doSubmitForDraft();
		}
		if (status === MinMaxChargebackStatusValEnum.PENDING_APPROVAL) {
			this.doSubmitForPendingApproval();
		}
		if (status === MinMaxChargebackStatusValEnum.APPROVED) {
			this.doSubmitForApproved();
		}
	}

	public doSubmitForDraft() {
	}

	public doSubmitForPendingApproval() {

		if (!this.checkManualAdjustmentValid()) {
			return;
		}
		this.manualAdjustmentClaim.guid = this.guid.newGuid();
		this.manualAdjustmentClaim.ignoredItems = JSON.parse(JSON.stringify(this.ignoredClaimItemsDataSource));
		this.manualAdjustmentClaim.claimtype = 'manual_adjustment'; //regular_claim
		const cut_off_date = this.claimPreviewProps?.cut_off_date || null;
		this.manualAdjustmentClaim.cut_off_date = cut_off_date || null;
		let transactionDate = this.claimPreviewProps.transaction_date ? this.claimPreviewProps.transaction_date : moment(new Date()).format('YYYY-MM-DD');
		const ixCustomDialogOptions: any = {
			popupIconsHtml: '<div class="sendIcon"><div class="infoIcon mrgT0"></div></div>',
			title: '',
			showTitle: false,
			subtitle: 'Submit For Approval',
			message: 'Are you sure you want to submit for Approval ?<br/><b>This transaction will be created with posting date: ' + transactionDate + '</b><br/>You will not be able to undo the operation.'
		};
		this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
			if (result) {
				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('method', ServerMethods.SAVE_MANUAL_ADJUSTMENT);
				formData.append('contracttype', ContractTypeValEnum.MINMAXENDUSERSCHARGEBACKCONTRACTS);
				formData.append('contractguid', this.minMaxEndUsersChargebackContractModelUI.guid);
				formData.append('manual_adjustment', JSON.stringify(this.manualAdjustmentClaim));
				if (transactionDate) {
					formData.append('transaction_date', transactionDate);
				}
				if (this.saveManualAdjustmentSbsn) {
					this.saveManualAdjustmentSbsn.unsubscribe();
				}
				this._LoaderService.show();
				this.saveManualAdjustmentSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
					next: response => {
						this._LoaderService.hide();
						if (response && response.flag) {
							this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
						} else {
							this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
						}
						this.isVisiblePopup = false;
						// this.closeClaimPopup.emit();
					}, error: error => {
						this._LoaderService.hide();
						this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
					}
				});
			}
		});
		//}
	}

	public doSubmitForApproved() {
	}

	public checkManualAdjustmentValid(): boolean {
		const lstclaimedItem: any[] = JSON.parse(JSON.stringify(this.ignoredClaimItemsDataSource)) || [];
		let returnVal = true;
		if (lstclaimedItem.length == 0) {
			this._ToastrService.info('Please add atleast one item ', 'Info', { closeButton: true, tapToDismiss: true });
			returnVal = false;
			return;
		}
		if (lstclaimedItem.length > 0) {
			const lstIndex = lstclaimedItem.findIndex(i => (!i.reason_value) || (!i.reason_description));
			if (lstIndex !== -1) {
				this._ToastrService.info('Reason and Reason description is required ', 'Info', { closeButton: true, tapToDismiss: true });
				returnVal = false;
				return;
			}
		}
		return returnVal;
	}

	public doActionsBtnItemClickForIgnored(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshIgnoredItemDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcelForIgnoredTab();
					break;
				case 'COLUMN_CHOOSER':
					this.ignoredClaimItemsRef.instance.showColumnChooser();
					break;
			}
		}
	}

	private doSetHeaderBlockOnExportExcel(worksheet, context) {
		const headerRow = worksheet.getRow(2);
		headerRow.height = 30;
		if (context.claimPreviewDetails.contractName) {
			worksheet.mergeCells(2, 1, 2, 12);
		}
		headerRow.getCell(1).value = context.claimPreviewDetails.contractName;
		headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 14, 'bold': true };
		headerRow.getCell(1).alignment = { horizontal: 'center', vertical: 'center' };

		const docRefNumRow = worksheet.getRow(2);
		docRefNumRow.height = 30;
		docRefNumRow.getCell(1).value = 'Chargeback Duration';
		docRefNumRow.getCell(1).alignment = { horizontal: 'left' };
		docRefNumRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		let monthFilterValue = context.claimPreviewDetails.chargeback_period;
		docRefNumRow.getCell(2).value = this._DatePipe.transform(monthFilterValue, 'MMMM, yyyy');;
		docRefNumRow.getCell(2).alignment = { horizontal: 'left' };
		docRefNumRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		if (context.claimPreviewDetails.documentDate) {
			docRefNumRow.getCell(4).value = 'Document Date';
			docRefNumRow.getCell(4).font = { name: 'Segoe UI Light', size: 12, bold: false };
			docRefNumRow.getCell(5).value = context.claimPreviewDetails.documentDate || '';
			docRefNumRow.getCell(5).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}

		const selectCustomerRow = worksheet.getRow(3);
		selectCustomerRow.height = 30;
		selectCustomerRow.getCell(1).value = 'Customer Code';
		selectCustomerRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		let selectCustomerRowValue = context.claimPreviewDetails.selectCustomerId || '';
		selectCustomerRow.getCell(2).value = selectCustomerRowValue;
		selectCustomerRow.getCell(2).alignment = { horizontal: 'left' };
		selectCustomerRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		if (context.claimPreviewDetails.selectCustomer) {
			selectCustomerRow.getCell(4).value = 'Customer Name';
			selectCustomerRow.getCell(4).font = { name: 'Segoe UI Light', size: 12, bold: false };
			selectCustomerRow.getCell(5).value = context.claimPreviewDetails.selectCustomer || '';
			selectCustomerRow.getCell(5).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}
		let sourceClaimNoRowValue = context.claimPreviewDetails.claimUse || '';
		if (sourceClaimNoRowValue) {
			const sourceClaimNoRow = worksheet.getRow(4);
			sourceClaimNoRow.height = 30;
			sourceClaimNoRow.getCell(1).value = 'Source Claim No.';
			sourceClaimNoRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
			sourceClaimNoRow.getCell(2).value = sourceClaimNoRowValue;
			sourceClaimNoRow.getCell(2).alignment = { horizontal: 'left' };
			sourceClaimNoRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}

		let claimedUserNoteRowValue = context.claimPreviewDetails.claimedUserNote || '';
		if (claimedUserNoteRowValue) {
			const sourceClaimNoRow = worksheet.getRow(5);
			sourceClaimNoRow.height = 30;
			sourceClaimNoRow.getCell(1).value = 'User Note.';
			sourceClaimNoRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
			sourceClaimNoRow.getCell(2).value = claimedUserNoteRowValue;
			sourceClaimNoRow.getCell(2).alignment = { horizontal: 'left' };
			sourceClaimNoRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}
	}

	// #region for Ignored Claim Tab Datagrid State Storing functionality

	public doLoadIgnoredItemTab() {
		setTimeout(() => {
			if (this.ignoredClaimItemsRef) {
				this.filterValueChangeSbsn = this.ignoredClaimItemsRef.filterValueChange.asObservable();
				this.filterValueChangeSbsn.subscribe((result) => {
					this.getFilterTextIgnoredTab();
				});
			}
		}, 60);
		return this.loadDatagridState(this.stateStorageIgnoredTabKey, this.mainSectionStorageKey);
	}

	public doSaveIgnoredItemTab(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageIgnoredTabKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}
	// #endregion

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterTextIgnoredTab() {
		this.filterTextForIgnored = '';
		this.isDisabledClearForIgnoredBtn = true;
		if (this.ignoredClaimItemsRef) {
			this.ignoredClaimItemsRef.filterPanel.customizeText = (e: any) => {
				this.filterTextForIgnored = e.text;
				this.isDisabledClearForIgnoredBtn = false;
			};
		}
		setTimeout(() => {
			this.updateStatesClearBtnForIgnoredTab();
		}, 600);
	}
}
