import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class BillbackContractService {

	public tabItemsList: any[] = [
		{ key: 'items', displayVal: 'Items' },
		{ key: 'billbackdocuments', displayVal: 'Billback Documents' }
	];
	public editBillbackGuid: any;
	public itemTabDataList: any = [];
	public calledClaimedItem: string;
	private actionFrom: string;
	constructor() { }

	public setEditBillbackGuid(editBillbackGuid) {
		this.editBillbackGuid = editBillbackGuid;
	}
	public getEditBillbackGuid() {
		return this.editBillbackGuid;
	}
	public setItemTabDataList(list) {
		this.itemTabDataList = list;
	}
	public getItemTabDataList() {
		return this.itemTabDataList;
	}

	public setActionFrom(actionFrom: string) {
		this.actionFrom = actionFrom;
	}
	public getActionFrom() {
		return this.actionFrom || 'billback_chargeback';
	}
}
