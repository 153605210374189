<div id="wrapper" class="loginScreens signUpPage">
	<div class="signUpContainer cf">
		<div class="cols12 cf mrgB20 txtCenter">
			<a>
				<img [src]="_AppCommonSrvc.cdnImgPath + 'logo.png'" width="222" height="48" alt="incentX" title="incentX">
			</a>
		</div>
		<div class="regTitles">
			<h3>New Account Registration </h3>
		</div>
		<div class="signUpForm cf">
			<div class="cols8">
				<form (submit)="doSignUp($event);" autocomplete="off">
					<div class="row mrgB0">
						<div class="cols12">
							<div class="feildCol">
								<dx-text-box mode="text" [(value)]="signUpUI.company_name" maxlength="100"
									[showClearButton]="true">
									<dx-validator>
										<dxi-validation-rule type="required" message="Account Name is required">
										</dxi-validation-rule>
									</dx-validator>
								</dx-text-box>
								<div class="inputField">
									<label for="company_name">Account Name</label>
								</div>
							</div>
						</div>
						<div class="cols6">
							<div class="feildCol">
								<dx-text-box mode="text" [(value)]="signUpUI.firstname" maxlength="50" [showClearButton]="true">
									<dx-validator>
										<dxi-validation-rule type="required" message="First Name is required">
										</dxi-validation-rule>
									</dx-validator>
								</dx-text-box>
								<div class="inputField">
									<label for="firstname">First Name</label>
								</div>
							</div>
						</div>
						<div class="cols6">
							<div class="feildCol">
								<dx-text-box mode="text" [(value)]="signUpUI.lastname" maxlength="50" [showClearButton]="true">
									<dx-validator>
										<dxi-validation-rule type="required" message="Last Name is required">
										</dxi-validation-rule>
									</dx-validator>
								</dx-text-box>
								<div class="inputField">
									<label for="lastname">Last Name</label>
								</div>
							</div>
						</div>
						<div class="cols6">
							<div class="feildCol">
								<dx-text-box mode="text" [(value)]="signUpUI.email" maxlength="200" [showClearButton]="true">
									<dx-validator>
										<dxi-validation-rule type="required" message="Email is required">
										</dxi-validation-rule>
										<dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
									</dx-validator>
								</dx-text-box>
								<div class="inputField">
									<label for="emailID">Email Address</label>
								</div>
							</div>
						</div>
						<div class="cols6">
							<div class="feildCol">
								<dx-text-box #pwd [(mode)]="passwordMode" [(value)]="signUpUI.password" [showClearButton]="true"
									maxlength="50">
									<dxi-button name="btn_Password" location="after" [options]="passwordButton">
									</dxi-button>
									<dx-validator>
										<dxi-validation-rule type="required" message="Password is required">
										</dxi-validation-rule>
									</dx-validator>
								</dx-text-box>
								<div class="inputField">
									<label for="password" maxlength="100">Password</label>
								</div>
							</div>
						</div>
						<div class="cols6 serchBox mrgB0 inputSelectOut">
							<div class="searchBoxIn">
								<div class="feildCol serchBox countryFlags">
									<span id="authphonenumberPopover" (click)="doHideShowAuthPhoneNumberPopover()"
										class="selectTip">
										<div class="flag-container"
											*ngIf="signUpUI.dialcode_phone && signUpUI.dialcode_phone.code">
											<div class="iti-flag {{signUpUI.dialcode_phone.icon}}"></div>
										</div>
									</span>
									<dx-popover target="#authphonenumberPopover" position="top" [width]="550"
										[(visible)]="isHideShowAuthPhoneNumberPopover">
										<div *dxTemplate="let data = model; of: 'content'">
											<div class="row">
												<div class="cols12">
													<dx-data-grid #authphonedialcodeListDatagridRef [dataSource]="listOfDialcodes"
														keyExpr="id" [selection]="{ mode: 'single' }" [hoverStateEnabled]="true"
														[paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: true }"
														[scrolling]="{ mode: 'virtual' }" [height]="345"
														[selectedRowKeys]="[dialCodeId]" [allowColumnResizing]="false"
														[columnResizingMode]="'nextColumn'" [columnAutoWidth]="true"
														[showColumnLines]="true" [showRowLines]="false"
														(onSelectionChanged)="doSelectAuthPhoneNumber($event)">
														<dxi-column dataField="id" [visible]="false"></dxi-column>
														<dxi-column dataField="name" [visible]="true" [allowHeaderFiltering]="false"
															caption="Name" cellTemplate="cellTemplateName">
															<div *dxTemplate="let element of 'cellTemplateName'">
																<div class="flag-container">
																	<div class="iti-flag {{element.data.icon}}"></div>
																	<span class="country-name" [innerHtml]="element.data.name"></span>
																</div>
															</div>
														</dxi-column>
														<dxi-column dataField="code" [visible]="true" [allowHeaderFiltering]="false"
															caption="Code" cellTemplate="cellTemplateCode" width="80">
															<div *dxTemplate="let element of 'cellTemplateCode'">
																<div class="flag-container">
																	<span class="dial-code">+{{element.data.code}}</span>
																</div>
															</div>
														</dxi-column>
													</dx-data-grid>
												</div>
											</div>
										</div>
									</dx-popover>
								</div>
							</div>
							<div class="dx-field mrgB20">
								<div class="dx-field-label">Authorization Phone Number</div>
								<div class="dx-field-value">
									<dx-text-box mode="text" [(value)]="signUpUI.mobile" [showClearButton]="true"
										[maxLength]="20" name="phoneNumber" class="phoneNumberField"
										hint="This number will be use for two-factor authentication by Authy">
									</dx-text-box>
								</div>
							</div>
						</div>
						<div class="cols6 serchBox mrgB0 inputSelectOut">
							<div class="searchBoxIn">
								<div class="feildCol serchBox countryFlags">
									<span id="conactNumberPopover" (click)="doHideShowContactNumberPopover()" class="selectTip">
										<div class="flag-container"
											*ngIf="signUpUI.dialcode_contact_number && signUpUI.dialcode_contact_number.code">
											<div class="iti-flag {{signUpUI.dialcode_contact_number.icon}}"></div>
										</div>
									</span>
									<dx-popover target="#conactNumberPopover" position="top" [width]="550"
										[(visible)]="isHideShowContactNumberPopover">
										<div *dxTemplate="let data = model; of: 'content'">
											<div class="row">
												<div class="cols12">
													<dx-data-grid #authphonedialcodeListDatagridRef [dataSource]="listOfDialcodes"
														keyExpr="id" [selection]="{ mode: 'single' }" [hoverStateEnabled]="true"
														[paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: true }"
														[scrolling]="{ mode: 'virtual' }" [height]="345"
														[selectedRowKeys]="[cndialCodeId]" [allowColumnResizing]="false"
														[columnResizingMode]="'nextColumn'" [columnAutoWidth]="true"
														[showColumnLines]="true" [showRowLines]="false"
														(onSelectionChanged)="doSelectContactNumber($event)">
														<dxi-column dataField="id" [visible]="false"></dxi-column>
														<dxi-column dataField="name" [visible]="true" [allowHeaderFiltering]="false"
															caption="Name" cellTemplate="cellTemplateName">
															<div *dxTemplate="let element of 'cellTemplateName'">
																<div class="flag-container">
																	<div class="iti-flag {{element.data.icon}}"></div>
																	<span class="country-name" [innerHtml]="element.data.name"></span>
																</div>
															</div>
														</dxi-column>
														<dxi-column dataField="code" [visible]="true" [allowHeaderFiltering]="false"
															caption="Code" cellTemplate="cellTemplateCode" width="80">
															<div *dxTemplate="let element of 'cellTemplateCode'">
																<div class="flag-container">
																	<span class="dial-code">+{{element.data.code}}</span>
																</div>
															</div>
														</dxi-column>
													</dx-data-grid>
												</div>
											</div>
										</div>
									</dx-popover>
								</div>
							</div>
							<div class="dx-field mrgB20">
								<div class="dx-field-label">Contact Number</div>
								<div class="dx-field-value">
									<dx-text-box mode="text" [(value)]="signUpUI.contact_number" [showClearButton]="true"
										[maxLength]="20" name="contactNumber" class="phoneNumberField">
									</dx-text-box>
								</div>
							</div>
						</div>
						<div class="cols6">
							<div class="feildCol">
								<dx-text-box mode="text" [(value)]="signUpUI.address1" [showClearButton]="true" maxlength="200">
									<dx-validator>
										<dxi-validation-rule type="required" message="Address 1 is required">
										</dxi-validation-rule>
									</dx-validator>
								</dx-text-box>
								<div class="inputField">
									<label for="address1">Address 1</label>
								</div>
							</div>
						</div>
						<div class="cols6">
							<div class="feildCol">
								<dx-text-box mode="text" [(value)]="signUpUI.address2" [showClearButton]="true" maxlength="200">
								</dx-text-box>
								<div class="inputField">
									<label for="address1">Address 2</label>
								</div>
							</div>
						</div>

						<div class="cols6">
							<div class="feildCol">
								<dx-select-box #Country [dataSource]="listOfCountries" [(value)]="signUpUI.country_name"
									displayExpr="name" valueExpr="name" [showClearButton]="true"
									placeholder="-- Select Country --"
									(valueChange)="onChangeCountry();fetchedListOfStates(signUpUI.country_name);">
									<dx-validator>
										<dxi-validation-rule type="required" message="Country is required">
										</dxi-validation-rule>
									</dx-validator>
								</dx-select-box>
								<div class="inputField">
									<label for="name">Country</label>
								</div>
							</div>
						</div>
						<div class="cols6">
							<div class="feildCol">
								<dx-text-box #inputState
									*ngIf="signUpUI.country_name != 'United States' && signUpUI.country_name != 'Canada'"
									mode="text" [(value)]="signUpUI.state" [showClearButton]="true">
									<dx-validator>
										<dxi-validation-rule type="required" message="State is required">
										</dxi-validation-rule>
									</dx-validator>
								</dx-text-box>
								<dx-select-box #selectState
									*ngIf="signUpUI.country_name == 'United States' || signUpUI.country_name == 'Canada'"
									[dataSource]="listOfStates" [(value)]="signUpUI.state" displayExpr="name" valueExpr="name"
									[showClearButton]="true" placeholder="State/Province" (valueChange)="onChangeState();">
									<dx-validator>
										<dxi-validation-rule type="required" message="State is required">
										</dxi-validation-rule>
									</dx-validator>
								</dx-select-box>
								<div class="inputField">
									<label for="name">State</label>
								</div>
							</div>
						</div>

						<div class="cols6">
							<div class="feildCol">
								<dx-text-box mode="text" [(value)]="signUpUI.city" [showClearButton]="true" maxlength="50">
									<dx-validator>
										<dxi-validation-rule type="required" message="City is required">
										</dxi-validation-rule>
									</dx-validator>
								</dx-text-box>
								<div class="inputField">
									<label for="city">City</label>
								</div>
							</div>
						</div>
						<div class="cols6">
							<div class="feildCol">
								<dx-text-box mode="text" [(value)]="signUpUI.zip" [showClearButton]="true" [maxLength]="10"
									(onValueChanged)="onBlurZipcode($event)">
									<dx-validator>
										<dxi-validation-rule type="required" message="Zip/Postal Code is required">
										</dxi-validation-rule>
									</dx-validator>
								</dx-text-box>
								<div class="inputField">
									<label for="zipCode">Zip/Postal Code</label>
								</div>
							</div>
						</div>

					</div>
					<div class="hrLine mrgT10"></div>
					<div class="row mrgB0">
						<div class="cols6">
							<div class="feildCol">
								<dx-text-box mode="text" [(value)]="signUpUI.name_on_card" [showClearButton]="true"
									maxlength="100">
									<dx-validator>
										<dxi-validation-rule type="required" message="Name on Card is required">
										</dxi-validation-rule>
									</dx-validator>
								</dx-text-box>
								<div class="inputField">
									<label for="cardName">Name on Card</label>
								</div>
							</div>
						</div>
						<div class="cols6">
							<div class="feildCol relative">
								<dx-number-box [min]="0" [(value)]="signUpUI.card_number" [showClearButton]="true"
									maxlength="30" (onValueChanged)="onKeyUpGetCardType($event)" [ngClass]="{'cardVisaImages':signUpUI.card_type === 'visa', 'cardMasterImages':signUpUI.card_type === 'mastercard',
							'cardAmericanExpressImages':signUpUI.card_type === 'amex', 'cardDiscoverImages':signUpUI.card_type === 'discover',
							'cardJCBImages':signUpUI.card_type === 'jcb'}">
									<dx-validator>
										<dxi-validation-rule type="required" message="Card Number is required">
										</dxi-validation-rule>
									</dx-validator>
								</dx-number-box>
								<div class="inputField">
									<label for="cardNumber">Card Number</label>
								</div>
							</div>
						</div>
						<div class="cols6 colsP0">
							<div class="cols6">
								<div class="feildCol">
									<dx-select-box [dataSource]="expiremonthList" [(value)]="signUpUI.expire_month_number"
										displayExpr="text" valueExpr="value">
									</dx-select-box>
									<div class="inputField">
										<label for="name">Month</label>
									</div>
								</div>
							</div>
							<div class="cols6">
								<div class="feildCol">
									<dx-select-box [dataSource]="listOfYears" [(value)]="signUpUI.expire_year_number"
										displayExpr="yl" valueExpr="yl">
									</dx-select-box>
									<div class="inputField">
										<label for="name">Year</label>
									</div>
								</div>
							</div>
						</div>
						<div class="cols6">
							<div class="feildCol relative">
								<dx-text-box mode="text" [(value)]="signUpUI.cvv_number" [showClearButton]="true"
									[maxLength]="4" (onValueChanged)="isCVVNumber($event)">
									<dx-validator (onValidated)="onCVVValidated($event)">
										<dxi-validation-rule type="required" message="CVC Code is required">
										</dxi-validation-rule>
									</dx-validator>
								</dx-text-box>
								<span id="cvvInfoDetails" [ngClass]="{'mrgR25':isCVVCls,'mrgR35':signUpUI.cvv_number}"
									class="icon-cvvcodeicon cvvinputadd">
									<span class="path1"></span>
									<span class="path2"></span>
									<span class="path3"></span>
									<span class="path4"></span>
									<span class="path5"></span>
								</span>
								<div class="inputField">
									<label for="cvcCode">CVC Code</label>
								</div>
								<dx-tooltip target="#cvvInfoDetails" showEvent="dxhoverstart" hideEvent="dxhoverend"
									position="right">
									<div *dxTemplate="let d = d; of: 'content'">
										<div class="cvvInfo">
											<p>Your card code is a 3 or 4 digit number that is found in these locations</p>
											<div class="row">
												<div class="cols6">
													<img [src]="_AppCommonSrvc.cdnImgPath + 'visa-cvv.jpg'" alt="Visa Card"
														title="" />
													<h4>Visa & Mastercard</h4>
													<p>The 3 digits are located on the back of the card, to the right of the
														signature
														strip.</p>
												</div>
												<div class="cols6">
													<img [src]="_AppCommonSrvc.cdnImgPath + 'amex-cvv.jpg'" alt="Amex Card"
														title="" />
													<h4>AMERICAN EXPRESS</h4>
													<p>The 4 digits of the cw are located
														on the front side of the card
														.</p>
												</div>
											</div>
										</div>
									</div>
								</dx-tooltip>
							</div>
						</div>
						<div class="cols12">
							<div class="checkBox">
								<span>
									<dx-check-box [(value)]="signUpUI.istermsandcondition"
										(onValueChanged)="onChangeTermsAndPolicies($event)">
									</dx-check-box>
									<label for="termsandcondition" class="mrgL10">&nbsp;</label>
								</span>
								I agree to the
								<a (click)="doOpenTermsAndPolicies()">IncentX Terms and Policies</a>
							</div>
						</div>
						<div class="cols12 mrgT20">
							<form [formGroup]="signUpFormGroup">
								<ngx-recaptcha2 #captchaElem [siteKey]="googleRecaptchaSiteKey" (reset)="doCaptchaReset()"
									(expire)="doCaptchaExpired()" (load)="doCaptchaLoaded()" (success)="doCaptchaSuccess($event)"
									[useGlobalDomain]="false" [size]="'normal'" [hl]="'en'" [theme]="'light'" [type]="'image'"
									formControlName="recaptcha">
								</ngx-recaptcha2>
							</form>
						</div>

						<div class="cols12 mrgT20">
							<div class="feildCol">
								<dx-button class="dxSubmitBtn" id="button" [disabled]="isDisableRegistration"
									text="Pay and Register" type="normal" stylingMode="contained" [useSubmitBehavior]="true">
								</dx-button>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div *ngIf="signUpUI.selSubscriptionPlanDetail && signUpUI.selSubscriptionPlanDetail.features"
				class="cols4 signUpRightSec">
				<div class="packageCart"
					*ngIf="(signUpUI.license_categories && signUpUI.license_categories.length > 0) || (signUpUI.setup_package_options && signUpUI.setup_package_options.length <=0)">
					<div class="packageStatus">
						<h4 *ngIf="salesRepName">
							Your sales contact is: <span style="text-transform: capitalize;"
								class="salerName blueColor">{{salesRepName}}</span>
						</h4>
					</div>
					<h3>Package Details</h3>
					<ul>
						<li *ngFor="let lc of signUpUI.license_categories">
							<h3 [innerHTML]="lc.name"></h3>
							<h4>
								<span class="blueColor">
									{{lc.total_price_per_month || 0 | currency:'USD'}} /
								</span>
								per {{lc.payment_type === 'annual' ? 'annum' : 'month'}} | {{lc.no_of_licenses}} {{
								lc.license_type === 'bucket' ? 'Bucket' : 'User'}}
							</h4>
							<a (click)="doShowFeatures(lc,'license_category')" class="cartInfoIcon">
								<span id="info" class="iconCartInfo sprite"></span>
							</a>
						</li>

						<li *ngFor="let spo of signUpUI.setup_package_options">
							<h3 [innerHTML]="spo.name"></h3>
							<h4><span class="blueColor">{{spo.base_price || 0 | currency:'USD'}} /</span> up to
								{{spo.days_included}}
								days</h4>
							<a (click)="doShowFeatures(spo,'setup_package_option')" class="cartInfoIcon">
								<span id="info" class="iconCartInfo sprite"></span>
							</a>
						</li>

						<li *ngIf="signUpUI.order_summary" class="packageSubTotal">
							<div class="cf">
								<span class="blueColor">
									{{signUpUI.order_summary.subtotal || 0 | currency:'USD'}}
								</span>
								Subtotal
							</div>
							<div>
								<span class="priceCheck blueColor">
									<span *ngIf="isTaxResponse">
										{{signUpUI.order_summary.totaltax || 0 | currency:'USD'}}
									</span>
									<span *ngIf="!isTaxResponse" class="loadingPrice custSpinner"></span>
								</span>
								Total Tax
							</div>
							<div
								*ngIf="(!signUpUI.zip || (signUpUI.zip && signUpUI.zip.length <= 4)) && signUpUI.order_summary.totaltax <= 0"
								class="zipCodeText">
								Please enter a State and ZIP code to calculate Tax
							</div>
							<div *ngIf="!isTaxResponse" class="zipCodeText">
								Calculating tax it may take while please wait
							</div>
						</li>
						<li class="noBdr totalCoast">
							<h3>Total Cost:</h3>
							<h4>
								<span class="blueColor">
									{{signUpUI.order_summary.totalcost || 0 | currency:'USD'}}
								</span>
							</h4>
							<div (click)="doModifyPackage()" class="modifyPackage">
								Your card will be charged <a>Modify Package</a>
							</div>
						</li>
					</ul>

					<div class="contQuestion">
						<h4>Get started with incentX Have questions?</h4>
						<h5>Get in touch at </h5>
						<div class="relative">
							<img [src]="_AppCommonSrvc.cdnImgPath + 'call-icon.png'" alt="" title="" />
							<a> 833-INCENTX (462-3689) </a>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>


<div *ngIf="isLoaderPopupDisplay" class="popOuter transparentPop">
	<div class="popCnt" style="width:430px;">
		<div class="popBox cf">
			<div class="animatedLogo">
				<div class="incentexAnim">
					<div [ngClass]="{'currentState': isActiveStepName === 'firststep' || isActiveStepName === 'secondstep'}"
						class="savingData ">
						<img [src]="_AppCommonSrvc.cdnImgPath + 'X_logo.png'" alt="Incentex" title="Incentex" />
					</div>
					<div [ngClass]="{'currentState': isActiveStepName === 'thirdstep'}" class="paySuccess">
						<span class="icon-successfully_signed" title="Incentex">
							<span class="path1"></span>
							<span class="path2"></span>
						</span>
					</div>
					<div [ngClass]="{'currentState': isActiveStepName === 'zerostep' || isActiveStepName === 'fourthstep'}"
						class="payAlert">
						<span class="icon-alert_popup" title="Incentex">
							<span class="path1"></span>
							<span class="path2"></span>
						</span>
					</div>
				</div>
				<div [ngClass]="{'activeStep':isActiveStepName === 'firststep'}" class="firstStep ">
					<h3 class="animatedText">
						<span>Saving SignUp Data</span>
					</h3>
					<p>It may take a while, please wait...</p>
				</div>
				<div [ngClass]="{'activeStep':isActiveStepName === 'secondstep'}" class=" secondStep">
					<h3 class="animatedText">
						<span>Validating Payment information</span>
					</h3>
					<p>It may take a while, please wait...</p>
				</div>
				<div [ngClass]="{'activeStep':isActiveStepName === 'thirdstep'}" class=" thirdStep">
					<h3 class="animatedText">
						<span>Signed Up Successfully</span>
					</h3>
					<p>Thank you for choosing incentX. Please check your email for activation</p>
					<div class="btnCols fullBtn mrgT10">
						<input (click)="doLoginNow()" name="loginBtn" type="button" class="button" value="Login Now">
					</div>
				</div>
				<div [ngClass]="{'activeStep':isActiveStepName === 'zerostep'}" class=" thirdStep">
					<h3 class="animatedText">
						<span>Alert</span>
					</h3>
					<p>{{errorMessage || ''}}</p>
					<div class="btnCols fullBtn mrgT10">
						<input (click)="doCancelNow()" name="loginBtn" type="button" class="button" value="Close">
					</div>
				</div>
				<div [ngClass]="{'activeStep':isActiveStepName === 'fourthstep'}" class=" thirdStep">
					<h3 class="animatedText">
						<span>Alert</span>
					</h3>
					<p>{{errorMessage || ''}}</p>
					<div class="btnCols fullBtn mrgT10">
						<input (click)="doLoginNow()" name="loginBtn" type="button" class="button" value="Login Now">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-show-license-features-dialog *ngIf="isShowLicenseFeaturesPopup"
	[showLicenseFeaturesProps]="showLicenseFeaturesProps"
	(closeShowLicenseFeaturesPopup)="doCloseShowLicenseFeaturesPopup($event)"></app-show-license-features-dialog>

<app-terms-and-conditions-dialog *ngIf="isShowTermsAndConditionsPopup"
	[termsAndConditionsProps]="termsAndConditionsProps"
	(closeTermsAndConditionsPopup)="doCloseTermsAndConditionsPopup($event)"></app-terms-and-conditions-dialog>