import { MainPipesModule } from './pipes/main-pipes.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { TwoFactorAuthenticationComponent } from './auth/two-factor-authentication/two-factor-authentication.component';
import { PagesModule } from './pages/pages.module';
import { ResharedModule } from './reshared/reshared.module';

import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxPermissionsModule } from 'ngx-permissions';
import { UserIdleModule } from 'angular-user-idle';

import { CustomComponentsModule } from '@app/custom-components/custom-components.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ForgotPasswordExtendedComponent } from './auth/forgot-password-extended/forgot-password-extended.component';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { ApSubscriptionPlansService } from '@app/services/ap-services/ap-subscription-plans-service/ap-subscription-plans.service';
import { ReportsServiceService } from '@app/services/reports-service/reports-service.service';
import { AuthorizePaymentService } from '@app/services/authorize-payment/authorize-payment.service';
import { AuthorizePaymentDataService } from '@app/services/authorize-payment/authorize-payment-data.service';
import { CrmsService } from '@app/services/crms-service/crms-service.service';
import { AppStateService } from '@app/services/app-state/app-state.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { SuccessInterceptorService } from '@app/services/rest-interceptor/success-interceptor.service';
import { ErrorInterceptorService } from '@app/services/rest-interceptor/error-interceptor.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { RestApiCallService } from '@app/services/rest-api-call/rest-api-call.service';
import { SalesDataSharingService } from '@app/services/sales-service/sales-data-sharing.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { DynamicScriptLoaderService } from '@app/services/dynamic-script-loader/dynamic-script-loader.service';
import { BusinessPlanDataSharingService } from '@app/services/business-plan-service/business-plan-data-sharing.service';
import { AuthGuardService } from '@app/services/auth-guard.service';
import { LoginService } from '@app/services/login.service';
import { DataService } from '@app/services/data.service';
import { ApCustomersService } from '@app/services/ap-services/ap-customers-service/ap-customers.service';
import { UserManagementService } from '@app/services/user-management-service/user-management-service.service';
import { UserProfileService } from '@app/services/userprofile-service/userprofile-service.service';
import { DashboardService } from './services/dashboard-service/dashboard.service';
import { VerificationEmailComponent } from './auth/verification-email/verification-email.component';
import { SignupWithTrialComponent } from './auth/signupwithtrial/signupwithtrial.component';
import { ApUserManagementService } from './services/ap-services/ap-user-management-service/ap-user-management-service.service';
import { PurchasesService } from './services/purchases-service/purchases.service';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CardNoxPaymentService } from './services/card-nox/card-nox-payment.service';
import { DxButtonModule, DxCheckBoxModule, DxDateBoxModule, DxDropDownBoxModule, DxNumberBoxModule, DxRadioGroupModule, DxSelectBoxModule, DxTagBoxModule, DxTextBoxModule, DxTooltipModule, DxValidationSummaryModule, DxValidatorModule, DxLoadPanelModule, DxMultiViewModule, DxPopoverModule, DxDataGridModule, DxLoadIndicatorModule, DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import { NgDynamicBreadcrumbModule, NgDynamicBreadcrumbService } from "ng-dynamic-breadcrumb";
import { AdminportalModule } from './adminportal/adminportal.module';
import { ApSalesService } from './services/ap-services/ap-sales-service/ap-sales.service';
import { NgxFileDropModule } from 'ngx-file-drop';
import { IxDxAlertService } from './services/ix-dx-alert-services/ix-dx-alert-services';
import { ApApplicationConfigsService } from './services/ap-services/ap-application-configs-service/ap-application-configs-service.service';

import config from 'devextreme/core/config';
import { licenseKey } from './../../devextreme-license';
import { ApplicationConfigurationsService } from './services/application-configurations-service/application-configurations-service.service';
config({ licenseKey });

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		SignupComponent,
		ForgotPasswordComponent,
		ForgotPasswordExtendedComponent,
		TwoFactorAuthenticationComponent,
		AuthComponent,
		VerificationEmailComponent,
		SignupWithTrialComponent,
	],
	imports: [
		Ng2SearchPipeModule,
		BrowserModule,
		PagesModule,
		AdminportalModule,
		ResharedModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		// NoopAnimationsModule, // Angular Material Animation On after put this line to comment
		MatNativeDateModule,
		MatFormFieldModule,
		MatInputModule,
		MatProgressSpinnerModule,
		MatDialogModule,
		MatMenuModule,
		MatIconModule,
		MatTooltipModule,
		MainPipesModule,
		CustomComponentsModule,
		DxTooltipModule,
		DxDateBoxModule,
		DxCheckBoxModule,
		DxDropDownBoxModule,
		DxTagBoxModule,
		DxSelectBoxModule,
		DxTextBoxModule,
		DxButtonModule,
		DxValidatorModule,
		DxValidationSummaryModule,
		DxRadioGroupModule,
		DxNumberBoxModule,
		DxLoadPanelModule,
		DxMultiViewModule,
		DxPopupModule,
		DxScrollViewModule,
		NgxFileDropModule,
		DxPopoverModule,
		DxDataGridModule,
		DxLoadIndicatorModule,
		ToastrModule.forRoot({
			newestOnTop: true,
			timeOut: 2000,
			positionClass: 'toast-top-right',
			preventDuplicates: true,
			resetTimeoutOnDuplicate: true
		}),
		NgxPermissionsModule.forRoot(),
		// Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
		// Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes)
		// and `ping` is 120 (2 minutes).
		UserIdleModule.forRoot({ idle: 600, timeout: 122, ping: 60 }),
		// UserIdleModule.forRoot({ idle: 60, timeout: 22, ping:30 }),
		NgxCaptchaModule,
		NgDynamicBreadcrumbModule,
	],
	entryComponents: [
		ForgotPasswordComponent
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: SuccessInterceptorService, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		AuthGuardService,
		LoginService,
		DataService,
		GlobalStateService,
		AppStateService,
		BusinessPlanDataSharingService,
		DynamicScriptLoaderService,
		SalesDataSharingService,
		RestApiCallService,
		RestApiService,
		LoaderService,
		DashboardService,
		CrmsService,
		ApCustomersService,
		ApSubscriptionPlansService,
		ApUserManagementService,
		ReportsServiceService,
		AuthorizePaymentService,
		AuthorizePaymentDataService,
		AppCommonSrvc,
		UserManagementService,
		UserProfileService,
		PurchasesService,
		CardNoxPaymentService,
		NgDynamicBreadcrumbService,
		ApSalesService,
		IxDxAlertService,
		ApApplicationConfigsService,
		ApplicationConfigurationsService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(public _AppStateService: AppStateService) {
	}
}
