import { Injectable } from '@angular/core';
import { IMG_ADD_FILE_SVG, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX, ServerSections } from '@app/constants-enums/constants';
import { RebateApprovalStatusLblEnum, RebateApprovalStatusValEnum, RebateSettlementStatusLblEnum, RebateSettlementStatusValEnum, SettlementPeriodsLblEnum, SettlementPeriodsValEnum } from '@app/constants-enums/enums';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class RebateService {
	public editRebatesGuid: string;
	public invoiceDetails: any;
	private buyinggroupDetails: any;
	private actionFrom: string;
	constructor(
		public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
	) { }

	getEditRebatesGuid() {
		return this.editRebatesGuid;
	}
	setEditRebatesGuid(val: any) {
		this.editRebatesGuid = val;
	}
	getInvoiceDetails() {
		return this.invoiceDetails;
	}
	setInvoiceDetails(val: any) {
		this.invoiceDetails = val;
	}

	getBuyingGroupDetails() {
		return this.buyinggroupDetails;
	}
	setBuyingGroupDetails(val: any) {
		this.buyinggroupDetails = val;
	}

	public getlistOfUomByItemDetails(listOfUoms: any[], listOfUomGroups: any[], itemDetails: any, priceListNum?: string) {
		let listOfSpecificUOM = [];
		if (itemDetails) {
			if (priceListNum) {
				if (itemDetails.itemprices && itemDetails.itemprices.length > 0) {
					const itemPriceData = itemDetails.itemprices.filter(itemPrice => itemPrice.pricelist.toString().toLowerCase() === priceListNum.toString().toLowerCase());
					if (itemPriceData && itemPriceData.length > 0) {
						const itemPriceEle = itemPriceData[0];
						if (itemPriceEle.uomprices && itemPriceEle.uomprices.length > 0) {
							itemPriceEle.uomprices.forEach(itemUP => {
								if (itemUP.uomentry) {
									const uomIndex = listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUP.uomentry.toString().toLowerCase());
									if (uomIndex !== -1) {
										const newItemUomPriceClone = JSON.parse(JSON.stringify(itemUP));
										newItemUomPriceClone.absentry = listOfUoms[uomIndex].absentry;
										newItemUomPriceClone.code = listOfUoms[uomIndex].code;
										newItemUomPriceClone.name = listOfUoms[uomIndex].name;
										newItemUomPriceClone.price = itemUP.price || 0;
										listOfSpecificUOM.push(newItemUomPriceClone);
									}
								}
							});
						}
					}
				} else {
					listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(listOfUoms, listOfUomGroups, itemDetails);
				}
			} else {
				listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(listOfUoms, listOfUomGroups, itemDetails);
			}
		}
		return listOfSpecificUOM;
	}

	private setDefaultUomByUomGroupEntry(listOfUoms, listOfUomGroups, itemDetails) {
		const listOfSpecificUOM = [];
		const uomGroupLst = listOfUomGroups || [];
		if (itemDetails.uomgroupentry) {
			const uomGroupIndex = uomGroupLst.findIndex(itemUomGroup => itemUomGroup.absentry.toString().toLowerCase() === itemDetails.uomgroupentry.toString().toLowerCase());
			if (uomGroupIndex !== -1) {
				const uomGroupDefinitionCollection = uomGroupLst[uomGroupIndex].uomgroupdefinitioncollection || [];
				let newItemUomPice: any = {};
				uomGroupDefinitionCollection.forEach(itemUomGroupDefinitionCollection => {
					newItemUomPice = {};
					newItemUomPice = JSON.parse(JSON.stringify(itemUomGroupDefinitionCollection));
					const uomIndex = listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUomGroupDefinitionCollection.alternateuom.toString().toLowerCase());
					if (uomIndex !== -1) {
						newItemUomPice.absentry = listOfUoms[uomIndex].absentry;
						newItemUomPice.code = listOfUoms[uomIndex].code;
						newItemUomPice.name = listOfUoms[uomIndex].name;
						newItemUomPice.price = 0;
						listOfSpecificUOM.push(newItemUomPice);
					}
				});
			}
		}
		return listOfSpecificUOM;
	}

	public getTierBasis() {
		const tierBasis = [
			{
				code: 'volume',
				label: 'Volume'
			},
			{
				code: 'revenue',
				label: 'Revenue'
			},
			{
				code: 'notiers',
				label: 'Standard'
			}
		];
		return tierBasis;
	}

	public getTierBasisForClasses() {
		const tierBasis = [
			{
				code: 'revenue',
				label: 'Revenue'
			},
			{
				code: 'notiers',
				label: 'Standard'
			}
		];
		return tierBasis;
	}
	public getRebateBasis() {
		const rebateBasis = [
			{
				code: 'dollar',
				label: this._LoginService.globalCurrencySymbol
			},
			{
				code: 'percentage',
				label: '%'
			},
			{
				code: 'dollarperuom',
				label: this._LoginService.globalCurrencySymbol + ' / UOM'
			}
		];
		return rebateBasis;
	}

	public getRebateBasisForClasses() {
		const rebateBasis = [
			{
				code: 'dollar',
				label: this._LoginService.globalCurrencySymbol
			},
			{
				code: 'percentage',
				label: '%'
			}
		];
		return rebateBasis;
	}
	getMonth() {
		return [
			{ value: '1', text: 'Jan' },
			{ value: '2', text: 'Feb' },
			{ value: '3', text: 'Mar' },
			{ value: '4', text: 'Apr' },
			{ value: '5', text: 'May' },
			{ value: '6', text: 'Jun' },
			{ value: '7', text: 'Jul' },
			{ value: '8', text: 'Aug' },
			{ value: '9', text: 'Sep' },
			{ value: '10', text: 'Oct' },
			{ value: '11', text: 'Nov' },
			{ value: '12', text: 'Dec' },
			{ value: '13', text: 'Jan' },
		];
	}

	setActionFrom(val: string) {
		this.actionFrom = val;
	}
	getActionFrom() {
		return this.actionFrom;
	}

	//Load State Data
	public loadStateData() {
		let newSection = null;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.REBATE_CREDIT_MEMO_lIST_SETTING)));
		} catch (e) {
			newSection = null;
		}
		const rebatesCraditMemoListConfigClone = newSection;
		if (!rebatesCraditMemoListConfigClone.configs) {
			rebatesCraditMemoListConfigClone.configs = [];
		} else {
			return rebatesCraditMemoListConfigClone.configs || null;
		}

	}

	public getListOfActionsForDatagrid() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'IMPORT',
				btn_name: 'Import',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}


	get getListOfRebateType() {
		return [
			{ value: 'purchasing', text: 'Purchasing' },
			{ value: 'sales', text: 'Sales' },
		];
	}

	get getlistOfRebateFor() {
		return [
			{ value: 'individual', text: 'Individual' },
			{ value: 'buyinggroup', text: 'Buying Group' },
			{ value: 'growth_incentive', text: 'Growth Incentive' },
			{ value: 'distribution', text: 'Distribution' },
			{ value: 'distribution_differential', text: 'Distribution Differential' },
		];
	}

	get getListOfSettlementPeriods() {
		return [
			{ text: SettlementPeriodsLblEnum.AUTOPAYMENT, value: SettlementPeriodsValEnum.AUTOPAYMENT },
			{ text: SettlementPeriodsLblEnum.MONTHLY, value: SettlementPeriodsValEnum.MONTHLY },
			{ text: SettlementPeriodsLblEnum.QUARTERLY, value: SettlementPeriodsValEnum.QUARTERLY },
			{ text: SettlementPeriodsLblEnum.HALFYEARLY, value: SettlementPeriodsValEnum.HALFYEARLY },
			{ text: SettlementPeriodsLblEnum.YEARLY, value: SettlementPeriodsValEnum.YEARLY },
			{ text: SettlementPeriodsLblEnum.CUSTOM, value: SettlementPeriodsValEnum.CUSTOM },
		]
	}

	get getListOfPaymentTypes() {
		return [
			{ text: 'Buying Group', value: 'buyinggroup' },
			{ text: 'Buying Group Member', value: 'buyinggroupmember' },
			{ text: 'Split', value: 'split' },
		]
	}

	get getListOfSettlementStatuses() {
		return [
			{ value: RebateSettlementStatusValEnum.OPEN, text: RebateSettlementStatusLblEnum.OPEN, classname: 'purple', color_code: '706cce' },
			{ value: RebateSettlementStatusValEnum.PROCESSING, text: RebateSettlementStatusLblEnum.PROCESSING, classname: 'orange', color_code: 'f99300' },
			{ value: RebateSettlementStatusValEnum.SUCCESS, text: RebateSettlementStatusLblEnum.SUCCESS, classname: 'green', color_code: '05c1a0' },
			{ value: RebateSettlementStatusValEnum.ERROR, text: RebateSettlementStatusLblEnum.ERROR, classname: 'red', color_code: 'ff4545' },
			{ value: RebateSettlementStatusValEnum.FAILED, text: RebateSettlementStatusLblEnum.FAILED, classname: 'red', color_code: 'ff4545' }
		]
	}

	get getListOfApprovalStatuses() {
		return [
			{ value: RebateApprovalStatusValEnum.DRAFT, text: RebateApprovalStatusLblEnum.DRAFT, classname: 'defaultClr', color_code: '21b3c6' },
			{ value: RebateApprovalStatusValEnum.PENDING_APPROVAL, text: RebateApprovalStatusLblEnum.PENDING_APPROVAL, classname: 'orange', color_code: 'f99300' },
			{ value: RebateApprovalStatusValEnum.APPROVED, text: RebateApprovalStatusLblEnum.APPROVED, classname: 'green', color_code: '05c1a0' },
			{ value: RebateApprovalStatusValEnum.REJECTED, text: RebateApprovalStatusLblEnum.REJECTED, classname: 'red', color_code: 'ff4545' }
		]
	}

	get getListOfRuleBonusTypes() {
		return [
			{ text: 'Inclusion/Exclusion Rule', value: 'inclusionExclusionRule' },
			{ text: 'Bonus Rate', value: 'bonusRate' },
			{ text: 'Rate Rule', value: 'rateRule' },
		];
	}

	get getListOfActionsForApprovalTrails() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public doGenerateSettlementPeriodStartAndEndDate(rebatesContractClone: any) {
		let e, s, d;
		let ssPeriodDay: any = 1; //start settlement period on
		let cc = rebatesContractClone.contractCalendar; // contract calendar [Fiscal Year,Calendar Year,Contract Year]
		let f: any = 1;// Fiscal Year month as of application configuration
		ssPeriodDay = rebatesContractClone.startSettlementPeriodOn ? parseInt(rebatesContractClone.startSettlementPeriodOn.toString()) : 1;

		cc = rebatesContractClone.contractCalendar;
		if (cc === 'fiscal_year') {
			f = this._LoginService.loginUser.account_detail.fiscal_year_start || 1;
		}
		// no previous closed settlement periods
		rebatesContractClone.settlementPeriodStart = rebatesContractClone.startDate;
		s = rebatesContractClone.startDate.split('-');

		if (cc === 'contract_year') {
			f = parseInt(s[1].toString()) || 1;
		}
		const sm = parseInt(s[1].toString()); // start date month
		if (rebatesContractClone.settlementPeriod === SettlementPeriodsValEnum.MONTHLY) {
			// tslint:disable-next-line: max-line-length
			e = new Date(s[0], s[1], 1);  // end date is first day of the next month, and JavaScript months start at 0, month 12 interpreted as Jan of next yr
		} else if (rebatesContractClone.settlementPeriod === SettlementPeriodsValEnum.QUARTERLY) {
			d = sm - ((f - 1) % 3); // adjust for fiscal year start
			if (d < 0) {
				d = 0;
				// d = d + 12;   // months before fiscal year start are as if at end of year
			}
			d = d - (d % 3) + (d % 3 ? 3 : 0);   // end of current quarter
			d = d + ((f - 1) % 3);    // re-adjust for fiscal year start
			// e = new Date(s[0], s[1] - (s[1]%3) + (s[1]%3?3:0), 1);  //end of current quarter, measured from fiscal year start
			e = new Date(s[0], d, ssPeriodDay);

		} else if (rebatesContractClone.settlementPeriod === SettlementPeriodsValEnum.HALFYEARLY) {
			d = sm - ((f - 1) % 6); // adjust for fiscal year start
			if (d < 0) {
				d = 0;
				// d = d + 12;   // months before fiscal year start are as if at end of year
			}
			d = d - (d % 6) + (d % 6 ? 6 : 0);   // end of current quarter
			d = d + ((f - 1) % 6);    // re-adjust for fiscal year start
			// e = new Date(s[0], s[1] - (s[1]%3) + (s[1]%3?3:0), 1);  //end of current quarter, measured from fiscal year start
			e = new Date(s[0], d, ssPeriodDay);

		} else if (rebatesContractClone.settlementPeriod === SettlementPeriodsValEnum.YEARLY) {
			// tslint:disable-next-line: radix
			if (cc === 'fiscal_year') {
				d = sm - ((f - 1) % 12); // adjust for fiscal year start
				if (d < 0) {
					d = 0;
					// d = d + 12;   // months before fiscal year start are as if at end of year
				}
				d = d - (d % 12) + (d % 12 ? 12 : 0);   // end of current quarter
				d = d + ((f - 1) % 12);    // re-adjust for fiscal year start
				// e = new Date(s[0], s[1] - (s[1]%3) + (s[1]%3?3:0), 1);  //end of current quarter, measured from fiscal year start
				e = new Date(s[0], d, ssPeriodDay);
			} else {
				e = new Date(parseInt(s[0]) + 1, f - 1, ssPeriodDay);
			}
		} else if (rebatesContractClone.settlementPeriod === SettlementPeriodsValEnum.CUSTOM) {
			rebatesContractClone.settlementPeriodEnd = rebatesContractClone.endDate;
		}
		try {
			e.setDate(e.getDate() - 1); // last day of the prev month
			const endDate = rebatesContractClone.endDate ? moment(rebatesContractClone.endDate, "YYYY-MM-DD") : null;
			const settlementPeriodEnd = moment(e, "YYYY-MM-DD");
			const diffDays = settlementPeriodEnd.diff(endDate, 'days');
			if (diffDays >= 0) {
				rebatesContractClone.settlementPeriodEnd = rebatesContractClone.endDate;
			} else {
				rebatesContractClone.settlementPeriodEnd = moment(e).format('YYYY-MM-DD');
			}
		} catch (e) {
		}
		return {
			settlementPeriodStart: rebatesContractClone.settlementPeriodStart,
			settlementPeriodEnd: rebatesContractClone.settlementPeriodEnd
		};
	}

	public getListOfActionsForReportDatagrid() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	get getListOfMemberModes() {
		return [
			{ text: 'Single Member', value: 'single_member', disabled: false, visible: true },
			{ text: 'Multiple Member', value: 'multiple_member', disabled: false, visible: true }
		];
	}
}
