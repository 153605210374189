import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import * as _ from 'underscore';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { AuthorizePaymentService } from '@app/services/authorize-payment/authorize-payment.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { MSG_ERROR_MESSAGE, RGX_EMAIL_VALID, RGX_REPLACE_NUMBER_ONLY, ServerMethods } from '@app/constants-enums/constants';
import { SignUpUI, SignUpRequest, LineItems, CalculateTaxRates, UpdateUserTierDetailsUI } from '@app/models/signup.model';
import * as $ from 'jquery';
import { environment } from '@environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
	selector: 'app-signup-with-trial',
	templateUrl: './signupwithtrial.component.html',
	styleUrls: ['./signupwithtrial.component.css']
})
export class SignupWithTrialComponent implements OnInit, OnDestroy {
	// #region for Angular LifeCycle
	public signUpUI = new SignUpUI();
	public listOfDialcodes: any[] = [];
	public listOfPreferredDialcodes: any[] = [];
	public listOfCountries: any[] = [];
	public listOfDisposableEmails: any[] = [];
	public listOfStates: any[] = [];
	public listOfYears: any[] = [];
	public orderSummary: any[] = [];
	public searchDialCodeText: string = '';
	private preferredDialcodes = ['us', 'ca'];
	private queryString: string;
	private listOfSubscriptionPlan: any[] = [];
	private selOfSubscriptionPlanDetails: any;
	private dialCodeSubscription: Subscription;
	private countrySubscription: Subscription;
	private disposableEmailSubscription: Subscription;
	private stateSubscription: Subscription;
	private tierSubscription: Subscription;
	private calculateTaxRatesSubscription: Subscription;
	private termsAndConditionsSubscription: Subscription;
	private originQueryString: any;
	private taxChangedTimout: any;
	private termsAndConditionsResponse: any;
	private listOfInvoices: any[] = [];
	private listOfInvoicesClone: any[] = [];
	private licenseCategoriesIndex: number;
	private setupPackageOptionsIndex: number;
	private updateUserTierDetailsUI: UpdateUserTierDetailsUI = new UpdateUserTierDetailsUI();
	private isSuccessfulyPaymentDone: boolean = false;
	public isLoaderPopupDisplay: boolean = false;
	public isActiveStepName: string;
	public errorMessage: string;
	public isTaxResponse: boolean = true;
	private repQueryParam: string;
	public salesRepName: string;
	public googleRecaptchaSiteKey: string = environment.GOOGLE_RECAPTCHA_SITE_KEY;
	public signUpFormGroup: FormGroup;
	public passwordshow = ' icon-view-pwd';
	public passwordhide = ' icon-hide-pwd';
	public passwordMode = 'password';
	public passwordButton = {
		icon: this.passwordshow,
		type: "normal",
		onClick: () => {
			this.onClickpasswordButtonOption();
		}
	};
	public onClickpasswordButtonOption() {
		if (this.passwordButton.icon == this.passwordshow) {
			this.passwordButton = {
				icon: this.passwordhide,
				type: "normal",
				onClick: () => {
					this.onClickpasswordButtonOption();
				}
			}
		}
		else {
			this.passwordButton = {
				icon: this.passwordshow,
				type: "normal",
				onClick: () => {
					this.onClickpasswordButtonOption();
				}
			}
		}
		this.passwordMode = this.passwordMode === "text" ? "password" : "text";
	}
	public expiremonthList: any[] = [];
	public isDisableRegistration: boolean = true;
	public dialCodeId: string = 'us';
	public cndialCodeId: string = 'us';
	public isHideShowAuthPhoneNumberPopover = false;
	public isHideShowContactNumberPopover = false;
	@ViewChild('authphonedialcodeListDatagridRef') authphonedialcodeListDatagridRef: DxDataGridComponent;
	@ViewChild('contactNumberdialcodeListDatagridRef') contactNumberdialcodeListDatagridRef: DxDataGridComponent;
	public isShowLicenseFeaturesPopup: boolean;
	public showLicenseFeaturesProps: any;
	public isShowTermsAndConditionsPopup: boolean;
	public termsAndConditionsProps: any;

	constructor(private _Router: Router,
		private _ActivatedRoute: ActivatedRoute,
		private _LoaderService: LoaderService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _AuthorizePaymentService: AuthorizePaymentService,
		public _AppCommonSrvc: AppCommonSrvc,
		private _GlobalStateService: GlobalStateService,
		private _FormBuilder: FormBuilder) {
		this._ActivatedRoute.queryParams.subscribe(params => {
			this.parseQueryString(params);
		});
	}

	ngOnInit() {
		this.signUpFormGroup = this._FormBuilder.group({
			recaptcha: ['', Validators.required]
		});

		this.getListOfMonth();
		this.fetchedListOfDialCode();
		this.fetchedListOfCountry();
		this.fetchedListOfStates('United States');
		this.fetchedListOfYears();
		this.fetchedListOfTiers();
		this.fetchedListOfDisposableEmail();
		this.signUpUI.istermsandcondition = false;
		this.signUpUI.license_categories = [];
		this.signUpUI.setup_package_options = [];
		this.signUpUI.card_type = '';
		this.signUpUI.termsandcondition = 'pending';
		this.signUpUI.state = '';

		this._GlobalStateService.subscribe('SET_COUNTRY_DATA_EVENT', response => {
			if (response) {
				if (response.isDefault) {
					this.setCountryData();
				} else {
					this.setCountryData(response.country_name);
				}
			}
		});
		this._GlobalStateService.subscribe('SET_STATE_DATA_EVENT', response => {
			if (response) {
				if (response.isDefault) {
					this.setStateData();
				} else {
					this.setStateData(response.state_name);
				}
			}
		});
		this._GlobalStateService.subscribe('TAX_CHANGER_NOTIFIER_EVENT', response => {
			if (response) {
				if (this.taxChangedTimout) {
					clearTimeout(this.taxChangedTimout);
				}
				this.taxChangedTimout = setTimeout(() => {
					this.callReqForCalculateTax();
				}, 500);
			}
		});
		this._GlobalStateService.subscribe('SHOW_TERMS_AND_POLICIES_EVENT', response => {
			this.doOpenTermsAndPolicyDialoge();
		});
	}

	ngOnDestroy() {
		if (this.dialCodeSubscription) {
			this.dialCodeSubscription.unsubscribe();
		}
		if (this.countrySubscription) {
			this.countrySubscription.unsubscribe();
		}
		if (this.stateSubscription) {
			this.stateSubscription.unsubscribe();
		}
		if (this.tierSubscription) {
			this.tierSubscription.unsubscribe();
		}
		if (this.calculateTaxRatesSubscription) {
			this.calculateTaxRatesSubscription.unsubscribe();
		}
		if (this.termsAndConditionsSubscription) {
			this.termsAndConditionsSubscription.unsubscribe();
		}
		if (this.disposableEmailSubscription) {
			this.disposableEmailSubscription.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('SET_COUNTRY_DATA_EVENT');
		this._GlobalStateService.unsubscribe('SET_STATE_DATA_EVENT');
		this._GlobalStateService.unsubscribe('TAX_CHANGER_NOTIFIER_EVENT');
		this._GlobalStateService.unsubscribe('SHOW_TERMS_AND_POLICIES_EVENT');
	}
	// #endregion

	// #region for SignUp Process
	// For Click To Save User Setup process
	public doSignUp(e) {
		if (!this.checkValidation()) {
			return false;
		}

		const signUpRequest = new SignUpRequest();
		signUpRequest.company_name = this.signUpUI.company_name;
		signUpRequest.firstname = this.signUpUI.firstname;
		signUpRequest.lastname = this.signUpUI.lastname;
		signUpRequest.email = this.signUpUI.email.toString().trim().toLowerCase();
		signUpRequest.password = this.signUpUI.password;
		signUpRequest.mobile = this.signUpUI.mobile;
		signUpRequest.isd_code = this.signUpUI.dialcode_phone.code;
		signUpRequest.country_code = this.signUpUI.dialcode_phone.id;
		signUpRequest.contact_number = this.signUpUI.contact_number || '';
		signUpRequest.dialcode = this.signUpUI.dialcode_contact_number.id + '#' + this.signUpUI.dialcode_contact_number.code;
		signUpRequest.address1 = this.signUpUI.address1 || '';
		signUpRequest.address2 = this.signUpUI.address2 || '';
		signUpRequest.country_name = this.signUpUI.country_name || '';
		signUpRequest.state = this.signUpUI.state || '';
		signUpRequest.city = this.signUpUI.city || '';
		signUpRequest.zip = this.signUpUI.zip || '';
		signUpRequest.is_trial = true;
		signUpRequest.termsandcondition = 'pending';
		signUpRequest.method = ServerMethods.SIGN_UP;
		signUpRequest.startdate = moment(new Date()).format('YYYY-MM-DD');
		signUpRequest.no_of_days = 14;
		signUpRequest.rep = this.repQueryParam;
		if (this.signUpUI.istermsandcondition) {
			signUpRequest.termsandcondition = 'verified';
			signUpRequest.tac_version = this.termsAndConditionsResponse.terms_version;
		}
		// signUpRequest.tier_guid = this.signUpUI.selSubscriptionPlanDetail.guid;
		// const license_categories = _.map(
		// 	_.where(this.signUpUI.license_categories, {}), function (lc) {
		// 		return { guid: lc.guid, no_of_licenses: lc.no_of_licenses };
		// 	});
		// const setup_package_options = _.map(
		// 	_.where(this.signUpUI.setup_package_options, {}), function (lc) {
		// 		return { guid: lc.guid, no_of_extra_days: 0 };
		// 	});
		// signUpRequest.license_categories = JSON.stringify(license_categories);
		// signUpRequest.setup_package_options = JSON.stringify(setup_package_options);
		const formData = new FormData();
		for (const property in signUpRequest) {
			formData.append(property, signUpRequest[property]);
		}
		this.isLoaderPopupDisplay = true;
		this.isActiveStepName = 'firststep';
		this._RestApiService.doUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					this.isActiveStepName = 'thirdstep';
				} else {
					this.isActiveStepName = 'zerostep';
					this.errorMessage = response.message || MSG_ERROR_MESSAGE;
					return false;
				}
			}, error: error => {
				this.isActiveStepName = 'zerostep';
				this.errorMessage = MSG_ERROR_MESSAGE;
			}
		});
		e.preventDefault();
	}

	// For Check Validation for Email Address Domain
	// Excluded from list Of Disposable Emails
	private validateDomain(email): boolean {
		let isValidate = true;
		const idx1 = email.indexOf("@");
		if (idx1 > -1) {
			const splitStr = email.split("@");
			if (this.listOfDisposableEmails.some(e => e.Domain === splitStr[1])) {
				isValidate = false;
			}
		} else {
			isValidate = false;
		}
		return isValidate;
	}

	// For Check Validation for SignUp Form
	private checkValidation(): boolean {
		if (!this.signUpUI.company_name || (this.signUpUI.company_name && !this.signUpUI.company_name.trim())) {
			this._ToastrService.info('Account Name is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (!this.signUpUI.firstname || (this.signUpUI.firstname && !this.signUpUI.firstname.trim())) {
			this._ToastrService.info('First Name is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (!this.signUpUI.lastname || (this.signUpUI.lastname && !this.signUpUI.lastname.trim())) {
			this._ToastrService.info('Last Name is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (!this.signUpUI.email || (this.signUpUI.email && !this.signUpUI.email.trim())) {
			this._ToastrService.info('Email is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (this.signUpUI.email) {
			if (!this.signUpUI.email.match(RGX_EMAIL_VALID)) {
				this._ToastrService.info('Email is Invalid.', 'Info', { closeButton: true, tapToDismiss: true });
				return false;
			}
		}
		if (!this.validateDomain(this.signUpUI.email)) {
			this._ToastrService.error('Please use an email address with a company domain to register for incentX.', 'Error', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (!this.signUpUI.password || (this.signUpUI.password && !this.signUpUI.password.trim())) {
			this._ToastrService.info('Password is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (!this.signUpUI.address1 || (this.signUpUI.address1 && !this.signUpUI.address1.trim())) {
			this._ToastrService.info('Address 1 is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (!this.signUpUI.mobile || (this.signUpUI.mobile && !this.signUpUI.mobile.trim())) {
			this._ToastrService.info('Authorization Phone Number is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (!this.signUpUI.country_name || (this.signUpUI.country_name && !this.signUpUI.country_name.trim())) {
			this._ToastrService.info('Country is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (!this.signUpUI.state || (this.signUpUI.state && !this.signUpUI.state.trim())) {
			this._ToastrService.info('State is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (!this.signUpUI.city || (this.signUpUI.city && !this.signUpUI.city.trim())) {
			this._ToastrService.info('City is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (!this.signUpUI.zip || (this.signUpUI.zip && !this.signUpUI.zip.toString().trim())) {
			this._ToastrService.info('Zip/Postal Code is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (this.signUpUI.zip.toString().trim().length > 10) {
			this._ToastrService.info('Zip/Postal Code cannot be longer than 10 characters', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		// Credit/Debit Card Payment Information

		if (!this.signUpUI.istermsandcondition) {
			this._ToastrService.info('Terms and Policy is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}

		if (!this.termsAndConditionsResponse || (this.termsAndConditionsResponse && !this.termsAndConditionsResponse.terms_version)) {
			this._ToastrService.info('Please Read Terms and Policy.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}

		if (!this.signUpUI.isCaptchaSuccess) {
			this._ToastrService.info('The reCaptcha is invalid.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}

		if (this.signUpUI.license_categories && this.signUpUI.license_categories.length <= 0) {
			this._ToastrService.info('Packages is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}

		//can_be_admin is atleast true in any selected license categories
		if (this.signUpUI.license_categories && this.signUpUI.license_categories.length >= 0) {
			let is_can_be_admin = false;
			this.signUpUI.license_categories.forEach(lcItem => {
				lcItem.user_types.forEach(utItem => {
					if (utItem.can_be_admin) {
						is_can_be_admin = true;
					}
				});
			});
			if (!is_can_be_admin) {
				this._ToastrService.info('Please select at least one license of a user type which can be an admin user.', 'Info', { closeButton: true, tapToDismiss: true });
				return false;
			}
		}

		return true;
	}

	// #region for Authorized .NET Functionality

	public doLoginNow() {
		this.isLoaderPopupDisplay = false;
		this._Router.navigate(['/auth/login'], { replaceUrl: true });
	}

	public doCancelNow() {
		this.isLoaderPopupDisplay = false;
	}

	// #endregion

	// #endregion

	// #region For Terms and Policies

	// For Click on Checkbox for Terms and Policies
	public onChangeTermsAndPolicies(event) {
		this.signUpUI.istermsandcondition = false;
		if (event.value) {
			this.fetchedTermsAndConditions();
			setTimeout(() => {
				//  this.signUpUI.istermsandcondition = false;
				event.value = false;
			}, 10);
		}
	}
	// public onChangeTermsAndPolicies(event) {
	// 	if (event.target.checked) {
	// 		this.fetchedTermsAndConditions();
	// 		setTimeout(() => {
	// 			this.signUpUI.istermsandcondition = false;
	// 			event.target.checked = false;
	// 		}, 10);
	// 	}
	// }

	// For Fetch new Terms and Policies and Open Dialog
	public doOpenTermsAndPolicies() {
		this.fetchedTermsAndConditions();
	}

	// For Fetch latest Terms and Condition
	private fetchedTermsAndConditions() {
		const formData = new FormData();
		formData.append('method', ServerMethods.GET_TERMS_AND_CONDITIONS);
		if (this.termsAndConditionsSubscription) {
			this.termsAndConditionsSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.termsAndConditionsSubscription = this._RestApiService.doUserSetUpReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response && response.flag) {
					if (response.data && response.data) {
						this.termsAndConditionsResponse = response.data;
						this._GlobalStateService.notifyDataChangedDuplicate('SHOW_TERMS_AND_POLICIES_EVENT', new Date().getTime());
					}
				}
			}, error: error => {
				this._LoaderService.hide();
			}
		});
	}

	// For Dialog Open for Terms and Policies
	private doOpenTermsAndPolicyDialoge() {
		this.termsAndConditionsProps = {
			termsAndConditionsResponse: this.termsAndConditionsResponse,
			istermsandcondition: (this.signUpUI.istermsandcondition) ? true : false
		};
		this.doOpenTermsAndConditionsPopup();
	}

	private doOpenTermsAndConditionsPopup() {
		this.isShowTermsAndConditionsPopup = false;
		setTimeout(() => {
			this.isShowTermsAndConditionsPopup = true;
		}, 200);
	}

	public doCloseTermsAndConditionsPopup(event: any) {
		if (event.isClickOnCloseBtn) {
			this.isShowTermsAndConditionsPopup = false;
			return;
		}
		this.signUpUI.istermsandcondition = event.istermsandcondition;
		this.isShowTermsAndConditionsPopup = false;
	}

	// #endregion

	// #region for Public methods
	public preventClick(event) {
		event.stopPropagation();
	}

	// For Get Type of Credit Card like Mastercard, Visa
	public onKeyUpGetCardType() {
		if (this.signUpUI.card_number) {
			let cardType = this._AuthorizePaymentService.getCardType(this.signUpUI.card_number).toString().toLowerCase();
			if (cardType) {
				cardType = cardType.toString().toLowerCase();
			}
			this.signUpUI.card_type = cardType;
		}
	}

	// For Fetched List of Years.
	public fetchedListOfYears() {
		const year = new Date().getFullYear();
		const range = [];
		range.push({ 'yl': year });
		for (let i = 1; i < 10; i++) {
			range.push({ 'yl': year + i });
		}
		this.signUpUI.expire_month_number = "01";
		this.listOfYears = range;
		this.signUpUI.expire_year_number = this.listOfYears[0].yl;
	}


	// To Get List of Subscription Plan (Tier)
	public fetchedListOfTiers() {
		if (this.tierSubscription) {
			this.tierSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.tierSubscription = this._RestApiService.doDataOutSignUpReq('').subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response && response.flag) {
					if (response.data && response.data.length > 0) {
						this.listOfSubscriptionPlan = response.data;
						this.setPlansAndPackageDetails();
					}
				} else {
					this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// For Redirect User to incentx.com for Modify package
	public doModifyPackage() {
		window.location.replace(environment.URL_WEBSITE_PRICING_PAGE + this.originQueryString || '');
	}

	// For Click To Select Dialcode for Phone fields
	public onClickDialcodePhone(dialCodeData) {
		this.signUpUI.dialcode_phone = dialCodeData;
	}

	// For Click To Select Dialcode for ContactNumber fields
	public onClickDialcodeContactNumber(dialCodeData) {
		this.signUpUI.dialcode_contact_number = dialCodeData;
	}
	// Fetch list of month
	private getListOfMonth() {
		var i;
		for (i = 0; i < 12; i++) {
			var month = i + 1;
			if (month < 10) {
				month = '0' + month;
			}
			this.expiremonthList.push({ text: month, value: month });
		}
	}
	// For Fetched List of Dialcodes
	private fetchedListOfDialCode() {
		if (this.dialCodeSubscription) {
			this.dialCodeSubscription.unsubscribe();
		}

		this.dialCodeSubscription = this._RestApiService.doPhoneCodeReq().subscribe({
			next: (res: any) => {
				if (res && res.length > 0) {
					this.listOfDialcodes = res;
					const preferredCountries = [];
					this.preferredDialcodes.forEach(country => {
						const preferredCountryIndex = res.findIndex(countryItem => countryItem.id === country);
						preferredCountries.push(res[preferredCountryIndex]);
					});
					this.listOfPreferredDialcodes = preferredCountries;
					const selectedDialCodeData = res.findIndex(countryItem => countryItem.id === 'us');
					this.signUpUI.dialcode_phone = res[selectedDialCodeData];
					this.signUpUI.dialcode_contact_number = res[selectedDialCodeData];
				}
			}, error: error => { }
		});
	}

	// #endregion

	// For Fetched List of Disposable Email
	private fetchedListOfDisposableEmail() {
		const formData = new FormData();
		formData.append('method', ServerMethods.GET_DISPOSABLE_EMAIL);
		if (this.disposableEmailSubscription) {
			this.disposableEmailSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.disposableEmailSubscription = this._RestApiService.doUserSetUpReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfDisposableEmails = response.data.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// #region for Country Related Logic
	// For Fetched List of Countries
	private fetchedListOfCountry() {
		if (this.countrySubscription) {
			this.countrySubscription.unsubscribe();
		}
		this.countrySubscription = this._RestApiService.doGetContryReq().subscribe({
			next: response => {
				this.listOfCountries = response;
				this._GlobalStateService.notifyDataChangedDuplicate('SET_COUNTRY_DATA_EVENT', { isDefault: true });
			},
			error: error => {
			}
		});
	}

	// Set Country Data when country changed and initially load
	private setCountryData(country_name?: string) {
		const defaultCountryName = 'united states';
		let selCountryName = '';
		if (country_name) {
			selCountryName = country_name;
		} else {
			selCountryName = defaultCountryName;
		}
		const countryIndex = this.listOfCountries.findIndex(c => c.name.toString().toLowerCase() === selCountryName.toString().toLowerCase());
		if (countryIndex !== -1) {
			this.signUpUI.country_name = this.listOfCountries[countryIndex].name;
			this.signUpUI.sel_country_data = this.listOfCountries[countryIndex];
		}
	}

	// For Change Event for Country
	public onChangeCountry() {
		this._GlobalStateService.notifyDataChangedDuplicate('SET_COUNTRY_DATA_EVENT', { isDefault: false, country_name: this.signUpUI.country_name });
		this.signUpUI.order_summary.license_categories_taxtotal = 0;
		this.signUpUI.order_summary.setup_package_options_taxtotal = 0;
		this.calculateFinalCost();

		this.signUpUI.sel_state_data = {};
		// this.signUpUI.zip = '';
		this._GlobalStateService.notifyDataChanged('TAX_CHANGER_NOTIFIER_EVENT', new Date().getTime());

	}
	// #endregion

	// #region for State Related Logic
	// For Set State Data by Changed event
	private setStateData(state_name?: string) {
		let selStateName = '';
		if (state_name) {
			selStateName = state_name;
		}
		this.signUpUI.sel_state_data = {};
		const stateIndex = this.listOfStates.findIndex(c => c.name.toString().toLowerCase() === selStateName.toString().toLowerCase());
		if (stateIndex !== -1) {
			this.signUpUI.sel_state_data = this.listOfStates[stateIndex];
		}
	}

	// For Change Event for State
	public onChangeState() {
		this.signUpUI.sel_state_data = {};
		this._GlobalStateService.notifyDataChangedDuplicate('SET_STATE_DATA_EVENT', { isDefault: false, state_name: this.signUpUI.state });
		this.signUpUI.order_summary.license_categories_taxtotal = 0;
		this.signUpUI.order_summary.setup_package_options_taxtotal = 0;
		this.calculateFinalCost();
		if (!this.signUpUI.state || (this.signUpUI.state && !this.signUpUI.state.trim())) {
			return false;
		}
		this._GlobalStateService.notifyDataChanged('TAX_CHANGER_NOTIFIER_EVENT', new Date().getTime());

	}

	// For Fetched List of States.
	public fetchedListOfStates(countryname) {
		if (countryname === 'United States' || countryname === 'Canada') {
			if (this.stateSubscription) {
				this.stateSubscription.unsubscribe();
			}
			this.signUpUI.sel_state_data = {};
			this.stateSubscription = this._RestApiService.doGetStateReq().subscribe({
				next: response => {
					if (countryname === 'United States') {
						this.listOfStates = response[0].states;
					} else {
						this.listOfStates = response[1].states;
					}
				}, error: error => {
					this.signUpUI.state = '';
				}
			});
		} else {
			this.signUpUI.state = '';
		}
	}
	// #endregion

	// #region for Zipcode Related Logic
	public onBlurZipcode(e) {
		this.isZipNumber(e)
		this.signUpUI.order_summary.license_categories_taxtotal = 0;
		this.signUpUI.order_summary.setup_package_options_taxtotal = 0;
		this.calculateFinalCost();
		if (!this.signUpUI.zip || (this.signUpUI.zip && !this.signUpUI.zip.toString().trim())) {
			return;
		}
		if (this.signUpUI.zip.toString().trim().length <= 4) {
			return;
		}
		this._GlobalStateService.notifyDataChanged('TAX_CHANGER_NOTIFIER_EVENT', new Date().getTime());
	}
	// #endregion

	// #region  for Initialize Query Parser and Set Plans and Packages
	// For Parsing QueryString to Array.
	private parseQueryString(params) {
		this.signUpUI.selSubscriptionPlanDetail = {
			guid: '',
			license_categories: [],
			setup_package_options: []
		};
		let queryString = undefined;
		try {
			queryString = JSON.parse(JSON.stringify(params));
		} catch (error) {
			queryString = undefined;
		}
		this.originQueryString = '';
		if (queryString) {
			if (queryString.p) {
				const subscriptionPlanParams = params.p || '';
				this.originQueryString = this.originQueryString + ((this.originQueryString) ? '&' : '?') + 'p=' + subscriptionPlanParams;
				this.signUpUI.selSubscriptionPlanDetail.guid = subscriptionPlanParams || '';
			} else {
				this.isDisableRegistration = true;
			}

			if (queryString.l) {
				const licenseCategoriesParams = params.l || '';
				this.originQueryString = this.originQueryString + ((this.originQueryString) ? '&' : '') + 'l=' + licenseCategoriesParams;
				const listOfLicenseCategories = [];
				const licenseCategoriesSplitted = licenseCategoriesParams.split('|');
				if (licenseCategoriesSplitted && licenseCategoriesSplitted.length > 0) {
					licenseCategoriesSplitted.forEach(itemEle => {
						const itemSplitted = itemEle.split('-');
						if (itemSplitted && itemSplitted.length > 0) {
							const no_of_licenses = $.isNumeric(itemSplitted[1]) ? Math.floor(+itemSplitted[1]) : 1;
							listOfLicenseCategories.push({ guid: itemSplitted[0], no_of_licenses: no_of_licenses });
						}
					});
				}
				this.signUpUI.selSubscriptionPlanDetail.license_categories = listOfLicenseCategories;
			} else {
				this.isDisableRegistration = true;
			}

			if (queryString.sc) {
				const setupPackageOptionsParams = params.sc || '';
				this.originQueryString = this.originQueryString + ((this.originQueryString) ? '&' : '') + 'sc=' + setupPackageOptionsParams;
				const listOfSetupPackageOptions = [];
				const setupPackageOptionsSplitted = setupPackageOptionsParams.split('|');
				if (setupPackageOptionsSplitted && setupPackageOptionsSplitted.length > 0) {
					setupPackageOptionsSplitted.forEach(itemEle => {
						listOfSetupPackageOptions.push({ guid: itemEle });
					});
				}
				this.signUpUI.selSubscriptionPlanDetail.setup_package_options = listOfSetupPackageOptions;
			}

			this.signUpUI.is_trial = true;
			if (queryString.is_trial) {
				this.originQueryString = this.originQueryString + ((this.originQueryString) ? '&' : '') + 'is_trial=' + queryString.is_trial;
				if (queryString.is_trial.toString().toLowerCase() === '1') {
					this.signUpUI.is_trial = true;
				} else {
					this.signUpUI.is_trial = true;
				}
				this.isDisableRegistration = true;
			}

			if (queryString.rep) {
				this.originQueryString = this.originQueryString + ((this.originQueryString) ? '&' : '') + 'rep=' + queryString.rep;
				this.repQueryParam = queryString.rep;
				this.salesRepName = '';
				try {
					const repSplit = queryString.rep.split('_').join(' ');
					this.salesRepName = repSplit.toString().toLowerCase();
				} catch (error) {
					this.salesRepName = '';
				}
			}
		}
	}

	// For Set Plan Details and Order Summary After query parse
	public setPlansAndPackageDetails() {
		this.signUpUI.license_categories = [];
		this.signUpUI.setup_package_options = [];
		if (this.signUpUI.selSubscriptionPlanDetail && this.signUpUI.selSubscriptionPlanDetail.guid) {
			const subscriptionPlanIndex = this.listOfSubscriptionPlan.findIndex(itemSP => itemSP.guid === this.signUpUI.selSubscriptionPlanDetail.guid);
			if (subscriptionPlanIndex !== -1) {
				this.selOfSubscriptionPlanDetails = JSON.parse(JSON.stringify(this.listOfSubscriptionPlan[subscriptionPlanIndex]));
				this.signUpUI.selSubscriptionPlanDetail.features = this.selOfSubscriptionPlanDetails.features;
				this.signUpUI.selSubscriptionPlanDetail.currancy = this.selOfSubscriptionPlanDetails.currancy;
				this.signUpUI.selSubscriptionPlanDetail.tier_name = this.selOfSubscriptionPlanDetails.tier_name;
				// Set list of license_categories
				this.signUpUI.selSubscriptionPlanDetail.license_categories.forEach((selItem, i) => {
					const licenseCategoriesIndex = this.selOfSubscriptionPlanDetails.license_categories.findIndex(spdItem => spdItem.guid === selItem.guid);
					if (licenseCategoriesIndex !== -1) {
						const licenseCategoriesItem = JSON.parse(JSON.stringify(this.selOfSubscriptionPlanDetails.license_categories[licenseCategoriesIndex]));
						selItem.subscriptionplanguid = this.selOfSubscriptionPlanDetails.guid;
						selItem.user_types = licenseCategoriesItem.user_types;
						selItem.features = licenseCategoriesItem.features;
						selItem.name = licenseCategoriesItem.name;
						selItem.price_per_license = licenseCategoriesItem.price_per_license;
						selItem.license_type = licenseCategoriesItem.license_type;
						selItem.total_price_per_month = licenseCategoriesItem.price_per_license * selItem.no_of_licenses;
						if (licenseCategoriesItem.license_type.toString().toLowerCase() === 'bucket') {
							selItem.licenses_per_bucket = licenseCategoriesItem.licenses_per_bucket;
							selItem.no_of_bucket = licenseCategoriesItem.no_of_bucket;
						}
						this.signUpUI.license_categories.push(selItem);
					}
				});
				// Set list of setup_package_options
				this.signUpUI.selSubscriptionPlanDetail.setup_package_options.forEach((selItem, i) => {
					const setupPackageOptionsIndex = this.selOfSubscriptionPlanDetails.setup_package_options.findIndex(spdItem => spdItem.guid === selItem.guid);
					if (setupPackageOptionsIndex !== -1) {
						const setupPackageOptionsItem = this.selOfSubscriptionPlanDetails.setup_package_options[setupPackageOptionsIndex];
						selItem.subscriptionplanguid = this.selOfSubscriptionPlanDetails.guid;
						selItem.name = setupPackageOptionsItem.name;
						selItem.base_price = setupPackageOptionsItem.base_price;
						selItem.days_included = setupPackageOptionsItem.days_included;
						selItem.features = setupPackageOptionsItem.features;
						this.signUpUI.setup_package_options.push(selItem);
					}
				});
				if (this.signUpUI.license_categories && this.signUpUI.license_categories.length > 0) {
					this.isDisableRegistration = false;
				} else {
					this.isDisableRegistration = true;
				}
				this.calculateOrderSummary();
				this.calculateFinalCost();
			}
		}
	}
	// #endregion

	// #region For Calculations for Licenses Categores and Setup Package Options

	// For Calculate Subtotal of License Category and Setup Package Options
	private calculateOrderSummary() {
		let license_categories_subtotal = 0;
		this.signUpUI.license_categories.forEach(element => {
			license_categories_subtotal = license_categories_subtotal + parseFloat(element.total_price_per_month);
		});
		let setup_package_options_subtotal = 0;
		this.signUpUI.setup_package_options.forEach(element => {
			setup_package_options_subtotal = setup_package_options_subtotal + parseFloat(element.base_price);
		});
		this.signUpUI.order_summary.license_categories_subtotal = license_categories_subtotal;
		this.signUpUI.order_summary.license_categories_taxtotal = 0;
		this.signUpUI.order_summary.setup_package_options_subtotal = setup_package_options_subtotal;
		this.signUpUI.order_summary.setup_package_options_taxtotal = 0;
	}

	// For Calculate Total Cost and Total Tax
	private calculateFinalCost() {
		// For Final Subtotal including license category and setup package options
		const subtotal = +this.signUpUI.order_summary.license_categories_subtotal + +this.signUpUI.order_summary.setup_package_options_subtotal;
		this.signUpUI.order_summary.subtotal = subtotal;

		// For Final Subtotal including Tax
		const taxtotal = +this.signUpUI.order_summary.license_categories_taxtotal + +this.signUpUI.order_summary.setup_package_options_taxtotal;
		this.signUpUI.order_summary.totaltax = taxtotal;

		// For Setup_Package_Options Total including tax
		const totalcostLicenseCategories = +this.signUpUI.order_summary.license_categories_subtotal + +this.signUpUI.order_summary.license_categories_taxtotal;
		this.signUpUI.order_summary.license_categories_totalcost = totalcostLicenseCategories;

		// For Setup_Package_Options Total including tax
		const totalcostSetupPackageOptions = +this.signUpUI.order_summary.setup_package_options_subtotal + +this.signUpUI.order_summary.setup_package_options_taxtotal;
		this.signUpUI.order_summary.setup_package_options_totalcost = totalcostSetupPackageOptions;

		this.signUpUI.order_summary.totalcost = subtotal + taxtotal;
	}

	// Fetched for calculate tax by changing Countries, State, Zip
	private callReqForCalculateTax() {
		this.signUpUI.order_summary.license_categories_taxtotal = 0;
		this.signUpUI.order_summary.setup_package_options_taxtotal = 0;
		this.calculateFinalCost();

		if (!this.signUpUI.state || (this.signUpUI.state && !this.signUpUI.state.trim())) {
			return false;
		}
		if (!this.signUpUI.zip || (this.signUpUI.zip && !this.signUpUI.zip.toString().trim())) {
			return false;
		}
		const calculateTaxRates = new CalculateTaxRates();
		calculateTaxRates.to_country = (Object.keys(this.signUpUI.sel_country_data).length > 0) ? this.signUpUI.sel_country_data.code : this.signUpUI.country_name;
		calculateTaxRates.to_state = (Object.keys(this.signUpUI.sel_state_data).length > 0) ? this.signUpUI.sel_state_data.abbreviation : this.signUpUI.state || '';
		calculateTaxRates.to_zip = (this.signUpUI.zip) ? this.signUpUI.zip.toString().trim() : '';
		calculateTaxRates.amount = +this.signUpUI.order_summary.license_categories_subtotal + +this.signUpUI.order_summary.setup_package_options_subtotal;
		calculateTaxRates.line_items = [];
		this.signUpUI.license_categories.forEach(lc => {
			const lineItems = new LineItems();
			lineItems.product_tax_code = 'license_categories';
			lineItems.quantity = lc.no_of_licenses;
			lineItems.unit_price = lc.price_per_license;
			calculateTaxRates.line_items.push(lineItems);
		});
		this.signUpUI.setup_package_options.forEach(spo => {
			const lineItems = new LineItems();
			lineItems.product_tax_code = 'setup_package_options';
			lineItems.quantity = 1;
			lineItems.unit_price = spo.base_price;
			calculateTaxRates.line_items.push(lineItems);
		});
		const formData = new FormData();
		formData.append('method', ServerMethods.CALCULATE_TAX_RATES);
		formData.append('package_value', JSON.stringify(calculateTaxRates));
		this.isTaxResponse = false;
		if (this.calculateTaxRatesSubscription) {
			this.calculateTaxRatesSubscription.unsubscribe();
		}
		this.calculateTaxRatesSubscription = this._RestApiService.doUserSetUpReqFormData(formData).subscribe({
			next: response => {
				this.isTaxResponse = true;
				if (response) {
					if (response.flag) {
						if (response.data && response.data.length > 0) {
							if (response.data[0].is_error <= 0) {
								const listCalculatedOfTaxes = response.data[0].result_response || [];
								this.setCalculatedTax(listCalculatedOfTaxes);
							}
						}
					} else {
						this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this.isTaxResponse = true;
			}
		});
	}

	// For Set Tax value for Licenses and Setup Package Options.
	private setCalculatedTax(listCalculatedOfTaxes: any[]) {
		// Set Calculated License Category Tax
		const licenseCategoriesTaxIndex = listCalculatedOfTaxes.findIndex(itemEle =>
			itemEle.product_tax_code === 'license_categories');
		if (licenseCategoriesTaxIndex !== -1) {
			this.signUpUI.order_summary.license_categories_taxtotal = parseFloat(listCalculatedOfTaxes[licenseCategoriesTaxIndex].product_tax_total);
		}

		// Set Calculated Setup Package Options Tax
		const setupPackageOptionsTaxIndex = listCalculatedOfTaxes.findIndex(itemEle =>
			itemEle.product_tax_code === 'setup_package_options');
		if (setupPackageOptionsTaxIndex !== -1) {
			this.signUpUI.order_summary.setup_package_options_taxtotal = parseFloat(listCalculatedOfTaxes[setupPackageOptionsTaxIndex].product_tax_total);
		}
		this.calculateFinalCost();
	}
	// #endregion

	// Method for ToFixed Decimal number without rounding.
	private toFixedWithoutRounding(valueNum: number, decimalNum: number = 2) {
		const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (decimalNum || -1) + '})?');
		return +valueNum.toString().match(re)[0];
	}

	// #region for Show Feature

	// Method used to display Features of Licenses Categories
	public doShowFeatures(selectedPackage: any, selectedType: string) {
		this.showLicenseFeaturesProps = {
			selectedPackageType: selectedType,
			selectedPackage: selectedPackage,
		};
		this.doOpenShowLicenseFeaturesPopup();
	}

	private doOpenShowLicenseFeaturesPopup() {
		this.isShowLicenseFeaturesPopup = false;
		setTimeout(() => {
			this.isShowLicenseFeaturesPopup = true;
		}, 200);
	}

	public doCloseShowLicenseFeaturesPopup(event: any) {
		if (event.isClickOnCloseBtn) {
			this.isShowLicenseFeaturesPopup = false;
			return;
		}
		this.isShowLicenseFeaturesPopup = false;
	}
	// #endregion

	// #region for Google Recaptcha methods

	public doCaptchaReset() {
		this.signUpUI.isCaptchaSuccess = false;
	}

	public doCaptchaExpired() {
		this.signUpUI.isCaptchaSuccess = false;
	}

	public doCaptchaLoaded() {
		this.signUpUI.isCaptchaSuccess = false;
	}

	public doCaptchaSuccess(e) {
		if (e) {
			this.signUpUI.isCaptchaSuccess = true;
		} else {
			this.signUpUI.isCaptchaSuccess = false;
		}
	}
	// #endregion

	//#region validation

	//Check text must be  number only
	public isZipNumber(e) {
		if (e.event) {
			const keyCode = e.event.keyCode;
			let zipCode = e.value || '';
			if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) && keyCode !== 86) {
				if (zipCode) {
					zipCode = zipCode.toString().replace(RGX_REPLACE_NUMBER_ONLY, '');
					setTimeout(() => {
						this.signUpUI.zip = zipCode;
					});
					return;
				}
			}
			if (keyCode === 86) {
				zipCode = zipCode.toString().replace(RGX_REPLACE_NUMBER_ONLY, '');
				setTimeout(() => {
					this.signUpUI.zip = zipCode;
				});
			}
			if (zipCode && zipCode.toString().trim().length === 6) {
				this.signUpUI.zip = zipCode;
			}
		}
	}
	public isCVVNumber(e) {
		if (e.event) {
			const keyCode = e.event.keyCode;
			let cvvNumber = e.value || '';
			if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) && keyCode !== 86) {
				if (cvvNumber) {
					cvvNumber = cvvNumber.toString().replace(RGX_REPLACE_NUMBER_ONLY, '');
					setTimeout(() => {
						this.signUpUI.cvv_number = cvvNumber;
					});
					return;
				}
			}
			if (keyCode === 86) {
				cvvNumber = cvvNumber.toString().replace(RGX_REPLACE_NUMBER_ONLY, '');
				setTimeout(() => {
					this.signUpUI.cvv_number = cvvNumber;
				});
			}
			if (cvvNumber && cvvNumber.toString().trim().length === 6) {
				this.signUpUI.cvv_number = cvvNumber;
			}
		}
	}
	//#endregion

	public doHideShowAuthPhoneNumberPopover() {
		setTimeout(() => {
			if (this.authphonedialcodeListDatagridRef) {
				this.authphonedialcodeListDatagridRef.instance.clearFilter();
			}
		}, 600);
		this.isHideShowAuthPhoneNumberPopover = !this.isHideShowAuthPhoneNumberPopover;
	}
	public doHideShowContactNumberPopover() {
		setTimeout(() => {
			if (this.contactNumberdialcodeListDatagridRef) {
				this.contactNumberdialcodeListDatagridRef.instance.clearFilter();
			}
		}, 600);
		this.isHideShowContactNumberPopover = !this.isHideShowContactNumberPopover;
	}
	public doSelectAuthPhoneNumber(e) {
		if (e.selectedRowKeys && e.selectedRowKeys.length > 0) {
			const phoneCodeDetails = e.selectedRowsData[0];
			this.dialCodeId = phoneCodeDetails.id;
			this.signUpUI.dialcode_phone = phoneCodeDetails;
			this.isHideShowAuthPhoneNumberPopover = false;
		}
	}
	public doSelectContactNumber(e) {
		if (e.selectedRowKeys && e.selectedRowKeys.length > 0) {
			const phoneCodeDetails = e.selectedRowsData[0];
			this.cndialCodeId = phoneCodeDetails.id;
			this.signUpUI.dialcode_contact_number = phoneCodeDetails;
			this.isHideShowContactNumberPopover = false;
		}
	}
}
