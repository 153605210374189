<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width90:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 85:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [title]="popupTitleText" [showCloseButton]="true" [(visible)]="isShowPopup"
	titleTemplate="title" (onHidden)="doHiddenPopup($event)" [wrapperAttr]="{ class: 'popupToolbarPadding' }"
	class="featurePopup">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'title'" class="featurePopup">
		<span (click)="doHiddenPopup($event)" class="closePopup dx-icon dx-icon-close"></span>
		<h2 *ngIf="selectedPackage" class="popTitle noBdr mrgB0 mrgT10">
			{{selectedPackage.name}}
			<div *ngIf="selectedPackageType === 'setup_package_option'" class="cf">
				<span class="highlight">
					{{selectedPackage.base_price || 0 |customCurrencyPipe}}
				</span>
				/ up to {{selectedPackage.days_included}} days
			</div>
			<div *ngIf="selectedPackageType === 'license_category'" class="cf">
				<span class="highlight">
					{{selectedPackage.price_per_license || 0 |customCurrencyPipe}}
				</span>
				/ per {{selectedPackage.license_type === 'bucket' ? selectedPackage.licenses_per_bucket + ' rep(s)' :
				'user(s)'}}, per {{selectedPackage.payment_type === 'annual' ? 'annum' : 'month'}}
			</div>
		</h2>
	</div>
	<div *dxTemplate="let data of 'content'" class="featurePopup">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div class="row mrgT10">
				<div class="cols12">
					<div [innerHTML]="featuresText"></div>
				</div>
			</div>
		</dx-scroll-view>
	</div>
</dx-popup>