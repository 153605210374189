<header id="header">
	<div class="cf">
		<!-- <div class="navigation-trigger">
			<i class="menu-icon"></i>
		</div> -->
		<!-- <div class="navigation-trigger">
			<i class="menu-icon"></i>
		</div> -->
		<div class="menuExpandTrigger">
			<span class="menu-icon"></span>
		</div>
		<div id="logo">
			<a><img [src]="_AppCommonSrvc.cdnImgPath +'logo.png'" width="146" height="32" alt="incentX" title="incentX">
			</a>
		</div>

		<div class="rightTop">
			<div class="usrCntNav">
				<!-- <div class="rightDrop">
					<span class="rightDropBtn"></span>
					<div class="rightDropIn">
						<div class="syncERP">
							<a>
								<span class="headerIcon syncIcon"></span>
								Sync with ERP
								<span class="syncHistory">Last update: 10 min ago</span>
							</a>
						</div>
						<div class="usrSettings">
							<a>
								<span class="headerIcon stngIcon"></span>
								Settings <span class="downArrow"></span>
							</a>
						</div>
					</div>
				</div> -->

				<div class="userProfile">
					<a>
						<div class="userImage">
							<span class="">
								{{first_name || '' | slice:0:1 |uppercase}}{{last_name || '' | slice:0:1 |uppercase}}
							</span>
						</div>
						<div class="userDetail">
							<h3>{{first_name || ''}} {{last_name || ''}} , <span>{{role_name || ''}}</span></h3>
							<h4>Customer Id.: <span>{{customer_no || ''}}</span></h4>
						</div>
					</a>
					<div class="userDtlToggle toggleIt">
						<ul class="usrContact">
							<li>
								<a (click)="doRedirectUser('userprofile')">
									<span class="contIcon icon-email_id icon-set1"></span>Profile
								</a>
							</li>
						</ul>
					</div>
				</div>

				<!-- <div class="notificationBar" (click)="notificationBarclick()">
					<a class="notifyBtn">
						<div class="headerIcon icon-notification-icon-new icon-set1">
							<span class="notiCount">2</span>
						</div>
					</a>
					<div class="dropPopup notifiaction">
						<ul>
							<li>
								Under Construction !
							</li>
							<li>
								<a>
									<span class="dropPopupTitle">Lorem Ipsum is simply dummy.</span>
									<span class="dropPopupText">Lorem Ipsum is simply dummy text of the printing
										and type...</span>
									<span class="dropPopupDate">03-06-2019</span>
								</a>
							</li>
						</ul>
						<a class="button viewAll">View All</a>
					</div>
				</div> -->

				<div class="signOut">
					<a (click)="doLogout()"><span class="icon-logout-new icon-set1"></span> </a>
				</div>
			</div>
			<!--   <div class="searchBar">
			<div class="searchControl">
				<input type="text" class="searchField" placeholder="Search..." />
				<input type="button" class="searchBtn" />
			</div>
		</div>

		 </div>  -->
		</div>
	</div>
</header>

<app-logout *ngIf="isShowLogoutPopup" [portalType]="portalType"
	(closeLogoutPopup)="doCancelLogoutPopup($event)"></app-logout>