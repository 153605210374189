<dx-popup [showTitle]="true" [title]="titleText" [dragEnabled]="true" [hideOnOutsideClick]="true"
	[showCloseButton]="true" [(visible)]="isVisiblePopup" (onHidden)="doHiddenPopop($event)"
	[wrapperAttr]="{ class: 'itemClaimDetailsPopupWrapper' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<div class="tableOut">
			<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="itemClaimDataSource" [showBorders]="true"
				[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true" [filterSyncEnabled]="true"
				[allowColumnReordering]="true" [filterValue]="filterValue" [remoteOperations]="{ groupPaging: true }"
				[columnAutoWidth]="true" [width]="'100%'" (onToolbarPreparing)="doToolbarPreparing($event)" [height]="'40%'"
				class="cellColor hoverClr hideFilterPanelInx">
				<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
				<dxo-paging [pageSize]="pageOptions.pageSize" [enabled]="false"></dxo-paging>
				<dxo-pager [visible]="false" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
					[showInfo]="true">
				</dxo-pager>
				<dxo-filter-row [visible]="true"></dxo-filter-row>
				<dxo-filter-panel [visible]="true"></dxo-filter-panel>
				<dxo-header-filter [visible]="true"></dxo-header-filter>
				<dxo-sorting mode="single"></dxo-sorting>
				<dxo-filter-row [visible]="true"></dxo-filter-row>
				<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
				<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadClaimItemDetailState"
					[customSave]="doSaveClaimItemDetailState" storageKey="invoices-datagrid-state">
				</dxo-state-storing>
				<div *dxTemplate="let data of 'templateFilterText'">
					<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
						<span>{{filterText}}</span>
					</div>
				</div>
				<dxi-column dataField="number" alignment="center" caption="No." dataType="number"
					[allowHeaderFiltering]="false">
				</dxi-column>
				<dxi-column dataField="end_user_name" caption="End User" dataType="string">
				</dxi-column>
				<dxi-column dataField="pricelistno" caption="Pricelist No." dataType="string">
				</dxi-column>
				<dxi-column dataField="claim_no" caption="Claim No." dataType="string">
				</dxi-column>
				<dxi-column caption="Status" dataField="claimedStatus" [allowEditing]="false"
					cellTemplate="cellTemplateStatus" [allowHeaderFiltering]="true" [allowFiltering]="true"
					[allowSorting]="true" dataType="string">
					<dxo-header-filter [dataSource]="listOfHeaderFilterStatuses"></dxo-header-filter>
					<div *dxTemplate="let element of 'cellTemplateStatus'">
						<div
							[ngClass]="element.data.claimedStatus || '' | filterFromList:listOfStatuses : 'value' : 'classname'">
							{{element.data.claimedStatus || '' | filterFromList:listOfStatuses : 'value' :
							'finaltext'}}
						</div>
					</div>
				</dxi-column>
				<dxi-column dataField="docnum" caption="Invoice No." dataType="string"
					[visible]="endUsersChargebackContractModelUI.is_enforce_quantity_sold"
					[showInColumnChooser]="endUsersChargebackContractModelUI.is_enforce_quantity_sold">
				</dxi-column>
				<dxi-column dataField="docdate" format="yyyy-MM-dd" caption="Invoice Date" dataType="date"
					[visible]="endUsersChargebackContractModelUI.is_enforce_quantity_sold"
					[showInColumnChooser]="endUsersChargebackContractModelUI.is_enforce_quantity_sold">
				</dxi-column>
				<dxi-column caption="Claimed UOM" dataField="selectedClaimedUom" [allowHeaderFiltering]="false"
					[allowFiltering]="false" [allowEditing]="true" cellTemplate="cellTemplateUomCode" dataType="string">
					<dxo-lookup [dataSource]="listOfUoms" valueExpr="code" displayExpr="name">
					</dxo-lookup>
					<div *dxTemplate="let element of 'cellTemplateUomCode'">
						<span>
							{{element.data.selectedClaimedUom || '' | filterFromList:listOfUoms : 'code' : 'name' }}
						</span>
					</div>
				</dxi-column>
				<dxi-column dataField="claimed_qty" alignment="left" caption="Claimed Qty (Contract UOM)" dataType="number">
				</dxi-column>
				<dxi-column dataField="approved_qty" alignment="left" caption="Approved Qty (Contract UOM)"
					dataType="number">
				</dxi-column>
				<dxi-column dataField="linetotal" caption="Chargeback Amount" dataType="number"
					[allowHeaderFiltering]="false" [allowFiltering]="false">
					<dxo-format type="currency" [precision]="decimalPointForCurrency">
					</dxo-format>
					<div *dxTemplate="let element of 'cellTemplateChargeback'">
						{{element.data.linetotal || 0 | customCurrencyPipe }}
					</div>
				</dxi-column>

				<dxo-summary>
					<dxi-total-item column="linetotal" summaryType="sum" displayFormat="Total: {0}">
						<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
						</dxo-value-format>
					</dxi-total-item>
					<dxi-total-item column="claimed_qty" summaryType="sum" displayFormat="{0}">
					</dxi-total-item>
					<dxi-total-item column="approved_qty" summaryType="sum" displayFormat="{0}">
					</dxi-total-item>
				</dxo-summary>
			</dx-data-grid>
		</div>
	</div>
</dx-popup>