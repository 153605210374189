<dx-popup [fullScreen]="callFrom !== 'message-trail'" [showTitle]="true" [width]="popupWidth" [height]="popupHeight"
	[title]="titleText" [dragEnabled]="true" [hideOnOutsideClick]="true" [showCloseButton]="true"
	[(visible)]="isVisiblePopup" (onHidden)="doHiddenPopop()" [wrapperAttr]="{ class: 'itemClaimDetailsPopupWrapper' }"
	id="ViewClaimPopUp">
	<dxo-position at="center" my="center"> </dxo-position>

	<div *dxTemplate="let data of 'content'">
		<div [ngClass]="callFrom === 'message-trail' ? 'cols12' :'cols9'">
			<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'"
				[bounceEnabled]="false" [useNative]="false" width="100%" height="100%">
				<div>
					<div class="orderDetail orderPdetails">
						<div class="custDetail">
							<h4 class="graySubHead textWrap">Claim No. :
								{{claimId}}</h4>
							<h4 class="graySubHead textWrap">Document Reference No. :
								{{chargeBackDocumentDetails.documentReferenceNumber}}</h4>
							<h4 class="graySubHead textWrap" *ngIf="chargeBackDocumentDetails?.selectCustomerId">Customer Code:
								<span>{{chargeBackDocumentDetails?.selectCustomerId}}</span>
							</h4>
							<h4 class="graySubHead textWrap" *ngIf="chargeBackDocumentDetails?.selectCustomer">Customer Name:
								<span>{{chargeBackDocumentDetails?.selectCustomer}}</span>
							</h4>
							<h4 class="graySubHead textWrap" *ngIf="chargeBackDocumentDetails?.source_claim_no">Source Claim
								No.
								:
								<a><span class="highlight"
										(click)="doClickViewClaim(chargeBackDocumentDetails)">{{chargeBackDocumentDetails?.source_claim_no}}</span></a>
							</h4>
							<!-- <h4 class="graySubHead textWrap">Document :
						<a href="{{downloadDocumentURL}}" class="downloadIcon">
						</a>
						Download
					</h4> -->
							<!-- <h4 class="graySubHead">Reason : <span
							[ngClass]="chargeBackDocumentDetails.claimedStatus === 'approved' ? 'green' : chargeBackDocumentDetails.claimedStatus === 'inprocess' ? 'orange' : 'red'">
							{{chargeBackDocumentDetails.claimedStatusReason}}</span></h4> -->
							<h4>
								<!-- <div class="remarkBx fullNote mrgT10 mrgB10 mrgR10 cursorPointer" *ngIf="downloadDocumentURL"
								(click)="doclickRefrenceDocument()">
								<strong><a class="downloadIcon"></a>Reference Document</strong>
							</div> 
							<div class="remarkBx fullNote  mrgT10 mrgB10 mrgR10 cursorPointer" *ngIf="claimDocumentURL"
								(click)="doClickClaimedDocument()">
								<strong><a class="downloadIcon"></a>Claim Document</strong>
							</div>
							<div class="remarkBx fullNote  mrgT10 mrgB10 mrgR10 cursorPointer"
								*ngIf="chargeBackDocumentDetails.claimedStatus !=='inprocess' && outputDocumentURL"
								(click)="doClickOutputDocument()">
								<strong><a class="downloadIcon"></a>Output Document</strong>
							</div>-->
								<dx-button *ngIf="downloadDocumentURL" (onClick)="doclickRefrenceDocument()"
									class="secondaryBtn button" type="normal" stylingMode="contained" [useSubmitBehavior]="false"
									icon="download" text="Reference Document">
								</dx-button>
								<dx-button *ngIf="claimDocumentURL" (onClick)="doClickClaimedDocument()"
									class="secondaryBtn button mrgL10" type="normal" stylingMode="contained"
									[useSubmitBehavior]="false" icon="download" text="Claim Document">
								</dx-button>
								<dx-button *ngIf="chargeBackDocumentDetails.claimedStatus !=='inprocess' && outputDocumentURL"
									(onClick)="doClickOutputDocument()" class="secondaryBtn button mrgL10" type="normal"
									stylingMode="contained" [useSubmitBehavior]="false" icon="download" text="Output Document">
								</dx-button>
							</h4>
						</div>
						<div class="orderStatus">
							<ul class="statusBx">
								<li><span class="stLbl">Claim Status : </span>
									<span class="stData stBtn"
										*ngIf="(chargeBackDocumentDetails.claimedStatus === 'inprocess' && callFrom !== 'notifications') || chargeBackDocumentDetails.claimedStatus !== 'inprocess'"
										[ngClass]="chargeBackDocumentDetails.claimedStatus || '' | filterFromList:listOfStatuses : 'value' : 'classnameBg'">
										{{chargeBackDocumentDetails.claimedStatus || '' | filterFromList:listOfStatuses : 'value'
										:
										'finaltext'}}</span>

									<span
										*ngIf="(chargeBackDocumentDetails.claimedStatus === 'inprocess' && callFrom === 'notifications')">
										<dx-drop-down-button [splitButton]="false"
											[ngClass]="getClassNameForStatusField(chargeBackDocumentDetails.claimedStatus)"
											[text]="chargeBackDocumentDetails.claimedStatus || '' | filterFromList:listOfApproveStatuses : 'value' : 'text' | uppercase"
											stylingMode="contained"
											class="dynamicBgColors secondaryBtn dynamicarow ix-textleftalign"
											[dataSource]="listOfApproveStatuses" [wrapItemText]="true" displayExpr="text"
											keyExpr="value" (onButtonClick)="getListOfApproveStatus(chargeBackDocumentDetails)"
											(onItemClick)="changedApprovalStatusAction($event,chargeBackDocumentDetails.guid)">
										</dx-drop-down-button>
									</span>
								</li>

								<li><span class="stLbl">Claim Date :</span><strong
										class="black">{{chargeBackDocumentDetails.claimDate| toDateObj | date: 'yyyy-MM-dd'
										}}</strong>
								</li>
								<li><span class="stLbl">Document Date :</span><strong
										class="black">{{chargeBackDocumentDetails.documentDate| toDateObj | date: 'yyyy-MM-dd'
										}}</strong>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="hrLine"></div>
				<div class="tabbing cf">
					<ul>
						<li (click)="doChangeTab('succeed_items')" [ngClass]="{active: currentTab === 'succeed_items'}">
							<a>Claimed Item(s)</a>
						</li>
						<li (click)="doChangeTab('ignored_items')" [ngClass]="{active: currentTab === 'ignored_items'}">
							<a>Ignored Item(s)</a>
						</li>
					</ul>
				</div>
				<div *ngIf="isShowDatagrid">
					<dx-data-grid #endUserChargeBacDocumentDataGridContainer id="gridContainer"
						[dataSource]="endUserChargeBackDocumentDataSource" [remoteOperations]="false" [wordWrapEnabled]="true"
						[hoverStateEnabled]="true" [showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
						[allowColumnResizing]="true" [filterSyncEnabled]="true" [filterValue]="filterValue"
						[remoteOperations]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [width]="'100%'"
						(onToolbarPreparing)="doToolbarPreparing($event)" [height]="500"
						class="cellColor hoverClr hideFilterPanelInx">
						<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [showScrollbar]="'always'"
							[bounceEnabled]="false" [useNative]="false"></dxo-scrolling>
						<dxo-filter-row [visible]="true"></dxo-filter-row>
						<dxo-filter-panel [visible]="true"></dxo-filter-panel>
						<dxo-header-filter [visible]="false"></dxo-header-filter>
						<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
						<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadViewEndUserChargebackDocument"
							[customSave]="doSaveViewEndUserChargebackDocument" [storageKey]="stateStorageKey">
						</dxo-state-storing>
						<div *dxTemplate="let data of 'templateFilterText'">
							<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
								<span>{{filterText}}</span>
							</div>
						</div>
						<dxi-column caption="SKU" dataField="itemsku" cellTemplate="cellTemplateSKU"
							[visible]="isQBOnlineErpUser" [showInColumnChooser]="isQBOnlineErpUser" dataType="string">
							<div *dxTemplate="let element of 'cellTemplateSKU'">
								{{element.data.itemsku || ''}}
							</div>
						</dxi-column>
						<dxi-column caption="End-User Code" dataField="end_user_code" cellTemplate="cellTemplateEndUserCode"
							dataType="string">
							<div *dxTemplate="let element of 'cellTemplateEndUserCode'">
								{{element.data.end_user_code || ''}}
							</div>
						</dxi-column>
						<dxi-column caption="End-User" dataField="end_user_name" cellTemplate="cellTemplateEndUser"
							dataType="string">
							<div *dxTemplate="let element of 'cellTemplateEndUser'">
								{{element.data.end_user_name || ''}}
							</div>
						</dxi-column>
						<dxi-column caption="PriceList No." dataField="pricelistno" cellTemplate="cellTemplatePriceListNo"
							dataType="string">
							<div *dxTemplate="let element of 'cellTemplatePriceListNo'">
								{{element.data.pricelistno || ''}}
							</div>
						</dxi-column>
						<dxi-column caption="Item" dataField="itemname" cellTemplate="cellTemplateItemName" dataType="string">
							<div *dxTemplate="let element of 'cellTemplateItemName'">
								{{element.data.itemname || ''}}
							</div>
						</dxi-column>
						<dxi-column caption="Item Description" dataField="description" cellTemplate="cellTemplateDescription">
							<div *dxTemplate="let element of 'cellTemplateDescription'">
								{{element.data.description || ''}}
							</div>
						</dxi-column>
						<dxi-column caption="UOM (Claim)" dataField="selectedClaimedUom" cellTemplate="cellTemplateUOM"
							alignment="center" dataType="string">
							<div *dxTemplate="let element of 'cellTemplateUOM'">
								{{element.data.selectedClaimedUom | uppercase}}
							</div>
						</dxi-column>
						<dxi-column caption="UOM (Contract)" dataField="contract_uom" cellTemplate="cellTemplateContractUOM"
							alignment="center" dataType="string">
							<div *dxTemplate="let element of 'cellTemplateContractUOM'">
								{{element.data.contract_uom | uppercase}}
							</div>
						</dxi-column>
						<dxi-column caption="Contract Price" dataField="final_amount" alignment="right" dataType="number"
							[allowHeaderFiltering]="false" [allowFiltering]="false">
							<dxo-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-format>
							<div *dxTemplate="let element of 'cellTemplateFinalAmount'">
								{{element.data.final_amount|| 0 | customCurrencyPipe}}
							</div>
							<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-format> -->
						</dxi-column>
						<dxi-column caption="Invoice Price" dataField="listPrice" alignment="right" dataType="number"
							[allowHeaderFiltering]="false" [allowFiltering]="false">
							<dxo-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-format>
							<div *dxTemplate="let element of 'cellTemplateInvoicePrice'">
								{{element.data.listPrice|| 0 | customCurrencyPipe}}
							</div>
							<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-format> -->
						</dxi-column>
						<dxi-column caption="Chargeback Amount (Claim)" dataField="chargebackAmount" alignment="right"
							dataType="number" [allowHeaderFiltering]="false" [allowFiltering]="false">
							<dxo-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-format>
							<div *dxTemplate="let element of 'cellTemplateChargebackAmountClaim'">
								{{element.data.chargebackAmount|| 0 | customCurrencyPipe}}
							</div>
							<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-format> -->
						</dxi-column>
						<dxi-column caption="Chargeback Amount (Contract)" dataField="contract_chargeback_amount"
							alignment="right" dataType="number" [allowHeaderFiltering]="false" [allowFiltering]="false">
							<dxo-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-format>
							<div *dxTemplate="let element of 'cellTemplateChargebackAmountContract'">
								{{element.data.contract_chargeback_amount|| 0 | customCurrencyPipe}}
							</div>
						</dxi-column>
						<dxi-column caption="Claim Qty (Claim UOM)" dataField="claimed" cellTemplate="cellTemplateClaimed"
							alignment="center" dataType="string">
							<div *dxTemplate="let element of 'cellTemplateClaimed'">
								{{element.data.claimed || ''}}
							</div>
						</dxi-column>
						<dxi-column caption="Qty Available (Contract UOM)" dataField="contract_avl_qty"
							[visible]="endUsersChargebackContractModelUI.is_enforce_quantity_sold"
							[showInColumnChooser]="endUsersChargebackContractModelUI.is_enforce_quantity_sold"
							cellTemplate="cellTemplateContractAvlQty" alignment="center" dataType="string">
							<div *dxTemplate="let element of 'cellTemplateContractAvlQty'">
								{{element.data.contract_avl_qty || ''}}
							</div>
						</dxi-column>
						<dxi-column caption="Approved Qty (Claim UOM)" dataField="approved_qty"
							cellTemplate="cellTemplateApprovedQty" alignment="center" dataType="string">
							<div *dxTemplate="let element of 'cellTemplateApprovedQty'">
								{{element.data.approved_qty || ''}}
							</div>
						</dxi-column>

						<dxi-column caption="Approved Amount" dataField="linetotal" dataType="number"
							[allowHeaderFiltering]="false" [allowFiltering]="false">
							<dxo-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-format>
							<div *dxTemplate="let element of 'cellTemplateApprovedAmount'">
								{{element.data.linetotal|| 0 | customCurrencyPipe}}
							</div>
						</dxi-column>

						<dxi-column caption="Claimed Amount" dataField="amount" dataType="number"
							[visible]="endUsersChargebackContractModelUI.is_enforce_quantity_sold"
							[showInColumnChooser]="endUsersChargebackContractModelUI.is_enforce_quantity_sold"
							[allowHeaderFiltering]="false" [allowFiltering]="false">
							<dxo-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-format>
							<div *dxTemplate="let element of 'cellTemplateClaimedAmount'">
								{{element.data.amount|| 0 | customCurrencyPipe}}
							</div>
						</dxi-column>

						<dxi-column caption="Remark" dataField="remark" cellTemplate="cellTemplateRemark" dataType="string"
							[visible]="currentTab === 'ignored_items'" [showInColumnChooser]="currentTab === 'ignored_items'"
							[allowHeaderFiltering]="false" [allowFiltering]="false">
							<div *dxTemplate="let element of 'cellTemplateRemark'">
								{{element.data.remark || ''}}
							</div>
						</dxi-column>

						<dxo-summary>
							<dxi-total-item column="approved_qty" alignment="center" summaryType="sum" displayFormat="{0}">
							</dxi-total-item>
							<dxi-total-item column="contract_avl_qty" alignment="center" summaryType="sum" displayFormat="{0}">
							</dxi-total-item>
							<dxi-total-item column="claimed" alignment="center" summaryType="sum" displayFormat="{0}">
							</dxi-total-item>
							<dxi-total-item column="amount" alignment="right" summaryType="sum" displayFormat="{0}">
								<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-value-format>
							</dxi-total-item>
							<dxi-total-item column="linetotal" alignment="right" summaryType="sum" displayFormat="{0}">
								<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-value-format>
							</dxi-total-item>
						</dxo-summary>
					</dx-data-grid>
				</div>

				<div *ngIf="shortPayTaskDataSource.length > 0" class="mrgT20">
					<h2 class="height0p">
						Claim to settle short pay:
					</h2>
					<dx-data-grid [height]="75" #listOfShortPayTaskDataGrid [dataSource]="shortPayTaskDataSource"
						keyExpr="claimnum" [showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
						[allowColumnResizing]="true" [hoverStateEnabled]="true" [showBorders]="true" class="mrgT10">
						<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
						<dxo-selection mode="single"></dxo-selection>
						<dxi-column dataType="date" dataField="date" caption="Date" cellTemplate="cellTemplateDate">
							<div *dxTemplate="let element of 'cellTemplateDate'">
								{{ (element.data.date !== null &&
								element.data.date !== '' &&
								element.data.date !== undefined) ?
								(element.data.date | date: 'yyyy-MM-dd') : "" }}
							</div>
						</dxi-column>
						<dxi-column dataType="string" dataField="claimnum" caption="Short Pay ID"></dxi-column>
						<dxi-column dataType="string" dataField="customer_code" caption="Customer Code"></dxi-column>
						<dxi-column dataType="number" dataField="amount" caption="Amount">
							<dxo-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-format>
							<div *dxTemplate="let element of 'cellTemplateAmount'">
								{{element.data.amount || 0 | customCurrencyPipe }}
								<!-- {{element.data.amount ? convertToNumber(element.data.amount) : '0'}} -->
							</div>
						</dxi-column>
						<dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
					</dx-data-grid>
				</div>

				<div class="remarkBx fullNote" *ngIf="chargeBackDocumentDetails.claimedUserNote">
					<strong>User Note:- {{chargeBackDocumentDetails.claimedUserNote}}</strong>
				</div>

				<dx-button icon="save"
					*ngIf="chargeBackDocumentDetails.claimedStatus === 'under_review' && callFrom !== 'notifications'"
					class="primaryBtn mrgT20 alignRight" id="button" text="Modify Claim" type="normal"
					stylingMode="contained" (onClick)="doModifyClaim()">
				</dx-button>
			</dx-scroll-view>
		</div>

		<div class="cols3 approvaltimelinetopix" *ngIf="callFrom !== 'message-trail'">
			<app-message-trall *ngIf="isViewMessageTrall" [claimDetail]="chargeBackDocumentDetails"></app-message-trall>
		</div>
	</div>
</dx-popup>
<app-upload-chargeback-enduser-claims-dx-dialog [loadUploadEndUserDxDialogSubject]="loadUploadEndUserDxDialogSubject"
	(hidePopUpDataEvent)="doHideUploadEndUserDxDialog($event)"></app-upload-chargeback-enduser-claims-dx-dialog>