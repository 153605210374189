import { Injectable, Injector } from '@angular/core';
import { IMG_ADD_FILE_SVG, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX } from '@app/constants-enums/constants';

@Injectable({
	providedIn: 'root'
})
export class ApplicationConfigurationsService {

	constructor() {
	}

	get listOfActionsForWorkflows() {
		return [{
			btn_code: 'ADD',
			btn_name: 'Add New',
			btn_image: IMG_ADD_FILE_SVG,
			icon: ' ' + IMG_ADD_FILE_SVG,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'REFRESH',
			btn_name: 'Refresh',
			btn_mage: '',
			icon: IMG_ICON_REFRESH,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		}];
	}

	get listofStatusesForWorkflow() {
		return [{
			"guid": "b67405da8a6de034625a2b4ace3c2ce7",
			"value": 'active',
			"label": "ACTIVE",
			"text": "ACTIVE",
			"bgcolor": "#03b86c"
		},
		{
			"guid": "9ea137e004825073e732a5dac6a37702",
			"value": 'inactive',
			"label": "INACTIVE",
			"text": "INACTIVE",
			"bgcolor": "#f2b80c"
		}];
	}

	get getListOfActionForAuditLogs() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	get listOfActionsForCustomFieldsTab() {
		return [{
			btn_code: 'ADD',
			btn_name: 'Add New',
			btn_image: IMG_ADD_FILE_SVG,
			icon: ' ' + IMG_ADD_FILE_SVG,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'REFRESH',
			btn_name: 'Refresh',
			btn_mage: '',
			icon: IMG_ICON_REFRESH,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		}];
	}

	get listOfFieldTypeForCustomFields() {
		return [{
			value: 'string',
			text: 'Text',
			visible: true,
			disabled: false,
		},
		{
			value: 'boolean',
			text: 'Boolean',
			visible: true,
			disabled: false,
		},
		{
			value: 'percentage',
			text: 'Percentage',
			visible: true,
			disabled: false,
		},
		{
			value: 'amount',
			text: 'Number',
			visible: true,
			disabled: false,
		},
		{
			value: 'date',
			text: 'Date',
			visible: true,
			disabled: false,
		}];
	}

	get listOfApprovalsForApprovalTrailLogs() {
		return [{
			value: 'pricelists',
			text: 'Price List',
			visible: true,
			disabled: false,
		},
		{
			value: 'rebatescontracts',
			text: 'Rebate Settlements',
			visible: true,
			disabled: false,
		},
		{
			value: 'minmaxenduserschargebackcontracts',
			text: 'Min-Max Chargebacks',
			visible: true,
			disabled: false,
		}];
	}

	get getListOfActionForApprovalTrailsLogs() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}
}
