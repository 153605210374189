

export class MerchantAuthenticationModel {
	public name: any;
	public transactionKey: any;
	constructor() { }
}

export class OpaqueDataModel {
	public dataDescriptor?: string;
	public dataValue?: string;
	constructor() { }
}

export class Payment {
	public opaqueData: OpaqueDataModel = new OpaqueDataModel();
	constructor() { }
}

export class BillTo {
	public firstName: string;
	public lastName: string;
	public company: string;
	public address: string;
	public city: string;
	public state: string;
	public zip: string;
	public country: string;
	public phoneNumber: string;
	// public opaqueData?: OpaqueDataModel = new OpaqueDataModel();
	constructor() { }
}
export class ShipTo {
	public firstName: string;
	public lastName: string;
	public company: string;
	public address: string;
	public city: string;
	public state: string;
	public zip: string;
	public country: string;
	public phoneNumber: string;
	constructor() { }
}

export class Order {
	public invoiceNumber?: string;
	public description?: string;
	constructor() { }
}

export class Tax {
	public amount: number;
	public name: string;
	public description: string;
	constructor() { }
}


export class PaymentProfile {
	public paymentProfileId: any[];
	public customerType?: string;
	public payment?: Payment = new Payment();
	public defaultPaymentProfile?: boolean;
	public customerPaymentProfileId?: string;
	public billTo?: BillTo = new BillTo();
	constructor() { }
}

export class Profile {
	public customerProfileId: string;
	public merchantCustomerId?: string;
	public description?: string;
	public email?: string;
	public paymentProfiles: PaymentProfile = new PaymentProfile();
	constructor() { }
}


export class TransactionRequestModel {
	public transactionType: string;
	public amount: number;
	public profile: Profile = new Profile();
	public billTo?: BillTo = new BillTo();
	public shipTo?: ShipTo = new ShipTo();
	public order?: Order = new Order();
	public tax?: Tax = new Tax();
	public poNumber?: string;
	public customerIP?: string;
	constructor() { }
}

export class MerchantTransactionModel {
	public merchantAuthentication: MerchantAuthenticationModel = new MerchantAuthenticationModel();
	public transactionRequest?: TransactionRequestModel = new TransactionRequestModel();
	public refId?: string;
	public profile?: Profile = new Profile();
	public customerProfileId?: string;
	public customerPaymentProfileId?: string;
	public validationMode?: string;
	constructor() { }
}

export class CreateTransactionRequestModel {
	public createTransactionRequest: MerchantTransactionModel = new MerchantTransactionModel();
}
export class CreateCustomerProfileRequestModel {
	public createCustomerProfileRequest: MerchantTransactionModel = new MerchantTransactionModel();
}
export class CreateCustomerPaymentProfileRequestModel {
	public createCustomerPaymentProfileRequest: MerchantTransactionModel = new MerchantTransactionModel();
}

export class DeleteCustomerPaymentProfileRequestModel {
	public deleteCustomerPaymentProfileRequest: MerchantTransactionModel = new MerchantTransactionModel();
}

export class UserDataPaymentModel {
	public cardNumber: string;
	public expMonth: string;
	public expYear: string;
	public cardCode: string;
	public amount: number;
	public email: string;
	public billTo?: BillTo = new BillTo();
	public shipTo?: ShipTo = new ShipTo();
	public order?: Order = new Order();
	public tax?: Tax = new Tax();
	public poNumber?: string;
	public customerIP?: string;
	public defaultPaymentProfile?: boolean;
	public paymentProfileId?: string;
	constructor() { }
}





