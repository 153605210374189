import { Component, OnInit, Inject, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { PopupHeightWidth } from "@app/constants-enums/enums";
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
import { LoginService } from "@app/services/login.service";

@Component({
	selector: "app-show-license-features-dialog",
	templateUrl: "./show-license-features-dialog.component.html",
	styleUrls: ["./show-license-features-dialog.component.css"]
})
export class ShowLicenseFeaturesDialogComponent implements OnInit, OnChanges {
	@Input() showLicenseFeaturesProps: any;
	@Output() closeShowLicenseFeaturesPopup = new EventEmitter<any>();
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowPopup: boolean;
	public popupTitleText: string;

	public globalCurrency: string;
	public selectedPackage: any = {};
	public featuresText: string;
	public selectedPackageType: string;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService) { }

	ngOnInit() {
		// get global currency
		this.globalCurrency = this._LoginService.globalCurrency;
		this.isShowPopup = true;
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['showLicenseFeaturesProps']) {
			const showLicenseFeaturesProps = _SimpleChanges['showLicenseFeaturesProps'].currentValue;

			this.selectedPackageType = showLicenseFeaturesProps.selectedPackageType;
			this.selectedPackage = showLicenseFeaturesProps.selectedPackage;

			this.featuresText = (this.selectedPackage.features && this.selectedPackage.features && this.selectedPackage.features.length > 0) ? this.selectedPackage.features[0] : '';
		}
	}


	public doHiddenPopup(event) {
		this.closeShowLicenseFeaturesPopup.emit({
			isClickOnCloseBtn: true
		});
		this.isShowPopup = false;
	}
}
