<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width70:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 65:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" title="Subscription Services Agreement" [showCloseButton]="true"
	[(visible)]="isShowPopup" (onHidden)="doHiddenPopup($event)" [wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div [innerHTML]="terms_content"></div>
		</dx-scroll-view>

		<div class="termCheckContainer">
			<div [ngClass]="{'plsCheckIt':isShowInfoDialog}" class="termUnchecked">
				<span class="termErrorIcon">
					<img [src]="_AppCommonSrvc.cdnImgPath + 'term-error.png'" alt="Error" title="Error" />
				</span>
				You have to <strong>read full Subscription Services Agreement</strong>
				then only you can proceed.
				<span (click)="clickToHideInfo()" class="closeTermError">
					<img [src]="_AppCommonSrvc.cdnImgPath + 'error-close.png'" alt="Close" title="Close" />
				</span>
			</div>

			<div [ngClass]="{'termUnRead':!isScrollTouchedToBottom}" class="row mrgT10 popupTermCheck ">
				<div (click)="clickToShowInfo()" class="fullBtn mrgT10">
					<div class="form-group temrGroup">
						<input [(ngModel)]="istermsandcondition"
							(change)="doAgreeTermsAndConditions($event);$event.stopPropagation();"
							[ngClass]="{'termUnRead':!isScrollTouchedToBottom}" [disabled]="!isScrollTouchedToBottom"
							type="checkbox" id="isAgreeTermsAndCondition">
						<label [ngClass]="{'termUnRead':!isScrollTouchedToBottom}" for="isAgreeTermsAndCondition">I
							agree
							to the IncentX Terms and Policies</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</dx-popup>