import { Injectable } from '@angular/core';
import { UserDataPaymentModel } from '@app/models/authorize-payment.model';
@Injectable({
	providedIn: 'root'
})
export class AuthorizePaymentDataService {
	public CMIData: any = [];
	public paymentResponse: any = [];
	public userData: UserDataPaymentModel = new UserDataPaymentModel();
	private profileID: string;
	private paymentProfilesIDs: any[];
	private paymentProfileId: string;
	constructor() { }

	getCMIData() {
		return this.CMIData;
	}
	setCMIData(val: any) {
		this.CMIData = val;
	}

	setProfilID(val: any) {
		this.profileID = val;
	}
	getProfilID() {
		return this.profileID || undefined;
	}

	setPaymentProfilIDs(val: any) {
		this.paymentProfilesIDs = val;
	}
	getPaymentProfilIDs() {
		return this.paymentProfilesIDs || [];
	}

	setPaymentProfilId(val: any) {
		this.paymentProfileId = val;
	}
	getPaymentProfilId() {
		return this.paymentProfileId || undefined;
	}

	getPaymentResponse() {
		return this.paymentResponse;
	}
	setPaymentResponse(val: any) {
		this.paymentResponse = val;
	}

	setProfileUserData(arrayUser) {
		this.userData = arrayUser;
	}
	getProfileUserData() {
		return this.userData;
	}
}
