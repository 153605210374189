import { CallbackfilterPipe, SanitizeHtmlPipe } from './callbackfilter/callbackfilter.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterFromListPipe, MultiFilterFromListPipe, FilterFromNestedListPipe, FilterListFromListPipe, GetPriceFromPricelistPipe, ReplacedWithStarPipe, CalculateAmountFromListPipe, FilterListFromListWithMultiPropertiesPipe, HeightWidthRatioPipe } from './filter-from-list.pipe';
import { CustomDateFormatPipe, GetDateRangeTextPipe, ToDateObjPipe } from './custom-date-format/custom-date-format.pipe';
import { CustomNumberSuffixPipe, CustomNumberDecimalSuffixPipe, GetToFixedTruncPipe, NoCommaPipe, CustomNumberNoRoundingPipe } from './custom-number-format/custom-number-format.pipe';
import { ShowClientAddressesPipe, ShowClientPhonePipe, ShowCreditCardKnoxAddressesPipe, ShowPaymentCardAddressesPipe, ShowUnderscoreToSpacePipe, ShowZohoAddressesPipe } from './show-addresses/show-addresses.pipe';
import { ShowMultipleDocnumPipe } from './show-multiple-docnum/show-multiple-docnum.pipe';
import { CustomCurrencyPipe, CustomCurrencySymbolPipe, CustomFormatCurrencyPipe } from './custom-currency/custom-currency.pipe';
import { CustomFileSizeFormatPipe } from './custom-file-size-format/custom-file-size-format.pipe';

@NgModule({
	declarations: [
		CallbackfilterPipe,
		SanitizeHtmlPipe,
		CustomDateFormatPipe,
		FilterFromListPipe,
		MultiFilterFromListPipe,
		FilterFromNestedListPipe,
		CustomNumberSuffixPipe,
		CustomNumberDecimalSuffixPipe,
		ShowPaymentCardAddressesPipe,
		FilterListFromListPipe,
		GetPriceFromPricelistPipe,
		ReplacedWithStarPipe,
		ShowCreditCardKnoxAddressesPipe,
		CalculateAmountFromListPipe,
		ShowClientAddressesPipe,
		ShowClientPhonePipe,
		FilterListFromListWithMultiPropertiesPipe,
		GetToFixedTruncPipe,
		ShowMultipleDocnumPipe,
		GetDateRangeTextPipe,
		NoCommaPipe,
		ToDateObjPipe,
		HeightWidthRatioPipe,
		CustomCurrencyPipe,
		CustomCurrencySymbolPipe,
		CustomFormatCurrencyPipe,
		CustomNumberNoRoundingPipe,
		CustomFileSizeFormatPipe,
		ShowZohoAddressesPipe,
		ShowUnderscoreToSpacePipe
	],
	imports: [
		CommonModule
	],
	exports: [
		CallbackfilterPipe,
		SanitizeHtmlPipe,
		CustomDateFormatPipe,
		FilterFromListPipe,
		MultiFilterFromListPipe,
		FilterFromNestedListPipe,
		CustomNumberSuffixPipe,
		CustomNumberDecimalSuffixPipe,
		ShowPaymentCardAddressesPipe,
		FilterListFromListPipe,
		GetPriceFromPricelistPipe,
		ReplacedWithStarPipe,
		ShowCreditCardKnoxAddressesPipe,
		CalculateAmountFromListPipe,
		ShowClientAddressesPipe,
		ShowClientPhonePipe,
		FilterListFromListWithMultiPropertiesPipe,
		ShowMultipleDocnumPipe,
		GetDateRangeTextPipe,
		NoCommaPipe,
		ToDateObjPipe,
		HeightWidthRatioPipe,
		CustomCurrencyPipe,
		CustomFormatCurrencyPipe,
		CustomNumberNoRoundingPipe,
		CustomFileSizeFormatPipe,
		ShowZohoAddressesPipe,
		ShowUnderscoreToSpacePipe
	]
})
export class MainPipesModule { }
