import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
import { PopupHeightWidth } from "@app/constants-enums/enums";

@Component({
	selector: "app-terms-and-conditions-dialog",
	templateUrl: "./terms-and-conditions-dialog.component.html",
	styleUrls: ["./terms-and-conditions-dialog.component.css"]
})

export class TermsAndConditionsDialogComponent implements OnInit, OnChanges {
	@Input() termsAndConditionsProps: any;
	@Output() closeTermsAndConditionsPopup = new EventEmitter<any>();
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowPopup: boolean;
	public popupTitleText: string;

	@ViewChild('scrollContainerTermsAndConditions') private scrollContainerTermsAndConditions: ElementRef;
	public termsAndConditionsResponse: any;
	public isShowAgreeTermsAndConditions: boolean = false;
	public istermsandcondition: boolean = false;
	public terms_content: string;
	public terms_version: string;
	public isScrollTouchedToBottom: boolean = false;
	public isShowInfoDialog: boolean = false;
	constructor(public _AppCommonSrvc: AppCommonSrvc) { }

	ngOnInit() {
		this.isShowPopup = true;
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['termsAndConditionsProps']) {
			const termsAndConditionsProps = _SimpleChanges['termsAndConditionsProps'].currentValue;
			this.termsAndConditionsResponse = termsAndConditionsProps.termsAndConditionsResponse;
			this.terms_content = this.termsAndConditionsResponse.terms_content;
			this.terms_version = this.termsAndConditionsResponse.terms_version;
			this.istermsandcondition = termsAndConditionsProps.istermsandcondition;
			if (this.istermsandcondition) {
				this.isScrollTouchedToBottom = true;
			}
		}
	}

	public doHiddenPopup(event) {
		this.closeTermsAndConditionsPopup.emit({
			isClickOnCloseBtn: true
		});
		this.isShowPopup = false;
	}

	doAgreeTermsAndConditions(event) {
		if (!this.isShowAgreeTermsAndConditions) {
			event.preventDefault();
		}
		this.isShowInfoDialog = false;
		if (event.target.checked) {
			this.termsAndConditionsResponse.istermsandcondition = event.target.checked;
			this.closeTermsAndConditionsPopup.emit(this.termsAndConditionsResponse);
		}
	}

	// For Show checkbox When Scroll Handler reaches to bottom  
	scrollHandler(event) {
		let element = this.scrollContainerTermsAndConditions.nativeElement;
		let atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
		if (atBottom) {
			this.isShowAgreeTermsAndConditions = true;
			this.isScrollTouchedToBottom = true;
		} else {
			this.isShowAgreeTermsAndConditions = false
		}
	}

	clickToShowInfo() {
		this.isShowInfoDialog = true;
		setTimeout(() => {
			this.isShowInfoDialog = false;
		}, 4000);
	}
	clickToHideInfo() {
		this.isShowInfoDialog = false;
	}
}
